@import "../../../../../../styles/mixins";
.event-permissions-step {
  &-content {
    .lockEvent {
      &-description {
        color: $grey-1;
        margin-bottom: 14px;
      }
      &-additional-label {
        margin: -5px 0 24px 31px;
        color: $grey-3;
      }
    }
    .label {
      font-size: 12px;
      color: $purple-color;
    }
    .restrictions-radio {
      .invited {
        margin-bottom: 0;
        &.active {
          border: 1px solid $wisp-pink-color;
          border-radius: $border-radius $border-radius 0 0;
          border-bottom: none;
        }
        &-label {
          width: 100%;
        }
      }
      .label {
        &-heading {
          padding-top: 16px;
          font-size: 16px;
          line-height: 24px;
          color: $black-color;
        }
        &-description {
          font-size: 14px;
          line-height: 160%;
          color: $black-color;
          margin: 0;
        }
        &-container {
          width: 100%;
          padding-top: 16px;
          @include flex(space-between);
          .label-heading {
            padding: 0;
          }
        }
      }
    }
    .attendees {
      border: 1px solid $wisp-pink-color;
      border-radius: 0 0 $border-radius $border-radius;
      border-top: none;
      padding: 8px 8px 0;
      .attendee {
        width: 50%;
        height: 56px;
        background-color: initial;
        &:hover {
          background-color: $background-color;
          .list-item-controls {
            display: initial;
          }
        }
        .list-item-controls {
          display: none;
        }
      }
    }
  }
}