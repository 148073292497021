@import "../../../styles/variables.module";

.timeZonePicker {
  position: relative;
  font-size: 18px;
  width: 100%;
  &-label {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $purple-color;
    position: absolute;
    top: 0;
    left: 16px;
  }
  &-controls {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: "Heebo", sans-serif;
    font-size: 18px;
    line-height: 140%;
    justify-content: space-between;
    cursor: pointer;
    &-label {
      margin-top: 30px;
    }
  }
  .dropDown-optionsList {
    max-height: 260px;
  }
}
