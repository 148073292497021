@import "../../../styles/index.scss";
.selectMembersModalContainer {
  background: $semitransparent-color;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  &.stream {
    background: rgba($color: #0e0a0c, $alpha: 0.6);
    .selectMembersModal {
      background-color: #4d3f49;
      width: 752px;
      &-header {
        padding: 12px 24px;
        &-title {
          color: $white-color;
          font-family: "Heebo", sans-serif;
        }
      }
      &-body {
        background-color: $popup-bg;
      }
      &-footer {
        background-color: #4d3f49;
      }
      .searchInput {
        max-height: 48px;
        max-width: 331px;
        &-input {
          border-radius: 20px;
        }
      }

      .selectedMembers-info-count,
      .selectedMembers-data-name {
        color: $white;
      }
    }
  }
}

.selectMembersModal {
  width: 100%;
  max-width: 616px;
  min-width: 610px;
  height: 100%;
  max-height: 680px;
  background: $white-2;
  font-family: $font-heebo;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  .selectedMembers-info-count,
  .selectedMembers-data-name {
    color: $black-color;
  }

  &-searchInput {
    width: 100%;
    height: 100%;
    max-width: 200px;
    &::before {
      top: unset;
    }
    &-input {
      width: 100%;
      height: 100%;
      font-family: $font-heebo;
      font-size: 18px;
      line-height: 160%;
      background-color: $grey-input-bg;
      border: none;
      &::placeholder {
        color: $grey-color;
      }
    }
    &-clear {
      &:before,
      &:after {
        background: $input-label-color !important;
      }
    }
    &.collapsable {
      max-width: 300px;
    }
  }
  &-header {
    height: 72px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 24px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 0;
    box-shadow: $card-shadow;
    &-title {
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      margin-right: auto;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: $blue-color;
      white-space: nowrap;
    }
    .dropDown {
      margin: 0 5px;
      &-optionsList {
        border-radius: 10px;
      }
      &-option {
        &:hover {
          background-color: $blue-3;
        }
      }
      &.sort-by {
        margin-right: 15px;
      }
    }
  }
  &-body {
    margin-top: 72px;
    height: calc(100% - 148px);
    overflow: auto;
    background-color: $white;
    &.short {
      height: calc(100% - 216px);
    }
    .communityMember-data-name {
      font-size: 16px;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    box-sizing: border-box;
    background: $white-2;
    position: absolute;
    width: 100%;
    bottom: 0;
    flex-wrap: wrap;
    min-height: 72px;
    &-bootem {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      &.withMargin {
        margin-top: 20px;
      }
    }
    &-buttons {
      display: flex;
      padding: 4px 0;
      margin-left: 24px;
      &.withMargin {
        margin-top: 20px;
      }
      .cancel {
        margin-right: 24px;
      }
    }
    &-selectBtn {
      transition: background-color 0.2s linear, color 0.2s linear;
      &:disabled {
        cursor: default;
        color: $black-color;
        background-color: $dark-grey-color;
      }
    }
  }
  &-additional_buttons {
    display: flex;
    padding: 4px 0;
    &.withMargin {
      margin-top: 20px;
    }
    .selectMembersModal {
      &-deselect_all {
        button {
          padding: 0;
        }
      }
      &-select_all {
        margin-right: 24px;
      }
    }
  }
  .discussionNameInput {
    width: 248px;
    .text-field {
      background: $input-background;
      border: 1px solid $blue-color;
      height: 40px;
      input {
        color: $black-color;
        &::placeholder {
          color: $grey-color;
          opacity: 1;
        }
      }
    }
  }
}
