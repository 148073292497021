@import "../../../../styles/variables.module";

.notificationContent {
  font-style: normal;
  font-weight: normal;
  padding: 2px;
  width: 348px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-family: $font-heebo;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: $white-color;
}

.message {
  font-family: $font-heebo;
  font-size: 14px;
  line-height: 180%;
  margin: 0;
  padding: 0;
  letter-spacing: 0.02em;
  color: $white-color;
}

@media screen and (max-width: 600px) {
  .notificationContent {
    max-width: 300px;
  }
}

@media screen and (max-width: 540px) {
  .notificationContent {
    max-width: 272px;
  }
}
