@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.module.scss";
.stripeSuccessPopup {
  width: 512px;
  height: 672px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @include flex(space-between, center, column);
  background: $white-color;
  box-shadow: 0px 4px 90px 10px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 56px 32px 24px;
  &-content {
    width: 100%;
    @include flex(center, center, column);
  }
  img {
    width: 118px;
    height: 112px;
    object-fit: cover;
    display: block;
    margin-bottom: 24px;
  }
  &-title {
    font-family: $font-lora;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: $grey-1;
    margin-bottom: 8px;
  }
  &-divider {
    height: 1px;
    width: 512px;
    background: rgba($color: #C4C4C4, $alpha: 0.4);
    margin-bottom: 24px;
  }
  &-description {
    font-family: $font-heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 388px;
    margin: 0 0 30px;
    color: rgba($color: $grey-1, $alpha: 0.9);
    position: relative;
  }
  &-textField {
    label+.text-field {
      line-height: 22px !important;
      margin-top: 64px !important;
      margin-bottom: 24px;
    }
    .text-label {
      margin: 0;
      color: $grey-1 !important;
      font-family: $font-heebo;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .helper-text {
      top: 92%;
    }
  }
  &-footer {
    width: 100%;
    @include flex(flex-end);
    .stripeSuccessPopup-footer-skip {
      margin-right: 16px;
      border-width: 2px !important;
    }
  }
}
