@import "../../../../../styles/variables.module";

.appearance-links {
  display: flex;
  margin-top: -24px;
  .left-side,
  .right-side {
    flex: 50%;
  }
  .left-side {
    margin-right: 48px;
  }
  .links-list {
    margin-bottom: 24px;
    .link-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .link-number {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-top: 16px;
      }
      .link-item-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        .label-error {
          position: absolute;
          color: #ef7171;
          font-size: 13px;
          line-height: 20px;
          bottom: -20px;
        }
      }
      button {
        background: url(/assets/icons/ic_remove.svg) no-repeat 100%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 35px;
      }
      label {
        display: none;
      }
    }
  }
  .links-button-wrapper {
    button {
      margin-top: 35px;
      border: none;
      background: none;
      outline: none;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      span {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: #7b9eb9;
        margin-left: 40px;
      }
      &::before {
        content: " ";
        background: url(/assets/icons/add-navigation-link.svg) no-repeat 100%;
        position: absolute;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border: none;
        outline: none;
        top: 10px;
      }
    }
  }
}
