@import "../../../../../styles/index.scss";
.eventMemberListItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  &-data {
    width: 280px;
    @include flex(flex-start);
    &-image {
      width: 46px;
      height: 42px;
      margin-right: 16px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      &-fallback {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $orange-4;
        text-transform: uppercase;
        background: $purple-2;
      }
    }
    &-name {
      font-size: 16px;
      line-height: 28px;
      color: $white-color;
      @include truncate-line;
    }

    &-title {
      @include truncate-line;
      font-size: 16px;
      margin-left: 12px;
      padding: 0 12px;
      background-color: $blue-1;
      color: $blue-4;
      border-radius: 20px;
      width: 77px;
      text-align: center;
    }
    &-edit {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
    &.own {
      width: 320px;
      .eventMemberListItem-data {
        &-title {
          margin-left: auto;
          background: none;
          color: $white-color;
          padding: 0;
          min-width: 55px;
          text-align: end;
        }
      }
    }
  }

  &-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: $popup-bg;
    border-radius: 5px;
    width: 35px;
    height: 16px;
    transition: 0.2s;
    hr {
      border: none;
      border-radius: 50%;
      width: 3.7px;
      height: 3px;
      background: $purple-1;
      margin: 2.5px;
      transition: 0.2s;
    }
    &.opened {
      background-color: $purple-color;
      hr {
        background: #e7e1e5;
      }
    }
    &.ownMember {
      display: none;
    }
  }
  &-image {
    &-restriction {
      position: absolute;
      left: 24px;
      top: 24px;
    }
  }
  &-options {
    position: absolute;
    top: 20px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $popup-bg;
    border-radius: $border-radius;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 100;
    &-item {
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0;
      width: 180px;
      text-align: center;
      color: $purple-3;
      box-sizing: border-box;
      padding: 16px 24px;
      transition: 0.2s;
      &:first-child {
        padding: 24px 24px 16px;
      }
      &:last-child {
        padding: 16px 24px 24px;
      }
      &:hover {
        background: #3f373c;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .eventMemberListItem {
    &-data {
      width: 210px;
      &.own {
        width: 245px;
      }
    }
  }
}
