@import "../../../../styles/index.scss";
.eventDashboard {
  width: $stream-dashboard-width;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  background: $nav-bg;
  padding: 94px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  z-index: 100;
  &-tabs {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 56px;
      height: 56px;
      border-radius: 12px;
      overflow: hidden;
      transition: 0.2s;
      position: relative;
      &.active {
        background: #3f373c;
      }
      .notification {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 8px;
        right: 8px;
        background: $red-color;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-color;
        font-size: 12px;
      }
    }
    &-divider {
      width: 100%;
      height: 2px;
      margin: 10px 0;
      background: rgba($color: $blue-3, $alpha: 0.1);
    }
  }
  &-bottom {
    margin-top: auto;
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: rgba($color: $blue-3, $alpha: 0.1);
      position: absolute;
      right: 0;
      left: 0;
      bottom: 87px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .eventDashboard {
    width: 80px;
    &-tabs {
      &-item {
        width: 50px;
        height: 50px;
        .notification {
          top: 4px;
          right: 4px;
        }
      }
    }
    &-bottom {
      .notification-setting-item {
        width: 50px;
        height: 50px;
      }
      .leave-event {
        width: 50px;
        height: 50px;
      }
    }
  }
}
