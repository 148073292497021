@import "../../../../styles/mixins";
.landing-page-container-wrapper {
  box-sizing: border-box;
  .landing-page-content-wrapper {
    padding: 40px 10px;
    background: var(--backgroud-color);
    &.ecamm {
      background: #7a49c8;
    }
    box-sizing: border-box;
    .landing-page-content-width-reducer {
      width: 100%;
      max-width: $landing-page-width;
      margin: 0 auto;
    }
    .top-section-wrapper {
      display: flex;
      .upcoming-event-container-wrapper {
        width: 810px;
        max-width: 100%;
        margin-right: 20px;
      }
      .featured-events-wrapper {
        flex: 40;
      }
    }
  }
  .badge {
    padding: 6px 15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    &.live {
      background: #eb5757;
      &::before {
        content: " ";
        background-image: url("/assets/icons/live-event.svg");
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
      }
    }
    &.featured {
      background: var(--accent-color);
      color: var(--text-color-link);
    }
  }
}

@media screen and (max-width: 800px) {
  .landing-page-container-wrapper {
    .landing-page-content-wrapper {
      padding: 16px 0;
      .top-section-wrapper {
        flex-direction: column;
        .upcoming-event-container-wrapper {
          width: 100%;
          margin: 0;
        }
      }
    }
    .badge {
      padding: 4px 8px;
      font-size: 10px;
    }
    .private-badge {
      &-text {
        display: none;
      }
    }
  }
}
