@import "../../../../../styles/index.scss";
.mobileWrapper {
  font-family: $font-heebo;
  height: 100%;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  &.lobby,
  &.live {
    background: #191317;
    overflow: hidden;
  }
  &-header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
    min-height: 48px;
    padding: 16px;
    &-logo {
      margin-right: 22px;
    }
    &-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.04em;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: $purple-3;
      &-community {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 650px;
        .community-name {
          @include truncate-line;
        }
      }
    }
  }
  &-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    &-header {
      width: 100%;
      margin-bottom: 16px;
      padding: 0 16px;
      box-sizing: border-box;
      .event {
        &-time {
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: $purple-3;
          margin-bottom: 8px;
        }
        &-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          color: $white-color;
        }
      }
    }
    &-body {
      width: 100%;
      height: 100%;
      position: relative;
      &.scheduled {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-text {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        span {
          max-width: 226px;
          text-align: center;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $purple-3;
        }
        strong {
          font-weight: bold;
          color: $white;
        }
      }
    }
  }
  .mobileWrapper-countDown {
    max-width: 296px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: $popup-bg;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    &-description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: $white-color;
      width: 264px;
      &-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $white-color;
      }
      &-name {
        font-size: 20px;
        line-height: 24px;
        color: $blue-4;
        max-width: 288px;
        @include truncate-line;
      }
      &-date {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 16px;
        color: $white-color;
      }
      &-countDown {
        width: 100%;
      }
      &-nextBtn {
        width: 100%;
      }
    }
    .countDown-card {
      width: 80px;
      height: 56px;
      background: rgba(25, 19, 23, 0.4);
      border-radius: 10px;
      overflow: hidden;
      @include display-flex-center;
      flex-direction: column;
      font-weight: bold;
      &-description {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $purple-2;
      }
    }
    .next-eventCard-countDown {
      margin: 0;
    }
  }
  &-lobby {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #282025;
    &-benches {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
      .mobileWrapper-lobby-benchItem {
        margin-bottom: 24px;
      }
    }
  }
  &-stream {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .videoStream {
      .unmute {
        transform: scale(0.7) translate(-30%, -10%);
      }
      height: 32%;
      background-color: #000;
    }
    &-controls,
    &-seats {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 16px 0;
      box-sizing: border-box;
    }
    &-seats {
      flex-direction: column;
      padding: 0;
    }
  }
  &-gradient {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 3;
    background-image: linear-gradient(0deg, #282025 4.79%, rgba(40, 32, 37, 0) 81.65%);
    pointer-events: none;
  }
  .styledDialog-body {
    max-width: 296px;
    .styledDialog-text-content {
      letter-spacing: unset;
    }
    .defaultButtons {
      justify-content: space-around;
    }
  }
  .app-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    &.fixed {
      position: fixed;
      bottom: 44px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      margin-bottom: 0;
    }
  }
}

.mobileWrapper-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &.ended-event {
    .mobileWrapper-warning-text {
      max-width: 200px;
    }
  }
  &.ended-event,
  &.withChildren {
    height: unset;
    padding-top: 70px;
  }
  &-title {
    font-weight: bold;
    font-size: 34px;
    line-height: 48px;
    color: $purple-4;
    margin: 0 0 16px;
  }
  &-text {
    font-size: 16px;
    line-height: 24px;
    color: $purple-3;
    margin-bottom: 56px;
    text-align: center;
    max-width: 210px;
  }
}

@media screen and (max-width: 770px) {
  .mobileWrapper {
    &-header {
      &-title {
        &-community {
          max-width: 600px;
        }
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .mobileWrapper {
    &-header {
      &-title {
        &-community {
          max-width: 400px;
        }
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .mobileWrapper {
    &-warning {
      &.withChildren {
        padding-top: 10px;
      }
      &-text {
        margin-bottom: 10px;
      }
    }
    &-content {
      &-body {
        &-text {
          padding-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-height: 496px) {
  .mobileWrapper {
    &-content {
      &-header {
        margin-bottom: 0;
        .event-time {
          margin-bottom: 0;
        }
      }
      &-body {
        &-text {
          padding-top: 4px;
        }
      }
    }
    &-warning {
      &.withChildren,
      &.ended-event {
        padding-top: 0;
      }
      &.withChildren {
        .mobileWrapper-warning-title,
        .mobileWrapper-warning-text {
          margin: 0;
        }
      }
    }
    .app-button {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 426px) {
  .mobileWrapper {
    &-header {
      &-title {
        &-community {
          max-width: 256px;
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .mobileWrapper {
    &-header {
      &-title {
        &-community {
          max-width: 224px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .mobileWrapper {
    &-header {
      &-title {
        &-community {
          max-width: 180px;
        }
      }
    }
  }
}
