@import "../../../../styles/index.scss";

.presenter-advices {
  height: 75%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid $soft-peach-color;

  table {
    width: 100%;
    border-spacing: 5px;

    td {
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 33%;

      p {
        margin-bottom: 110px;
        &.use-laptop {
          margin-top: 5px;
        }
      }

      .join-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -75px;

        &.producer {
          margin-left: -125px;
        }
      }

      .learn-more-button {
        position: absolute;
        bottom: 50px;
        left: 50%;
        margin-left: -75px;
        margin-bottom: 10px;
      }
    }
  }
}
