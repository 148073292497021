@import "../../../../../styles/index.scss";
.eventSignUpDialog {
  font-family: $font-heebo;
  width: 100%;
  height: 100%;
  background-color: $common-panel-bg;
  overflow: auto;
  box-sizing: border-box;
  color: $white-color;
  &-container {
    margin-top: 24px;
  }
  &-description {
    font-size: 16px;
    line-height: 28px;
    color: $blue-4;
    margin-bottom: 24px;
    p {
      margin: 0;
    }
  }
  &-email,
  &-password {
    margin-bottom: 24px;
    .text-label {
      margin-left: 0;
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      color: $grey-3 !important;
    }
    .textarea {
      font-size: 16px;
      line-height: 28px;
      color: $blue-4;
    }
    .text-field {
      border-radius: 20px;
      background-color: $popup-bg;
    }
    .helper-text {
      text-align: left;
      width: 100%;
      color: $error;
      font-size: 13px;
      line-height: 28px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      height: 32px;
    }
  }
  &-forgot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;
  }
  &-forgotBtn {
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: $purple-3;
    text-decoration: none;
  }
  &-error {
    font-size: 13px;
    line-height: 25px;
    color: $error;
    margin: 24px 0;
  }
  &-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .styledTextArea {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      label + .text-field {
        margin-top: 23px !important;
      }
    }
    &.avatar {
      width: 104px;
      height: 104px;
      margin-bottom: 32px;
      .editIcons.editIcons-edit {
        border: none;
      }
    }
    &.submit {
      justify-content: flex-end;
    }
    input::-ms-reveal {
      display: none;
    }
  }
  .styledTextArea {
    .text-label {
      margin: 0;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
      color: $grey-3;
    }
    .text-field {
      background: $popup-bg;
      border-radius: 20px;
      color: $blue-4;
      min-height: 48px;
      .textarea {
        &::placeholder {
          color: $purple-2;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .MuiIconButton-root {
        padding: 8px;
      }
    }
  }
  &-alert {
    .styledDialog-text-title {
      text-transform: none !important;
    }
  }
}
