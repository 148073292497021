@import "../../../../../styles";
.hostNotes {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-editor {
    background: #382e35;
    height: 100%;
    border-radius: 10px;

    .rdw-editor-wrapper {
      .rdw-editor-toolbar {
        margin: 0;
        background: #31272e;
        border: none;
        border-radius: 10px 10px 0 0;
        .rdw-option-wrapper {
          background: none;
          border: none;
        }
      }
      .rdw-editor-main {
        padding: 10px 16px 0;
        height: calc(100vh - 340px);
        overflow-y: scroll;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $white-color;
        .DraftEditor-root {
          height: fit-content;
        }
        .public-DraftStyleDefault-block {
          margin: 0;
        }
      }
    }
  }
  &-addButton {
    margin: 32px 0 14px 0;
  }

  &-emptyState {
    @include flex(center, center, column);
    width: 100%;
    height: 100%;
    &-icon {
      @include flex();
      width: 136px;
      height: 136px;
      background-color: #382e35;
      border-radius: 50%;
      &::before {
        content: "";
        background: url("/assets/icons/host_notes_icon.svg") no-repeat center;
        background-size: contain;
        width: 79px;
        height: 59px;
      }
    }
    &-title {
      color: #7e6677;
      font-size: $font-size-normal;
      font-weight: bold;
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .hostNotes-editor {
    .rdw-editor-wrapper {
      .rdw-editor-main {
        height: calc(100vh - 400px);
      }
    }
  }
}
