@import "../styles.scss";

.previewModeSwitcher {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background: #597a98;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.19);
    border-radius: $border-radius-rounded;
    min-height: 36px;
    &.vertical {
        min-width: 36px;
        flex-direction: column;
    }
    & .switcher-button {
        background: transparent;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 0;
        margin-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
            background: $white-color;
        }
        &:last-child {
            margin-right: 0;
        }
        
    }
}
