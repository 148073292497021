@import "../../../styles/index";
%figure-shared {
  position: absolute;
  z-index: 0;
}

.authMain {
  width: 54.5%;
  background: #f8f9fa;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &-actions {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 2;
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
  }
  &-content.header {
    margin-top: 134px;
  }
  &-figures {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    &-circle {
      @extend %figure-shared;
      top: 0;
      left: 0;
      transform: translate(50%, -50%);
    }
    &-polygon {
      @extend %figure-shared;
      right: 0;
      top: 0;
      transform: translateY(-28%) rotate(-90deg);
    }
    &-arch {
      @extend %figure-shared;
      transform: translateX(50%);
      left: 0;
      bottom: -2%;
    }
    &-squarePolygon {
      @extend %figure-shared;
      transform: scale(0.65);
      right: 0;
      bottom: 0;
    }
  }
  &-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .authMain {
    width: 100%;
    &-figures {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      z-index: -1;
      background-color: $white-2;
    }
  }
}