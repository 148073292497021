@import "../../../../../styles/variables.module";

.member-dialog-edit {
  padding: 16px 24px;

  &-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: $font-heebo;
    border-bottom: 1px solid $soft-peach-color;
    padding-bottom: 16px;
    margin-bottom: 16px;

    .item {
      margin-bottom: 16px;
      padding: 0;

      &.uploader {
        height: 104px;
        width: 104px;
      }

      input {
        font-size: 14px;
      }
    }
  }

  &-admin {
    span {
      font-size: 14px;
    }
  }

  .grey-text {
    margin-top: 0;
    font-size: 14px;
    color: $grey-color;
  }
}
