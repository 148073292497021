@import "../../../styles/index.scss";
.styledCheckBox {
  &:hover {
    background-color: unset !important;
  }
  &-icon {
    width: 24px;
    height: 24px;
    background: $background-color;
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    background-position: center;
    border: 2px solid transparent;
    &.withBorder {
      border-color: #f0b48f;
    }
  }
  &-checkedIcon {
    background-image: url("/assets/icons/checked_box.svg");
  }
  &.light {
    .styledCheckBox {
      &-icon {
        background: $blue-4;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
        box-sizing: border-box;
        background-position: center;
        border: 2px solid transparent;
        &.withBorder {
          border-color: $white;
        }
      }
      &-checkedIcon {
        background-image: url("/assets/icons/checked_box_light.svg");
      }
    }
  }
}
