@import "../../../../../styles/variables.module";

.community-dialog-view {
  &-main {
    .avatar {
      width: 112px;
      height: 112px;
      margin: 8px 0 24px;
    }

    .item {
      font-size: 16px;
      color: $grey-1;
      line-height: 24px;
      padding: 0 24px 16px;
    }

    .stripe-link {
      display: inline-block;
      text-transform: capitalize;
      color: $blue-color;
      background: none;
      position: relative;
      margin: 5px 18px 5px 0;
      line-height: 23px;


      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: url("/assets/icons/back_arrow_icon.svg") center right 5px no-repeat;
        transform: rotate(-180deg);
        z-index: 1;
      }
    }
  }

  &-additional {
    background-color: #fcfafa;
    padding: 24px;

    &-description {
      font-size: 13px;
      line-height: 20px;
      color: $grey-3;
      margin: -8px 0 0;
    }

    .last-row {
      margin: 32px 0 24px;
      padding-bottom: 24px;

      &.with-border {
        border-bottom: 1px solid $beige-3;
      }
    }

    .field {
      padding-top: 0;

      &:first-of-type {
        padding-right: 5px;
        box-sizing: border-box;
      }

      .label {
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
        margin-bottom: 8px;
      }

      .value {
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;

        .copy-item {
          margin-top: -8px;
          &-content {
            border: none;
            padding: 8px 28px 8px 0;

            &::after {
              right: 0;
            }
          }
        }
      }
    }
  }
}
