@import "../../../../styles/variables.module.scss";

.login-buttons {
  color: $white-color;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &_btn_wrap {
    display: flex;
    justify-content: space-between;
  }
}
