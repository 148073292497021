@import "../../../styles/index.scss";

.outlinedRedButton {
  border-radius: $border-radius;
  border: 2px solid $red-color-bright !important;
  background: transparent;
  color: $red-color-bright;
  transition: 0.2s;
  &-text {
   vertical-align: middle;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  &:hover {
    background: $red-color-bright;
    color: $white-color;
  }
}
