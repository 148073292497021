@import "../../../../styles/mixins";
.stream-content {
  &.eight-to-eight {
    min-width: 100%;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
  }
}

.full-meeting {
  width: 100%;
  height: 100%;
  position: relative;
  @include flex;
  .emptyText-message {
    color: $purple-2;
    font-size: 27px;
  }
}
