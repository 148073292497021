@import "../../../../../styles/index.scss";
.sermon {
  height: 100%;
  margin: 0 16px 16px 0;
  border-radius: $border-radius;
  box-shadow: $card-shadow;
  background: $white-color;
  font-family: $font-heebo;
  cursor: pointer;
  &-available {
    background: $orange-color;
  }
  &.disabledCard {
    cursor: not-allowed;
    .sermon-card-roles,
    .sermon-card-heading,
    .sermon-card-name,
    .sermon-card-edit,
    button {
      filter: grayscale(80);
      color: $grey-3;
      cursor: not-allowed;
    }
    .sermon-card-delete {
      cursor: pointer;
    }
    .three-dots-popup {
      &:hover {
        cursor: not-allowed;
        .three-dots-popup-wrapper {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 312px;
    box-sizing: border-box;
    background: $white-color;
    box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: relative;
    &-heading {
      width: 312px;
      min-height: 48px;
      height: 48px;
      background: #d8e1e9;
      box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.05);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &-timing {
      width: 212px;
      height: 16px;
      margin-left: 17px;
      margin-top: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #7b9eb9;
    }
    &-roles {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px 8px 0;
      &-item {
        width: 18px;
        height: 16px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-name {
      width: 280px;
      height: 16px;
      padding: 16px 16px 12px 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #181611;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-details {
      width: 280px;
      margin: 0 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #181611;
      word-break: break-word;
      @include truncate-lines(3);
    }
    &-edit,
    &-copy-url {
      button {
        text-align: end;
        margin-right: 4px;
        letter-spacing: normal;
      }
    }
    &-delete {
      width: 54px;
      letter-spacing: 0.04em;
      color: #ef7171;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      text-transform: capitalize;
    }
    &-speaker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
      &-label {
        color: $grey-color;
        margin-right: 4px;
        margin-left: 16px;
        text-transform: capitalize;
      }
      &-content {
        color: $black-color;
        @include truncate-line;
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 300px;
      height: 52px;
    }
    &-ongoing {
      margin: 12px auto 12px 12px;
      padding: 2px 8px;
      height: 24px;
      background: #e1a077;
      border-radius: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: $white-color;
    }
    &-ended {
      margin: 12px auto 12px 12px;
      padding: 2px 8px;
      height: 24px;
      background: #f3f3f3;
      border-radius: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8c8c8c;
    }
    &-draft {
      margin: 12px auto 12px 12px;
      padding: 2px 8px;
      height: 24px;
      background: #f3f3f3;
      border-radius: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8c8c8c;
      text-transform: capitalize;
    }
    &-popup {
      &-item {
        width: 80px;
        padding: 12px 40px;
        display: flex;
        align-items: center;
        color: #7b9eb9;
      }
      &-divider {
        width: 88px;
        height: 0px;
        border: 1px solid #e8e8e8;
      }
      &-delete {
        width: 80px;
        height: 24px;
        padding: 12px 40px;
        display: flex;
        align-items: center;
        color: #ef7171;
      }
    }
  }
  &-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &-inactive {
      opacity: 0.31;
    }
    &-container {
      display: flex;
      align-items: center;
      padding-right: 5px;
    }
    &-date {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: $black-color;
      margin: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-memberImgContainer {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.sermon-card-edit button {
  text-align: end;
  margin-right: 4px;
  letter-spacing: normal;
}

.statusContainer {
  display: flex;
  align-items: center;
  &-inactive {
    margin: 0 5px 0 0;
    font-size: 12px;
    color: $black-color;
  }
}
