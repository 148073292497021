@import "../../../../styles/variables.module";

.communityMembersContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $white-color;
  &-title {
    text-transform: uppercase;
    margin: 0;
    padding: 5.5px 24px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.06em;
    color: $blue-color;
    background-color: $tabs-blue-color;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &-members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px;
  }
}
