@import "../../../styles/index.scss";

.styledLink {
    font-family: $font-heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    width: auto;
    &:hover {
        text-decoration: none !important;
    }
    &.MuiTypography-colorPrimary {
        color: $blue-color;
    }
}
