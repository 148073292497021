@import "../../../../../styles/index.scss";

.leave-event.icon {
  @include display-flex-center();
  border: 2px solid #EF7171;
  border-radius: 8px;
  background: none;

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    mask: url("/assets/icons/exit_icon.svg");
    mask-size: cover;
    mask-repeat: no-repeat;
    background: #EF7171;
  }
}
