@import "../../../../../styles/index.scss";

.communityDropDown {
  box-sizing: border-box;
  width: 100%;
  background-color: $blue-color;
  display: flex;
  flex-direction: column;
  font-family: $font-heebo;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: $white-color;

  &-selected {
    width: calc(100% - 48px);
    padding: 10px 24px;
    word-wrap: break-word;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      margin-right: 5px;
    }

    &.collapsed {
      border-bottom: none;
      padding: 0 8px 24px;
      .dashboard-logo {
        width: 40px;
        height: 40px;
        margin-right: 0;
      }
    }
  }

  &-opened {
    height: calc(100vh - 24px);
    position: absolute;
    z-index: 3;
    background-color: $light-blue-color;
  }

  &-item {
    display: flex;
    align-items: center;

    &.option {
      padding: 10px 24px;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: $light-blue-color;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        width: calc(100% - 48px);
        border-bottom: 1px solid $light-blue-color;
      }
    }
  }

  &-options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $blue-color;
    z-index: 3;
    height: 100%;
    width: 100%;
    overflow: auto;

    &-list {
      overflow-y: scroll;
      height: calc(100% - 60px);
    }
    &-controls {
      padding: 24px;
    }
  }

  .arrow-container {
    cursor: pointer;
  }
}
