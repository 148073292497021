@import "../../../../../styles/variables.module";

.appearance-details {
  display: flex;
  .styledTextArea label + .text-field {
    margin-top: 16px !important;
  }
  .left-side,
  .right-side {
    flex: 50%;
  }
  .left-side {
    margin-right: 48px;
  }
  .radio-button-wrapper {
    margin-top: 24px;
    margin-bottom: 16px;
    .radio-button-title {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #382e35;
    }
    .radio-options {
      flex-direction: row;
      .MuiFormControlLabel-root {
        width: 150px;
      }
      .styledRadio-icon {
        height: 43px;
        width: 43px;
        background: url(/assets/icons/radio.svg);
        background-repeat: no-repeat;
        background-size: auto;
        border-radius: 0;
        background-color: none;
        &.styledRadio-checkedIcon {
          background: url(/assets/icons/selected-radio.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .image-selector-wrapper {
    min-width: 344px;
    min-height: 145px;
    .imageUpload {
      // min-height: 232px;
      position: relative;
      overflow: visible;
      .imageUpload-select {
        min-height: 145px;
        background-image: url(/assets/icons/default-image-upload.svg) !important;
        .imageUpload-select-icon {
          display: none;
        }
      }
    }
    .label-error {
      margin-top: 16px;
      color: #ef7171;
      font-size: 13px;
      line-height: 20px;
    }
    .imageUpload-uploaded {
      width: 145px;
      margin: 0 auto;
      .imageUpload-uploaded-options {
        display: none;
      }
    }
    .image-information-wrapper {
      .image-information-title {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #181611;
        margin-bottom: 16px;
      }
    }
    .image-choose-wrapper {
      margin-bottom: 16px;
      position: absolute;
      bottom: -70px;
      .image-choose-title {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: #7b9eb9;
        &.replace {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          letter-spacing: 0.04em;
          text-transform: capitalize;
          color: #7b9eb9;
          left: 20px;
        }
      }
    }
    .replace-button-wrapper {
      margin-top: 24px;
      button {
        border: none;
        background: none;
        outline: none;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        span {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          letter-spacing: 0.04em;
          text-transform: capitalize;
          color: #ef7171;
          margin-left: 40px;
        }
        &::before {
          content: " ";
          background: url(/assets/icons/ic_remove.svg) no-repeat 100%;
          position: absolute;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          border: none;
          outline: none;
          top: 10px;
        }
      }
    }
  }
  .color-picker-wrapper {
    margin-bottom: 24px;
    .color-picker-title {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      display: flex;
      align-items: center;
      color: #181611;
    }
    .color-picker-input {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    label {
      display: none;
    }
    .colorpicker-wrap {
      margin-top: 16px;
      margin-left: 16px;
      .colorpicker {
        background: none;
        width: 64px;
        height: 40px;
        padding: 0;
        .colorpicker-selectedColor {
          width: 100%;
          height: 100%;
          border-radius: 0;
        }
        .arrow-container {
          display: none;
        }
      }
    }
  }
}
