@import "../styles.scss";

.empty-presenter {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    margin-bottom: 16px;
    padding-top: 16px;
    position: relative;
    &-text {
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $grey-color;
        padding: 8px 0;
    }
    &-helper-text {
        color: $red-color;
        font-size: 12px;
        line-height: 16px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-align: left;
        position: absolute;
        bottom: -14px;
        right: 10px;
        margin: 0;
    }
}
