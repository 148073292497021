@import "../../../../styles";

.MuiSnackbarContent-message {
  width: 100%;
  max-width: 360px;
}

.notification-announcement-snackbar {
  .MuiSnackbarContent-root {
    background-color: #382E35;
    border-radius: 10px;
  }
}

.notification-announcement {
  width: 100%;
  cursor: pointer;
  font-family: $font-heebo;
  .notification-announcement-title {
    color: $purple-4;
    font-size: $font-size-normal;
    font-weight: bold;
    margin-top: 0;
  }
  .notification-announcement-message {
    font-size: $font-size-small;
    @include truncate-lines(4);
  }
  button {
    margin-top: 16px;
    width: 100%;
  }
}