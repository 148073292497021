@import "../../../../../../styles/index";

.stream-preview {
  display: grid;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  grid-template-columns: auto 376px 96px;
  grid-template-rows: 112px auto;
  grid-template-areas:
    "header header header"
    "main chats menu";
  background-color: $nav-bg;

  &-header {
    grid-area: header;
    @include flex(flex-end, center);
    padding: 0 20px 0 40px;

    > button {
      margin-left: 24px;
      letter-spacing: 0.04em;
      border-radius: 40px;
      height: 48px;

      .close {
        display: inline-block;
        mask: url("/assets/icons/cross_icon.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        background: $purple-3;
        width: 10px;
        height: 10px;
        margin-left: 15px;
      }
    }

    &-el1 {
      @include flex(space-between, flex-start, column);
      margin-left: 44px;
      margin-right: auto;
      height: 54px;
      &-top {
        width: 267px;
        height: 20px;
        border-radius: $border-radius-rounded;
        background-color: $purple-3;
        opacity: 0.4;
      }
      &-bottom {
        width: 372px;
        height: 16px;
        border-radius: $border-radius-rounded;
        background-color: $purple-color;
        opacity: 0.4;
      }
    }

    &-el2 {
      width: 48px;
      height: 48px;
      border-radius: 50px;
      background-color: $purple-2;
      opacity: 0.4;
      margin-left: 24px;
    }
  }

  &-main {
    grid-area: main;
    @include flex(flex-start, center, column);
    background-color: #282025;
    overflow: hidden;

    &-auditoriumView {
      @include flex(flex-start, center, column);
      width: 704px;
      margin-top: 40px;
      &-rows {
        @include flex(space-between, center, row);
        flex-wrap: wrap;
        margin-top: 20px;
        .row-item {
          img {
            margin: 7px 5px;
          }
          &.lastItem {
            &:last-child {
              margin: 0 auto;
            }
          }
        }
      }
      &-tables {
        @include flex(flex-start);
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        margin-top: 40px;
      }
      &-back-img {
        width: 100%;
        height: 400px;
        border-radius: 20px;
        border: $purple-2 solid;
        background-image: url("/assets/images/count_down_img.svg");
        background-repeat: no-repeat;
        background-position: bottom left 5%;
      }
      &-slides {
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-height: 380px;
        max-width: 848px;
        padding-bottom: 6px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &-lobbyView {
      height: 100%;
      &-tables {
        margin-top: 40px;
        width: 732px;
        img {
          margin: 4px 8px;
        }
      }
      &-classrooms {
        @include flex(center, center, column);
        height: 100%;
        margin-top: 40px;
        img {
          margin: 16px 0;
        }
      }
    }

    &-membersCounter {
      position: absolute;
      width: 174px;
      height: 40px;
      border-radius: 20px 20px 0 0;
      background-color: $blue-1;
      bottom: 0;
      z-index: 3;
      &-el1 {
        width: 101px;
        height: 15px;
        border-radius: 5px;
        background-color: $blue-3;
        margin: 12.5px auto;
      }
    }
  }

  &-chat {
    grid-area: chats;
    @include display-flex-center;
    flex-direction: column;
    background-color: $common-panel-bg;

    &-header {
      width: 100%;
      height: 64px;
      box-sizing: border-box;
      @include display-flex-center;
      justify-content: space-between;
      flex-shrink: 0;
      padding: 0 24px;
      border-bottom: 1px solid rgba(147, 128, 141, 0.4);
      box-shadow: 0 25px 10px rgba(0, 0, 0, 0.5);
      position: relative;
      z-index: 1;

      > div {
        background-color: $purple-2;
        opacity: 0.6;
        border-radius: 5px;
      }

      &-el1 {
        width: 217px;
        height: 15px;
      }

      &-el2 {
        width: 15px;
        height: 15px;
      }
    }

    &-main {
      width: 100%;
      height: 100%;
      max-height: 533px;
      overflow: hidden;
      padding: 0 24px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 15px;

      img {
        position: absolute;
        bottom: 0;
      }
    }

    &-footer {
      width: 328px;
      height: 48px;
      margin: auto 0 28px;
      @include display-flex-center;
      justify-content: flex-start;
      flex-shrink: 0;
      background: $popup-bg;
      border-radius: 20px;
      padding: 0 14px;
      box-sizing: border-box;

      > div {
        height: 15px;
        background-color: $purple-2;
      }

      &-el1 {
        width: 15px;
        opacity: 0.6;
        border-radius: 5px;
      }

      &-el2 {
        width: 147px;
        opacity: 0.2;
        margin-left: 9px;
        border-radius: 5px;
      }

      &-el3 {
        width: 15px;
        opacity: 0.6;
        border: 4px solid rgba(111, 95, 106, 0.7);
        border-radius: 8px;
        margin-left: auto;
      }
    }
  }

  &-menu {
    grid-area: menu;
    padding: 24px 0 14px;
    @include display-flex-center;
    flex-direction: column;
    justify-content: flex-start;

    &-el1 {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: $purple-2;
      opacity: 0.4;
      margin: 18px 0;

      &:first-of-type {
        opacity: 0.8;
      }

      &:last-of-type {
        margin-top: auto;
      }
    }

    &-el2 {
      width: 100%;
      height: 2px;
      background-color: $blue-3;
      opacity: 0.1;
      margin-top: 24px;
    }
  }
}
