@import "../../../styles/variables.module";

.renameGreeterTitle {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  box-sizing: border-box;
  width: 100%;

  &-description {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1d1d1d;
    margin-bottom: 20px;
    &.variantBrown {
      color: $purple-3;
    }
  }

  &-form {
    width: 100%;
    .text-label {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.04em;
      margin-left: 0;
    }

    .text-field {
      border-radius: 20px;
      background: #f5f5f5;
      input {
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
      }
    }

    &.variantBrown {
      .text-field {
        background: #282025;
        input {
          color: $blue-3;
        }
      }
    }
  }
}
