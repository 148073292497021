.private-badge {
  display: flex;
  align-items: center;
  text-align: center;
  &-icon {
    path {
      fill: var(--text-color);
    }
  }
  &-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--text-color);
    text-align: center;
    margin-left: 8px;
  }
}