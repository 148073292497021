@import "../../../../styles/index.scss";

.sendConfirmation {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  &-container {
    width: 100%;
    max-width: 448px;
  }
  &-image {
    width: 100%;
  }
  &-header {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 48px;
    line-height: 61px;
    color: $black-color;
    margin: 0 0 24px;
  }
  &-form {
    .emailField {
      margin-bottom: 32px;
    }
  }
  &-existing-error {
    margin-bottom: 32px;
    &-description {
      font-size: 16px;
      line-height: 28px;
      color: $white-color;
      position: relative;
      margin: 0 0 8px;
      padding-left: 30px;
      &::before {
        content: "";
        background: url("/assets/icons/exclamation_icon.svg") no-repeat 100%;
        left: 0;
        width: 24px;
        height: 24px;
        position: absolute;
      }
    }
    &-link {
      color: $orange-color;
      font-family: $font-lora;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding-left: 30px;
      &::after {
        content: "";
        background: url("/assets/icons/right_arrow.svg") no-repeat 100%;
        right: 0;
        width: 16px;
        height: 16px;
        padding-left: 8px;
      }
    }
  }
}
