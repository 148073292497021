@import "../../../styles/index.scss";
.styledTextArea {
  .text-field {
    font-family: $font-heebo !important;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $dark-blue;
    border-radius: $border-radius;
    padding: 6px 16px;
    min-height: 40px;
    mix-blend-mode: normal;
    background-color: $input-background;
    .MuiIconButton-root {
      &:hover {
        background-color: transparent;
      }
    }
    &.MuiInputBase-adornedEnd {
      padding-right: 0;
    }
  }
  .text-area {
    border-radius: $border-radius;
  }
  .textarea {
    width: 100%;
    height: 100%;
    padding: 0;
    resize: none;
    outline: none;
  }
  .helper-text {
    font-family: $font-heebo;
    color: $red-color;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    text-align: right;
  }
  .disabled-text {
    color: $purple-color;
  }
  label+.text-field {
    margin-top: 22px !important;
  }
  .text-label {
    font-size: 13px;
    line-height: 20px;
    color: $grey-4 !important;
    margin-left: 16px;
    transform: translate(0, 0) scale(1) !important;
    .MuiFormLabel-asterisk {
      color: $purple-color !important;
    }
  }
  .label-error {
    color: #a3a7c1 !important;
  }
  .password-icon {
    width: 22px;
    height: 18px;
  }
}