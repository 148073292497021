@import "../../../../styles/variables.module";

.noMatchesFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  &-img {
    width: 50px;
  }
  &-text {
    font-size: 21px;
    letter-spacing: 0.02em;
    text-align: center;
    color: $grey-color;
    margin: 10px 0 0 0;
  }
}
