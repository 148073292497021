@import "../../../../styles/variables.module";
@import "../../../../styles/mixins.scss";

.communityDashboardWrapper {
  width: 100%;
  background-color: $white-2;
  display: flex;
  flex-direction: column;
  align-items: center;

  .communityDashboard {
    max-width: 1144px;
    width: 100%;
    margin: 42px auto;
    padding-bottom: 140px;
    &__presentCard {
      @include flex(center, flex-start, column);
      width: 100%;
      height: 252px;
      padding: 40px 56px 28px;
      border-radius: 10px;
      background-image: url("/assets/images/community_dashboard_presentCard.png");
      background-size: 1140px;
      background-repeat: no-repeat;
      background-position: left;
      .communityName {
        @include flex(flex-start);
        font-family: Lora;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 130%;
        color: $grey-1;
        width: 600px;
        height: 100%;
      }
      .description {
        @include flex(flex-start, flex-start);
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
        margin-top: 16px;
        width: 600px;
        height: 100%;
      }
    }

    &__createCards {
      width: 100%;
      margin-top: 40px;
      .title {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 16px;
      }
      .cards {
        @include flex(flex-start);
      }
    }

    &__personalMeetings {
      width: 100%;
      @include flex(center, flex-start, column);

      .title {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: $dark-purple;
        margin: 56px 0 0;
      }
      .meetings {
        width: 100%;
        @include flex(space-between);
        flex-wrap: wrap;
      }
      .addNewRoom {
        button {
          margin-top: 35px;
          border: none;
          background: none;
          outline: none;
          @include flex(flex-start);
          position: relative;
          cursor: pointer;
          span {
            font-family: Heebo;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: $blue-1;
            margin-left: 40px;
          }
          &::before {
            content: " ";
            background: url(/assets/icons/add-navigation-link.svg) no-repeat 100%;
            position: absolute;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border: none;
            outline: none;
            top: 10px;
          }
        }
      }
      &--disabledCard {
        filter: grayscale(80%);
        .meetingRoomCard {
          cursor: not-allowed;
        }
        .addNewRoom {
          button {
            cursor: not-allowed;
          }
        }
      }
    }
    &__engagements {
      width: 100%;
      .title {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: $black-color;
        margin: 64px 0 24px;
      }
      .content {
        @include flex(space-between, flex-start);
        &__cards {
          @include flex(space-between, flex-start, column);
          height: 368px;
        }
        &__video {
          @include flex();
          width: 560px;
          height: 368px;
        }
      }
    }
  }
  &.subscriptionAttention {
    .attentionBanner-wrapper {
      position: -webkit-sticky;
      position: sticky;
      top: 5px;
      padding: 0 24px;
      box-sizing: border-box;
      width: 100%;
      z-index: 1;
    }
    .communityDashboard-wrapper {
      margin-top: -5px;
    }
  }
}
