@import "../../../../../../styles/index.scss";

.event-schedule {
  margin: -10px 0 24px;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;

  .error {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 11px;
    color: $red-color;
    margin: 0;
  }

  .number {
    width: 70px;
  }

  .row {
    @include display-flex-center;
    justify-content: flex-start;
    position: relative;
    width: fit-content;
  }

  &-frequency {
    .number {
      margin: 0 16px;
    }
  }

  &-days {
    margin: 24px 0;

    .row {
      width: 272px;
      margin-top: 13px;
      justify-content: space-between;

      .day-icon {
        @include display-flex-center;
        font-size: 13px;
        font-weight: bold;
        color: $blue-1;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $blue-4;
        cursor: pointer;

        &.checked {
          color: $white-color;
          background-color: $blue-1;
        }
      }
    }
  }

  &-ends {
    .datePicker-wrapper {
      width: 140px;
    }
    .label {
      width: 64px;
    }
    .number {
      margin-right: 8px;
    }
    .ends-options {
      margin-left: -9px;
      .option {
        margin: 3px 0;
      }
    }
  }
}
