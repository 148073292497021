@import "../../../../../styles";
.landing-footer {
  width: 100%;
  max-width: 100%;
  background: var(--accent-color);
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  .information-divider {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-bottom: 1px solid var(--text-color-link);
    opacity: 0.2;
  }
  &-wrapper {
    width: 100%;
    max-width: $landing-page-width;
    margin: 0 auto;
    height: 217px;
    box-sizing: border-box;
    @include flex(center, center, column);
    box-sizing: border-box;
    .information-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .information-community {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 30;
        .logo-wrapper {
          margin-right: 32px;
          img {
            max-height: 56px;
            width: auto;
          }
        }
        &-name {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          color: var(--text-color-link);
          max-width: 248px;
          overflow: hidden;
          padding-right: 24px;
          text-overflow: ellipsis;
          &.sm {
            font-size: 14px;
            line-height: 20px;
          }
          &.md {
            font-size: 16px;
            line-height: 22px;
          }
          &.lg {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
      .links-wrapper {
        flex: 50;
        display: flex;
        justify-content: flex-end;
        width: 745px;
        a {
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: Heebo;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: 1px;
          text-transform: var(--menu-case);
          color: var(--text-color-link);
          margin: auto 20px auto 0;
          cursor: pointer;
          text-decoration: none;
          white-space: nowrap;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .address-wrapper {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        margin-left: 32px;
        color: var(--text-color-link);
        position: relative;
        &::before {
          content: "";
          background: url("/assets/icons/gridicons_location.svg");
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          left: -32px;
          top: -4px;
        }
        &:empty {
          &::before {
            content: none;
          }
        }
      }
      .poweredBy {
        position: relative;
        font-size: 16px;
        line-height: 130%;
        color: var(--text-color-link);
        padding-right: 8px;
        margin-right: 24px;
        text-transform: none;
        font-weight: normal;
        &::before {
          content: "";
          background: url("/assets/icons/heart_icon.png");
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          right: -24px;
          top: -2px;
        }
        &:hover {
          text-decoration: underline !important;
        }
      }
      &.top {
        padding: 44px 0 38px;
      }
      &.bottom {
        position: relative;
        padding: 25px 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .landing-footer {
    &-wrapper {
      .information-wrapper {
        &.top {
          padding: 24px 0;
        }
        .links-wrapper {
          a {
            margin-right: 20px;
          }
        }
      }
    }
    .information-divider {
      bottom: 88px;
    }
  }
}

@media screen and (max-width: 900px) {
  .landing-footer {
    &-wrapper {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 800px) {
  .landing-footer {
    padding: 0;
    &-wrapper {
      height: 38px;
      padding: 0;
      background: var(--backgroud-color);
      border-top: none;
      .information-wrapper {
        .address-wrapper {
          display: none;
        }
        &.top {
          display: none;
        }
        &.bottom {
          padding: 12px 0;
          justify-content: center;
        }
        .poweredBy {
          margin-right: 32px;
          font-size: 11px;
          line-height: 130%;
          &::before {
            transform: scale(0.6);
            right: -24px;
            top: -4px;
          }
        }
      }
    }
    .information-divider {
      bottom: 38px;
    }
  }
}
