.loaderContainerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .loaderChildrenWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .loading {
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
}