@import "../../../styles/variables.module";

.downItems {
  text-align: left;
  z-index: 100;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  background: #FFFFFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  width: 100%;
  overflow: auto;
  max-height: 200px;

  &-item {
    color: $black-color;
    background-color: $white-color;
    padding: 8px 16px;
    cursor: pointer;

    &-current {
      color: #4B9F22;
    }

    &:hover {
      background-color: darken($white-color, 3);
    }

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }

  &-additional {
    margin: 8px 16px;
    border-top: 1px solid $light-grey-color;

    &-title {
      font-size: 12px;
      line-height: 150%;
      color: $grey-color;
      font-weight: normal;
      margin: 8px 0;
    }

    &-text {
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $black-color;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0;
      background: none;
    }
  }
}
