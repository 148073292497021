@import "../../../../styles/index.scss";
@import "../../../../styles/mixins.scss";
.auth {
  font-family: $font-heebo;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  &-title {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: $black-color;
    margin: 0;
  }
  &-error-container,
  &-description,
  &-text,
  &-linkContainer,
  &-submitBtn,
  &-forgotBtn &-error {
    width: 424px !important;
    @include mobile($mobile-width) {
      width: 100% !important;
    }
  }
  &-aside {
    &-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-root {
    &.join,
    &.login,
    &.forgot,
    &.reset {
      max-width: 416px;
      margin: 0 16px;
      .back {
        display: inline-block;
        color: $blue-color;
        position: relative;
        margin: 10px 0;
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 20px;
          height: 20px;
          background: url("/assets/icons/back_arrow_icon.svg") center right 5px no-repeat;
          z-index: 1;
        }
      }
      .block-description {
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
        margin-top: 10px;
        width: 100%;
        @include mobile($mobile-width) {
          font-size: 13px;
          line-height: 20px;
          color: #8C8C8C;
          text-align: center;
          margin: 16px 0 32px 0;
        }
      }
      .divider {
        width: 320px;
        position: relative;
        margin: 30px 0 20px;
        .text {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          color: $blue-3;
          position: relative;
          background: $white-2;
          z-index: 1;
          width: 85px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
        .line {
          position: absolute;
          width: 100%;
          height: 1px;
          background: $blue-3;
          top: 12px;
        }
      }
      .submit-button {
        margin-top: 24px;
      }
      #loginForm {
        @include mobile($mobile-width) {
          width: 90%;
        }
      }
    }
    &.join {
      .auth-description {
        @include mobile($mobile-width) {
          display: none;
        }
      }
      .auth-description-subtitle {
        margin-top: 16px;
        margin-bottom: 24px;
      }
      .MuiGrid-container {
        @include mobile($mobile-width) {
          display: block;
        }
      }
    }
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-margin {
      margin-bottom: 40px;
    }
    .auth-forgotBtn,
    .auth-no-access {
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      margin-top: 10px;
      text-decoration: none;
      color: $blue-color;
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
    }
    .auth-no-access {
      margin: 22px 0 20px;
    }
    &-sign-up {
      padding: 24px 0;
      align-items: center;
      flex-direction: column;
      color: $blue-color;
      font-size: 12px;
      line-height: 18px;
      .sign-up-link {
        display: inline-block;
        text-transform: capitalize;
        color: $blue-color;
        background: none;
        position: relative;
        margin: 0 20px 0 auto;
        letter-spacing: 1.6px;
        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background: url("/assets/icons/back_arrow_icon.svg") center right 5px no-repeat;
          transform: rotate(-180deg);
          z-index: 1;
        }
      }
    }
    .login-google {
      margin-bottom: 16px;
    }
  }
  &-logo-title {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: $purple-color;
    margin-top: 8px;
  }
  &-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-description {
    &-title {
      font-family: $font-lora;
      font-weight: bold;
      font-size: 26px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: $grey-1;
      @include mobile($mobile-width) {
        text-align: center;
      }
    }
    &-subtitle {
      font-family: $font-heebo;
      font-size: 16px;
      line-height: 150%;
      position: relative;
      padding: 0 0 24px;
      margin: 0 0 30px;
      color: $grey-1;
      &::before {
        @include divider-short;
      }
      @include mobile($mobile-width) {
        font-size: 13px;
        line-height: 20px;
        color: #8C8C8C;
        &:before {
          display: none;
        }
      }
    }
  }
  &-description-mobile {
    display: none;
    .auth-description-logo {
      @include flex();
      margin-bottom: 48px;
    }
    .auth-description-title,
    .auth-description-subtitle {
      text-align: center;
      &:before {
        display: none;
      }
    }
    @include mobile($mobile-width) {
      display: block;
    }
  }
  &-text {
    font-family: $font-heebo;
    font-size: 12px;
    line-height: 130%;
    min-height: 40px;
    margin-top: 24px;
    color: $black-color;
    &-forgot,
    &-resetCompleted {
      font-size: 18px;
      line-height: 160%;
    }
  }
  &-error {
    border-radius: 8px;
    font-family: $font-heebo;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 0 24px 0;
    color: $red-color;
    margin: 4px 0;
    box-sizing: border-box;
  }
  &-forgotInput {
    margin-bottom: 0 !important;
  }
  &-linkContainer {
    text-align: right;
    font-family: $font-heebo;
    font-size: 14px;
    text-decoration: none;
    letter-spacing: 0.02em;
    &-label {
      margin-top: 32px !important;
      color: $grey-color;
    }
    &-link {
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 22px 0 0;
      text-decoration: none;
      color: $blue-color;
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-submitBtn {
    text-transform: uppercase;
    height: 40px;
    font-family: $font-heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    border-radius: 0 !important;
  }
}

.registration {
  &-error {
    color: $red-color;
  }
}

@media screen and (max-width: 1000px) {
  .auth {
    min-height: 50vh;
  }
}

@media screen and (max-width: 450px) {
  .auth {
    &-error-container,
    &-description,
    &-text,
    &-linkContainer,
    &-error {
      width: 312px !important;
    }
    &-root {
      &.join {
        .join-email {
          input {
            // MuiInputBase-input has a 18px !important
            font-size: 14px !important;
          }
        }
      }
      .divider {
        width: 270px!important;
      }
    }
  }
}
