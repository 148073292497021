@import "../../../../styles/variables.module";
@import "../../../../styles/mixins";
.membersList {
  width: 100% !important;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 88px 0 0 0 !important;
  padding: 0 20px 8px;
  background-color: $white-2;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  position: relative;
  &.subscriptionAttention {
    margin-top: 120px !important;
    height: calc(100vh - 120px);
  }
  &::-webkit-scrollbar-thumb {
    background: #d8e1e9;
    width: 8px;
  }
  &-card {
    display: flex;
    align-self: stretch;
  }
  &-menu {
    @include flex;
    .button {
      margin-right: 16px;
    }
    .withDivider {
      position: relative;
      margin-right: 48px;
      &:after {
        content: "";
        position: absolute;
        border-left: 1px solid $blue-3;
        right: -24px;
        height: 24px;
        top: -2px;
      }
    }
    .icon {
      position: relative;
      @include flex(flex-end);
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
      }
    }
    .remove {
      &::before {
        background-image: url(/assets/icons/trash_white.svg);
        background-repeat: no-repeat;
      }
    }
    .export {
      &::before {
        background-image: url(/assets/icons/download.svg);
        background-repeat: no-repeat;
      }
    }
  }
}

.header-title {
  @include flex(center, flex-end);
  h1 {
    margin: 0 16px 0 0;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $black-color;
  }
  &-count {
    margin-right: 8px;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $blue-1;
  }
  &-selected {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: $blue-2;
  }
}

.cannot-remove-popup {
  width: 380px;
}

@media screen and (max-width: 750px) {
  .membersList {
    min-width: 700px;
    overflow-x: scroll;
  }
}
