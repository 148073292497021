@import "../../../styles/index.scss";

.scheduler {
  &-line {
    position: relative;
  }

  &-dateSquare {
    position: absolute;
    width: 48px;
    height: 64px;
    left: -25px;
    border-radius: $border-radius;
    overflow: hidden;
  }

  &-leftBlock {
    display: flex;
    justify-content: center;
    width: 48px;
    margin-right: 24px !important;
  }

  &-dateInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-style: normal;
    text-align: center;
    &-dayOfWeek {
      margin: 0 auto;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      color: #7B9EB9;
    }
    &-day {
      margin: 0 auto;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: #7B9EB9;
    }
  }

  &-childrenContainer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    height: max-content;
  }
}

@media screen and (max-width: 1071px) {
  .scheduler {
    &-childrenContainer {
      justify-content: start;
    }
  }
}
