@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins.scss";

.reports {
  width: 100%;

  &-item {
    width: 1144px;
    height: 56px;
    background: $white-color;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 0 24px;
    @include flex(space-between);
    box-sizing: border-box;

    &-file {
      @include flex();
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
      img {
        margin-right: 14px;
      }
    }

    &-action {
      color: $blue-1;
      font-family: "Heebo";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
}
