@import "../../../../../styles/index.scss";

.productTour {
  @include display-flex-center;
  justify-content: space-between;
  &-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    margin: 0;
  }
  &-message {
    font-size: 13px;
    line-height: 25px;
    color: $grey-3;
    margin: 0;
  }
  .productTour-btn {
  @include display-flex-center;
    background: $blue-color;
    color: $white-color;
    border-radius: $border-radius;
    text-transform: capitalize;
    padding: 4px 32px;
    height: 40px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.04em;
  }
}
