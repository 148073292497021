@import "../styles.scss";

.integrations-item {
  max-width: 1024px;
  min-height: 176px;
  width: 100%;
  background: $white-color;
  box-shadow: $card-shadow;
  border-radius: $border-radius;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  &.integrations-item-view {
    box-shadow: none;
    border: 1px solid $soft-peach-color;
  }
  &-image {
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: $background-color, $alpha: 0.5);
  }
  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 32px;
  }
}
