@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";

.subscription-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .subscription-detail-information {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.08);
    width: 680px;

    .header-information-wrapper {
      display: flex;

      .plan-title-wrapper {
        display: flex;
        flex: 60%;
        align-content: center;
        flex-direction: column;

        .title-information {
          display: flex;
          margin-bottom: 12px;

          .plan-title {
            font-family: "Lora";
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 26px;
            max-width: 220px;
          }

          .badge {
            display: flex;
            justify-content: center;
            width: 97px;
            border-radius: 14px;
            margin: 0 16px;
            font-family: "Heebo";
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 1px;
            align-items: center;
            height: 24px;
            text-transform: uppercase;

            &.trialing {
              color: #e1a077;
              background: #fcf0e9;
            }

            &.canceled,
            &.past_due,
            &.incomplete,
            &.paused {
              background: rgba(239, 113, 113, 0.3);
              color: #eb5757;
            }
          }
        }

        .subscription-dates {
          font-family: "Heebo";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #8c8c8c;

          span {
            margin-left: 8px;
          }
        }
      }

      .plan-actions-wrapper {
        display: flex;
        flex: 40%;
        justify-content: flex-end;

        .blue-button {
          background: #7b9eb9;
          border-radius: 9px;
          font-family: "Heebo";
          padding: 8px;
          margin-right: 8px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          letter-spacing: 0.04em;
          text-transform: capitalize;
          color: #ffffff;
          outline: none;
          border: none;
          width: 208px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:disabled {
            background-color: $grey-3;
            cursor: not-allowed;
          }
        }

        .subscription-wrapper-actions {
          position: relative;
          width: 40px;
          height: 40px;
          .three-dots-button {
            width: 40px;
            height: 40px;
            background: #eff3f6;
            border-radius: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(90deg);
            cursor: pointer;

            .dot {
              width: 4px;
              height: 4px;
              background: #7b9eb9;
              margin: 2px;
            }
          }

          .stripe-buttons-wrapper {
            z-index: 1;
            display: flex;
            flex-direction: column;
            visibility: hidden;
            opacity: 0;
            transition-duration: 1s;
            transition-property: opacity, visibility;
            transition-delay: 0s;
            width: 255px;
            position: absolute;
            top: 50px;
            right: 0px;
            background: $white-color;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            align-items: center;
            letter-spacing: 0.04em;
            text-transform: capitalize;
            background: #ffffff;
            box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
            border-radius: 20px;

            .move-to-stripe {
              padding: 24px;
              font-family: Heebo;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
              display: flex;
              align-items: center;
              letter-spacing: 0.04em;
              text-transform: capitalize;
              color: #7b9eb9;
              cursor: pointer;
              border-bottom: 1px solid #e8e8e8;

              &:last-child {
                border: none;
              }
              &.cancel {
                color: #ef7171;
              }
            }
          }

          &:hover {
            .stripe-buttons-wrapper {
              visibility: visible;
              opacity: 100;
            }
          }
        }
      }
    }

    .header-border {
      background: #e8e8e8;
      height: 1px;
      display: block;
      margin: 16px -24px 24px;
    }

    .subscription-content-wrapper {
      .used-time-progress-bar {
        margin: 50px 0 40px;

        .total {
          height: 20px;
          background-color: $grey-5;
          border-radius: 8px;
          padding: 4px;
          box-sizing: border-box;
          position: relative;

          .tip {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #181611;
            margin-top: 12px;
          }

          .used {
            height: 12px;
            background-color: $blue-1;
            position: relative;
            border-radius: 4px 0 0 4px;

            .tooltip {
              display: inline-block;
              padding: 2px 10px;
              position: absolute;
              top: -43px;
              left: 100%;
              transform: translateX(-50%);
              color: #ffffff;
              background-color: $blue-1;
              border-radius: 6px;
              font-size: 13px;
              line-height: 20px;

              &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) scaleX(0.6);
                border: 9px solid $blue-1;
                border-color: $blue-1 transparent transparent transparent;
              }
            }

            &.reached {
              border-radius: 4px;
            }
          }
        }

        &.warning {
          .used {
            background-color: $error;

            .tooltip {
              background-color: $error;

              &:after {
                border-top-color: $error;
              }
            }
          }
        }
      }

      .content-description {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .current-subscription-information {
        margin-top: 24px;
        padding: 16px 24px;
        background: #f3f3f3;
        border-radius: 10px;
        display: flex;

        .mau-information-wrapper {
          display: flex;
          flex: 50%;
          flex-direction: column;
          border-right: 2px solid #e8e8e8;

          &.warning {
            .block-title,
            .block-value {
              color: $error;
            }
          }
        }

        .reset-date-wrapper {
          display: flex;
          flex: 50%;
          flex-direction: column;
          margin-left: 35px;
          &.canceled {
            .block-title {
              color: #fc0303;
            }
            .block-value {
              color: #fc0303;
            }
          }
        }

        .block-title {
          font-family: Heebo;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #000000;
        }

        .block-value {
          font-family: Heebo;
          font-style: normal;
          font-size: 16px;
          line-height: 150%;
          /* or 24px */
          display: flex;
          align-items: center;
          color: #000000;
          font-weight: bold;
        }
      }

      .warning-message {
        font-size: 16px;
        line-height: 24px;
        color: $grey-3;
        letter-spacing: 0.03em;
      }
    }
  }
  .early-adopters {
    width: 728px;
    margin-top: 24px;
    padding: 20px;
    box-sizing: border-box;
  }

  .mau-information-tooltip-wrapper {
    width: 680px;
    background: #eff3f6;
    border-radius: 10px;
    margin-top: 16px;
    padding: 24px;
    .information-title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      span {
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #181611;
        margin-left: 10px;
      }
    }
    .information-description-wrapper {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #181611;
    }
  }

  .no-subscription {
    min-height: calc(100vh - 704px);
    margin-bottom: 40px;
    @include flex;
    &-inner {
      width: 680px;
      height: 97px;
      border-radius: $border-radius;
      background-color: $white;
      @include flex(space-between);
      font-family: $font-lora;
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
      color: #000000;
      padding: 24px;
      box-sizing: border-box;
    }
  }
}
