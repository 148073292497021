@import "../../../../../../styles/variables.module";
@import "../../../../../../styles/mixins";
.streaming-statistics {
  font-family: $font-heebo;
  margin-top: 30px;

  h3.sub-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black-color;
    text-align: left;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    color: $nav-bg;
  }

  .limit-reached-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 -44px;
    color: $error;
  }

  .used-time-progress-bar {
    margin: 50px 0 15px;

    .total {
      height: 20px;
      background-color: $grey-5;
      border-radius: 8px;
      padding: 4px;
      box-sizing: border-box;

      .used {
        height: 12px;
        background-color: $blue-1;
        position: relative;
        border-radius: 4px 0 0 4px;

        .tooltip {
          display: inline-block;
          padding: 2px 10px;
          position: absolute;
          top: -43px;
          left: 100%;
          transform: translateX(-50%);
          color: #ffffff;
          background-color: $blue-1;
          border-radius: 6px;
          font-size: 13px;
          line-height: 20px;

          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) scaleX(0.6);
            border: 9px solid $blue-1;
            border-color: $blue-1 transparent transparent transparent;
          }
        }

        &.reached {
          border-radius: 4px;
        }
      }
    }

    &.warning {
      .used {
        background-color: $error;

        .tooltip {
          background-color: $error;

          &:after {
            border-top-color: $error;
          }
        }
      }
    }
  }

  .remaining-values {
    @include display-flex-center();
    height: 64px;
    background-color: $grey-6;
    border-radius: 16px;
    margin: 16px 0;

    div {
      @include display-flex-center();
      font-size: 16px;
      line-height: 26px;
      color: #000000;
      width: 100%;
      height: calc(100% - 16px);

      &:first-of-type {
        border-right: 2px solid $grey-5;
      }

      &.warning {
        color: $error;
      }

      span {
        font-weight: 500;
      }
    }
  }

  .controls {
    @include display-flex-center();
    margin: 16px 0 40px;

    .switch-provider {
      margin-right: 16px;
      flex-shrink: 0;
    }

    .buy-hours {
      letter-spacing: 0.04em;
    }
  }
}
