@import "../../../../styles/index.scss";

@mixin forPresenter {
  @at-root .presenter-view #{&} {
    @content;
  }
}

.countdown {
  height: 100%;
  min-height: 680px;
  display: flex;
  font-family: $font-heebo;
  font-size: 18px;
  line-height: 160%;

  h1,
  h2,
  h3 {
    font-family: $font-lora;
  }

  .block {
    background: $white-color;
    box-shadow: $card-shadow;
    border-radius: $border-radius;
    padding: 0 24px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 266px);
    width: 73%;

    &.fullWidth {
      max-width: 100%;
      width: 100%;
    }

    &-main {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      overflow: hidden;

      &-customName {
        max-width: 296px;
      }

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 86px);
        padding: 40px 0;
        box-sizing: border-box;

        &.producer-view {
          height: calc(100% - 429px);
        }

        @include forPresenter {
          height: 25%;
          padding-top: 20px;
        }
      }

      &-anonymous {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &-timer {
        color: $black-color;

        &-description {
          font-size: 16px;
          line-height: 28px;
          max-width: 368px;
          text-align: center;
          margin: 16px 0;
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-right: 1px solid $soft-peach-color;

          &:last-child {
            border-right: none;
          }
        }

        &-title {
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
          text-align: center;
          margin: 0;
        }
      }

      &-action {
        display: flex;
        flex-direction: column;

        p {
          text-align: center;
          margin-bottom: 30px;
        }

        button {
          z-index: 2;
        }
      }

      &-description {
        max-width: 544px;
        margin-bottom: 58px;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: $dark-blue;
        text-align: center;
      }

      &-member {
        height: 86px;
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 0;
        border-top: 1px solid $soft-peach-color;

        button {
          margin-left: 16px;
        }

        .welcome-text {
          font-family: $font-lora;
          color: $blue-color;
          font-size: 15px;
          line-height: 120%;
          letter-spacing: 0.02em;
        }
      }

      .bg-element {
        position: absolute;
      }

      .el1 {
        top: 0;
        left: 153px;
        width: 227px;
        height: 80px;
        background-image: url("/assets/icons/bg_el_1.svg");
      }

      .el2 {
        top: 28px;
        right: 0;
        width: 183px;
        height: 85px;
        background-image: url("/assets/icons/bg_el_2.svg");
      }

      .el3 {
        bottom: 206px;
        left: 64px;
        width: 62px;
        height: 62px;
        background-image: url("/assets/icons/bg_el_3.svg");

        @include forPresenter {
          bottom: 56px;
          left: 24px;
        }
      }

      .el4 {
        bottom: 172px;
        right: 24px;
        width: 120px;
        height: 150px;
        background-image: url("/assets/icons/bg_el_4.svg");

        @include forPresenter {
          bottom: -46px;
          right: -40px;
        }
      }

      .event-ended {
        height: calc(100% - 86px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include forPresenter {
          height: 100%;
        }

        h1 {
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 24px;
        }

        .download-record-button {
          margin-top: 20px;
        }

        .back-to-events-button {
          margin-top: 60px;
        }
      }
    }

    &-help {
      flex-shrink: 0;
      height: 112px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include forPresenter {
        height: 80px;
      }

      .help-link {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          background: url("/assets/icons/lifebuoy.svg") no-repeat 100%;
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }

      &-mobile {
        width: 65%;
        display: flex;

        div {
          border-right: 1px solid $soft-peach-color;
          width: 100%;
          height: 80px;
          padding-left: 80px;
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: url("/assets/icons/mobile_app.svg") center left no-repeat;

          p {
            margin: 9px 0 -6px 5px;
            line-height: 100%;
          }
        }
      }

      &-guides {
        width: 35%;
        display: flex;
        justify-content: center;

        @include forPresenter {
          width: auto;
        }
      }

      &-link {
        font-size: 16px;
        line-height: 23px;
        display: inline-block;
        letter-spacing: 0.1em;
        color: $black-color !important;
        width: fit-content;
        padding: 8px 6px;
      }
    }
  }

  &-event {
    display: flex;
    flex-direction: column;
    width: 27%;
    min-width: 250px;
    margin-left: 16px;

    &.block {
      padding: 0 16px;
    }

    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 0.02em;
      margin-top: 0;
    }

    p,
    td {
      margin: 0;

      &.subject {
        margin-bottom: 10px;
      }
    }

    &-details {
      min-height: 144px;
      padding: 24px 0;
      flex-shrink: 0;
      border-bottom: 1px solid $soft-peach-color;
    }

    &-slides {
      display: flex;
      padding-top: 24px;
      flex-direction: column;
      border-bottom: 1px solid $soft-peach-color;
      padding-bottom: 5px;
      margin-bottom: 25px;
      overflow-y: scroll;

      h3 {
        margin-bottom: 10px;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-top: 1px dashed $soft-peach-color;

          &:first-child {
            border: none;
          }

          td {
            padding: 10px 0;

            &:last-child {
              width: 80px;
              color: $grey-color;
              vertical-align: top;
            }
          }
        }
      }
    }

    &.hideEventBlock {
      display: none;
    }
  }

  .empty-text {
    font-weight: bold;
    text-align: center;
    max-width: 300px;

    &-title {
      font-family: $font-heebo;
      font-size: 50px;
      line-height: 73px;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: $blue-color;
      margin: 0 0 14px;
    }

    &-body {
      font-family: $font-lora;
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: $purple-color;
      margin: 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .countdown {
    &-content {
      max-width: 100%;
      width: 100%;

      &-main {
        &-container {
          .anonymous-name {
            display: none;
          }
        }

        &-member {
          display: none;
        }
      }

      &-help {
        &-mobile {
          display: none;
        }

        &-guides {
          width: 100%;
        }
      }
    }

    &-event {
      display: none;
    }
  }
}
