@import "../../../../../../styles/variables.module";
.blocked-step {
  width: 384px;
  margin: 0 auto;
  .image {
    height: 230px;
    img {
      width: 100%;
    }
  }
  .section-divider {
    margin: 16px 0;
  }
  h2 {
    font-size: 18px;
    line-height: 20px;
  }
  p {
    margin-bottom: 32px;
  }
  button {
    margin: 24px 0;
  }
}
