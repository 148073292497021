@import "../../../styles/index.scss";

.darkTextButton {
  background: none;
  &-text {
    vertical-align: middle;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $dark-blue;
  }
}
