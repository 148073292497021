@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";

.templates {
  @include flex(center, flex-start, column);
  max-width: 624px;
  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: $grey-1;
    margin-bottom: 16px;
  }
  &-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $black-color;
    margin-bottom: 16px;
  }
  &-learnMore {
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      mask: url("/assets/icons/link2.svg") no-repeat;
      mask-size: contain;
      background-color: $blue-1;
      left: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      transform: rotate(77deg) skew(-14deg);
    }
  }
  .switch-control-thumb {
    background-color: $blue-1;
  }
  &-type-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $black-color;
    margin-top: 24px;
    height: 40px;
  }
  &-add {
    @include flex();
    width: 100%;
    height: 72px;
    background-color: $beige-4;
    border: 1px solid $pink-color;
    border-radius: 10px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $purple-2;
    cursor: pointer;
  }
  &-content {
    @include flex(flex-start, center, column);
    margin-top: 16px;
    width: 100%;
  }
  &-creationDialog {
    .styledDialog-container {
      .styledDialog-text {
        background-color: #f8f9fa;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        padding: 24px;
        &-title {
          color: $blue-color;
          margin: 0;
          height: 24px;
          @include flex(flex-start);
        }
        &-divider {
          display: none;
        }
      }
      .defaultButtons {
        background-color: #f8f9fa;
        padding-top: 24px;
        &-cancel {
          border: 2px solid $blue-1;
          border-radius: 9px;
          width: 112px;
        }
      }
    }

    .pollCreation {
      &-content {
        width: 432px;
        margin: 10px 0;

        &-titleField,
        &-textField {
          .custom-text-field-counter {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $grey-4;
          }
        }
        &-checkControl {
          @include flex(flex-start, flex-start, column);
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $grey-1;
          margin: 24px 0 0 4px;
        }
        .optionsList-addOption {
          color: $blue-1;
        }
      }
    }
  }
}
