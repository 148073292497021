@import "../../../../../../styles/index.scss";
.event-general-step {
  &-header {
    .copy-link {
      margin-top: 20px;
      p {
        font-weight: bold;
      }
    }
  }
  &-content {
    .top-block {
      display: flex;
      justify-content: center;
      &-main {
        width: 384px;
        flex-shrink: 0;
        border-right: 1px solid $wisp-pink-color;
        padding-right: 16px;
        .subject {
          margin-bottom: 0;
        }
      }
      &-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 16px;
        font-size: 12px;
        line-height: 18px;
        .upgrade-subscription {
          margin-top: 18px;
        }
        h3 {
          margin: 0;
        }
        .help {
          font-size: 13px;
          line-height: 20px;
          margin: 5px 0 13px;
        }
        .image {
          width: 208px;
          height: 128px;
          background-color: $background-color;
          border-radius: $border-radius;
          overflow: hidden;
          .imageUpload-uploaded-options {
            top: 8px;
            right: 8px;
          }
          .editIcons {
            &-edit,
            &-add {
              display: none;
            }
            &-delete {
              background-color: #828282;
              border-radius: 50%;
              opacity: 0.5;
            }
          }
        }
      }
    }
    .field {
      margin-bottom: 16px;
    }
    .label {
      font-size: 13px;
      line-height: 20px;
      color: $grey-4;
      margin: 0;
      align-self: flex-start;
    }
    .event-section {
      padding-top: 24px;
      border-top: 1px solid $wisp-pink-color;
      margin-top: 24px;
      &-label {
        margin: 0 0 20px;
      }
      .MuiFormControlLabel-root {
        margin-left: -9px;
        .MuiFormControlLabel-label {
          user-select: none;
        }
      }
      .styledCheckBox-icon {
        background-color: $blue-4;
      }
      .custom-switch {
        margin: 16px 0 0 9px;
      }
    }
    .event-dates {
      .pickers {
        display: flex;
        .datePicker-wrapper {
          width: 144px;
          flex-shrink: 0;
        }
        .timePicker-wrapper {
          margin-left: 16px;
          width: 112px;
          flex-shrink: 0;
        }
        .helper-text {
          font-size: 13px;
          line-height: 20px;
          @include display-flex-center;
          align-self: flex-end;
          color: $error;
          margin: 0 0 10px 16px;
        }
      }
    }
    .event-repeat {
      margin-top: 36px;
      @include display-flex-center;
      justify-content: flex-start;
      &-options {
        @include display-flex-center;
        position: relative;
        .label {
          font-size: 13px;
          line-height: 20px;
          position: absolute;
          top: -24px;
          left: 16px;
        }
        .dropDown {
          &-optionsList {
            border-radius: $border-radius;
            max-height: 280px;
          }
          &-option {
            padding: 9px 16px;
            &:hover {
              background-color: $blue-4;
              color: initial;
            }
          }
          &-selectedOpt {
            color: $blue-1;
          }
        }
      }
      .custom-text {
        @include display-flex-center(center, flex-start);
        flex-direction: column;
        align-self: center;
        margin-left: 16px;
        font-size: 14px;
        line-height: 20px;
        color: $grey-1;
        &-repeat,
        &-ends {
          margin: 0;
        }
      }
    }
  }
  .class-room-configuration-wrapper {
    margin-top: 32px;
    .class-room-member-count-wrapper {
      border-top: 1px solid $wisp-pink-color;
      border-bottom: 1px solid $wisp-pink-color;
      padding: 24px 0;
      .class-room-section-label {
        margin-bottom: 16px;
      }
    }
    .class-room-meeting-settings-wrapper {
      display: flex;
      flex-direction: column;
      .class-room-section-label {
        margin-top: 24px;
      }
      .settings-item {
        margin-top: 16px;
      }
    }
  }
  .landingPage-settings {
    margin-top: 30px;
    padding-top: 24px;
    border-top: 1px solid $wisp-pink-color;
    &-checkBox {
      margin-top: 16px;
    }
  }
}

.previewPlatformTour {
  margin-bottom: 24px;
}
