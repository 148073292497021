@import "../../../../../../styles/index.scss";

.pollDetails {
  &-title {
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: $purple-4;
    margin-bottom: 18px;
  }

  &-createInfo {
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    color: $purple-2;
    margin-bottom: 18px;
  }

  &-question {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    margin-bottom: 18px;
    span {
      color: $purple-color;
    }
  }
}
