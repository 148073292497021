@import "../../../../../styles/index.scss";
.notification-setting-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.2s;
  position: relative;
  margin: 34px 0;
  &.active {
    background: #3f373c;
  }
}

.notification-setting-tab {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: default;
  &-body {
    width: 262px;
    height: 152px;
    background-color: $popup-bg;
    box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 172px;
    display: flex;
    flex-direction: column;
    &-title {
      color: $purple-4;
      font-size: 16px;
      font-weight: bold;
      padding: 20px 24px;
    }
    &-content {
      padding: 0 16px 16px 24px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      color: $purple-3;
      font-size: 13px;
      font-weight: bold;
      justify-content: space-between;
      align-content: space-around;
      flex-grow: 2;
    }
  }
}

@media screen and (max-width: 1160px) {
  .notification-setting-tab-body {
    margin-right: 14px;
  }
}