@import "../../../../../styles/index.scss";
.next-eventCard {
  font-family: $font-heebo;
  position: fixed;
  bottom: 112px;
  left: 40px;
  max-width: 704px;
  height: 232px;
  padding: 16px 24px 16px 16px;
  box-sizing: border-box;
  z-index: 200;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: $popup-bg;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  transform: translate(-200%, 0);
  transition: transform 0.3s;
  &-image {
    width: 352px;
    height: 100%;
    margin-right: 24px;
    border-radius: $border-radius;
    overflow: hidden;
    background: rgba(25, 19, 23, 0.4);
    @include display-flex-center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: $white-color;
    width: 288px;
    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
    &-name {
      font-size: 20px;
      line-height: 24px;
      color: $blue-4;
      max-width: 288px;
      @include truncate-line;
    }
    &-date {
      font-size: 13px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    &-countDown {
      width: 100%;
    }
    &-nextBtn {
      width: 100%;
    }
  }
  &-countDown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .countDown-card {
      width: 88px;
      height: 56px;
      background: rgba(25, 19, 23, 0.4);
      border-radius: 10px;
      overflow: hidden;
      @include display-flex-center;
      flex-direction: column;
      font-weight: bold;
      &-description {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $purple-2;
        text-transform: capitalize;
      }
    }
  }
  &-overlay {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 10;
    background-color: transparent;
    pointer-events: none;
    &.show {
      background-color: rgba($color: #191317, $alpha: .5);
      pointer-events: auto;
      .next-eventCard {
        transform: translate(0, 0);
      }
    }
  }
  & &-close {
    @include flex;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    background-image: url(/assets/icons/cross_icon_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    padding: 18px;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
}

@media screen and (max-width: 1220px) {
  .next-eventCard {
    transform: scale(.9) translate(-200%, 10%);
    &-overlay {
      &.show {
        .next-eventCard {
          transform: scale(.9) translate(-5%, 10%);
          left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .next-eventCard {
    transform: scale(.7) translate(-200%, 20%);
    &-overlay {
      &.show {
        .next-eventCard {
          transform: scale(.7) translate(-20%, 20%);
        }
      }
    }
  }
}

@media screen and (max-width: 876px) {
  .next-eventCard {
    transform: scale(.6) translate(-200%, 35%);
    &-overlay {
      &.show {
        .next-eventCard {
          transform: scale(.6) translate(-35%, 35%);
        }
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .next-eventCard {
    transform: scale(.5) translate(-300%, 50%);
    &-overlay {
      &.show {
        .next-eventCard {
          transform: scale(.5) translate(-50%, 50%);
        }
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .next-eventCard {
    transform: scale(.4) translate(-300%, 75%);
    &-overlay {
      &.show {
        .next-eventCard {
          transform: scale(.4) translate(-75%, 75%);
        }
      }
    }
  }
}
