@import "../../../../styles/mixins";
.sermons {
  font-family: $font-heebo;
  .dashboard-body-overflow & .header {
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.1);
  }
  &-add-event-button {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em !important;
    text-transform: capitalize;
    color: $white-color;
  }
  flex-direction: column;
  margin-top: 90px;
  padding: 0 16px 96px;
  background: #f8f9fa;
  &.subscriptionAttention {
    margin-top: 120px;
  }
  &-container {
    flex-direction: column;
    flex: 1;
    width: calc(100% + 16px) !important;
  }
  .scheduler-container {
    position: relative;
    .emptyText-message {
      color: $grey-3;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
    }
    .scrollBack {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 50%;
      z-index: 1;
      img {
        margin-right: 12px;
      }
      &.positionTop {
        top: 104px;
        img {
          transform: rotate(180deg);
        }
      }
      &.positionBottom {
        bottom: 24px;
      }
    }
  }
  .no-communities {
    .emptyText-message {
      color: #9f9f9f;
      p {
        margin: 0;
        text-align: center;
      }
    }
  }
}
