@import "../../../../../styles/variables.module.scss";
@import "../../../../../styles/mixins";
.general-settings-wrapper {
  padding: 0 40px 40px;
  .community-appearance-wrapper {
    border-bottom: 1px solid #dad2d0;
    padding-bottom: 50px;
    margin-bottom: 60px;
  }
  .left-side,
  .right-side {
    flex: 50%;
  }
  .left-side {
    margin-right: 48px;
  }
  .communityDialogEdit-item {
    margin-top: 16px;
    .timeZonePicker {
      display: inline-block;
      width: 100%;
    }
    .timeZonePicker-label {
      z-index: 1;
      font-size: 13px;
      line-height: 20px;
      color: $grey-4 !important;
      font-family: $font-heebo;
      top: 0;
      transform: translate(0, 0) scale(1) !important;
      margin-top: 8px;
    }
  }
  .communityDialogEdit-visibility {
    margin-top: 24px;
    .switch-control-thumb {
      background: $blue-1;
    }
    .communityDialogEdit-title {
      font-size: 13px;
      line-height: 20px;
      color: $grey-4;
      margin: 0 0 6px 16px;
    }
  }
  .communityDialogEdit-verified {
    margin: 40px 0 0 3px;
  }
  .communityDialogEdit-invitation {
    .communityDialogEdit-invitation-description {
      margin: 0 0 6px 16px;
    }
  }
  .image-selector-wrapper {
    min-width: 344px;
    min-height: 232px;
    .imageUpload {
      position: relative;
      overflow: visible;
      // min-height: 232px;
      .imageUpload-select {
        min-height: 232px;
        background-image: url(/assets/icons/default-image-upload.svg) !important;
        .imageUpload-select-icon {
          display: none;
        }
      }
      .imageUpload-after-wrapper {
        display: block;
      }
    }
    .imageUpload-uploaded {
      width: 300px;
      margin: 0 auto;
      .imageUpload-uploaded-options {
        display: none;
      }
    }
    .image-information-wrapper {
      margin-bottom: 16px;
      position: absolute;
      bottom: -70px;
      .image-information-title {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: $blue-1;
        margin-bottom: 8px;
      }
      .image-information-text {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
    }
  }
  .communitySelect {
    .searchSelect-title {
      font-size: 13px;
      line-height: 20px;
      color: $grey-4 !important;
      margin-left: 16px;
      transform: translate(0, 0) scale(1) !important;
      font-family: $font-heebo;
      margin-top: 8px;
    }
  }
  .settings-community-website {
    position: relative;
    button {
      background: url("/assets/icons/copy_icon.svg") no-repeat 100%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border: none;
      outline: none;
      top: 60px;
      cursor: pointer;
    }
    input {
      max-width: 90%;
    }
  }

  .google-analytics-block {
    width: 856px;
    font-size: 14px;
    line-height: 160%;
    color: $grey-1;

    p {
      margin: 0 0 24px;
    }

    input {
      width: 372px;
      box-sizing: border-box;
    }
  }
}

.delete-community-button {
  background-color: #ef7171 !important;
}
