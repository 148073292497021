@import "../../../../../../../styles";

$card-border-radius: 10px;
$card-title-color: #e9e6e8;
$card-created-color: #93808d;
$card-description-color: $white-color;
$card-divider-color: #7e6677;
$card-menu-color: #382e35;

@mixin build-card-row($color, $font-size, $margin-bottom: 0) {
  color: $color;
  font-size: $font-size;
  margin-bottom: $margin-bottom;
}

.announcement-card {
  background-color: $event-input-background;
  border-radius: $card-border-radius;
  padding: 24px 16px;
  cursor: pointer;

  .announcement-title {
    @include build-card-row($card-title-color, $font-size-normal, 8px);
    font-weight: bold;
  }

  .announcement-description {
    @include build-card-row($card-description-color, $font-size-normal);
    word-wrap: break-word;
  }

  .announcement-created {
    @include build-card-row($card-created-color, $font-size-small, 16px);
    font-weight: bold;
  }

  .announcement-main-button {
    margin-top: 24px;
  }

  .announcement-divider {
    width: 100%;
    height: 2px;
    background-color: $card-divider-color;
    margin-top: 16px;
    opacity: 0.4;
  }

  .announcement-footer {
    @include flex(space-between);
    margin-top: 24px;
    .announcement-footer-status {
      border-radius: 9px;
      padding: 2px 8px;
      color: $white-color;
      background: rgba(123, 158, 185, 0.6);
      &.draft {
        background: rgba(126, 102, 119, 0.7);
      }
      &.scheduled {
        background-color: #9e725d;
      }
    }
    .announcement-controls {
      @include flex(flex-end);
      .three-dots-popup {
        min-width: 24px;
        .three-dots-popup-wrapper {
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0;
          border-radius: 20px;
          background-color: $card-menu-color;
          box-shadow: 0px 4px 8px rgb(0, 0, 0 / 5%);
          .announcement-dots-popup-item {
            border-radius: 20px 20px 0 0;
            box-sizing: border-box;
            color: $white-color;
            padding: 16px 16px 8px 16px;
            width: 100%;
            &.unschedule {
              border-radius: 0;
            }
            &.delete {
              border-radius: 0 0 20px 20px;
              color: #ef7171;
            }
            &:hover {
              background: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
      .announcement-button-delete {
        cursor: pointer;
        width: 24px;
        height: 24px;
        background-image: url("/assets/icons/ic_remove.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
