@import "../../../../../styles/variables.module";

.communityListItem {
  padding-left: 20px;
  height: 64px;
  padding-top: 8px;

  &-title {
    color: $white-color;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-family: $font-heebo;
  }

  &-text {
    font-size: 12px;
    color: $grey-color;
    font-family: $font-heebo;
  }

  &-personIcon {
    opacity: 0.5;
    margin-right: 6px;
  }
}

@media screen and (max-width: 768px) {
  .communityListItem {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
