@import "../../../../../styles/variables.module";

.member-dialog-view {
  &-admin {
    margin: 24px;
    padding-bottom: 6px;
    border-bottom: 1px solid $dividers;
  }

  &-status {
    margin: 24px;
  }

  .sub-title {
    font-size: 14px;
  }

  .grey-text {
    font-size: 14px;
    color: $grey-color;
  }

  .full-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
