@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";
@mixin item-select-opened {
  min-width: 54px;
  margin-left: 2px;
  .styledCheckBox {
    display: block;
  }
}

@mixin item-select-disableOpen {
  min-width: 6px !important;
  margin-left: 0 !important;
  .styledCheckBox {
    display: none !important;
  }
}

.members-table {
  font-family: $font-heebo;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 10px;
  display: table;
  overflow-x: scroll;
  .item-select {
    min-width: 6px;
    width: 6px;
    height: 54px;
    display: grid;
    @include flex;
    justify-items: center;
    align-items: center;
    background: $blue-3;
    transition: min-width 0.2s;
    overflow: hidden;
    &.opened {
      @include item-select-opened;
    }
    .styledCheckBox {
      display: none;
    }
    &.disableOpen {
      @include item-select-disableOpen;
    }
  }
  &__head {
    width: 100%;
    &_label {
      padding-left: 6px;
      color: inherit;
      display: table-row;
      outline: 0;
      vertical-align: middle;
      height: 48px;
      th:first-child {
        &::before {
          border-top-left-radius: 10px;
        }
      }
      th:last-child {
        &::before {
          border-top-right-radius: 10px;
        }
      }
      th {
        color: $blue-color;
        display: table-cell;
        font-size: 0.875rem;
        text-align: left;
        font-weight: 400;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        vertical-align: inherit;
        min-width: 100px;
        background-color: $white-2;
        position: sticky;
        top: 0px;
        z-index: 10;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: $blue-3;
          left: 0;
          top: 0;
          z-index: -1;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          span {
            margin-right: 9px;
          }
          img {
            cursor: pointer;
          }
        }
      }
      &_user {
        display: flex;
        >div {
          width: 54px;
          height: 54px;
          background: $blue-3;
          display: grid;
          justify-items: center;
          align-items: center;
        }
        span {
          margin-left: 12px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  &__body {
    display: table-row-group;
    background-color: $white;
    &_info {
      color: inherit;
      display: table-row;
      outline: 0;
      vertical-align: middle;
      min-height: 56px;
      height: 56px;
      cursor: pointer;
      &:nth-child(even) {
        background: rgba(239, 243, 246, 0.4);
      }
      &_created {
        padding-right: 110px;
      }
      td {
        display: table-cell;
        padding: 0;
        font-size: 0.875rem;
        text-align: left;
        font-weight: 400;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        .member__info {
          display: flex;
          align-items: center;
          width: 100%;
          overflow: hidden;
          &_name {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.04em;
            text-transform: capitalize;
            color: $grey-1;
            margin-right: 8px;
            @include truncate-lines(1);
            width: 100%;
            &-current-user {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 20px;
              color: $grey-4;
            }
          }
          &_role {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: $grey-4;
          }
        }
        .status {
          width: 88px;
          height: 24px;
          display: grid;
          justify-items: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: $grey-3;
          background: $grey-6;
          border-radius: 8px;
        }
        .status.pending {
          color: $beige-1;
          background: $beige-4;
        }
        .status.rejected {
          color: #e1a077;
          background: #fcf0e9;
        }
        .status.notInvited {
          color: $grey-3;
          background: $grey-6;
        }
        .status.accepted,
        .status.active {
          color: $blue-color;
          background: #eff3f6;
        }
        &:nth-child(1) {
          width: 40%;
          max-width: 40%;
        }
      }
      &:hover {
        .item-select {
          @include item-select-opened;
        }
      }
    }
  }
}
