@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";
.stream-participants {
  width: $stream-stream-participants-width;
  height: 100%;
  @include display-flex-center(flex-start);
  flex-direction: column;
  flex-shrink: 0;
  background-color: $nav-bg;
  padding: 10px 24px;
  box-sizing: border-box;
  &-header {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    &-table {
      color: $purple-2;
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;
      font-weight: bold;
      .table-name {
        color: $purple-4;
        font-size: 22px;
        line-height: 26px;
        margin-top: 5px;
        &.can-edit {
          span {
            cursor: pointer;
            padding-right: 30px;
            &:hover {
              color: $purple-4;
              background: url("/assets/icons/edit_icon_2.svg") center right no-repeat;
              background-size: 22px;
            }
          }
        }
      }
    }
    .expand {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $nav-bg;
      position: absolute;
      top: -6px;
      right: -48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
    }
  }
  &-streams {
    overflow-y: auto;
    padding-right: 5px;
    margin: auto auto 20px;
    width: 100%;
  }
  .video-list-wrapper {
    width: 100%;
    @include display-flex-center;
    .video-wrapper {
      display: inline-block;
      width: 100%;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .videobox {
        width: 100%;
        height: 168px;
        margin: 0;
      }
      .member-name {
        font-size: 14px;
        bottom: 5px;
        left: 10px;
        line-height: 14px;
      }
      .microphone-indicator {
        top: 6px;
        left: 10px;
        height: 20px;
        width: 20px;
      }
      .video-container {
        margin: 0;
      }
    }
  }
  .video-menu-container {
    margin-top: auto;
  }
}

@media screen and (max-height: 960px) {
  .stream-participants {
    width: $stream-stream-participants-width-medium;
    .video-list-wrapper {
      .video-wrapper {
        .videobox {
          height: 143px;
        }
      }
    }
    .video-menu-container {
      width: 250px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .stream-participants {
    width: $stream-stream-participants-width-small;
    padding: 10px 14px;
    &-header {
      .expand {
        width: 40px;
        height: 40px;
        top: -6px;
        right: -32px;
      }
    }
    .video-list-wrapper {
      .video-wrapper {
        .videobox {
          height: 143px;
        }
      }
    }
    .video-menu-container {
      width: 250px;
      transform: scale(0.9);
    }
  }
}

@media screen and (max-height: 860px) {
  .stream-participants {
    .video-list-wrapper {
      .video-wrapper {
        width: 215px;
        margin-bottom: 10px;
        .videobox {
          height: 125px;
          width: 215px;
        }
      }
    }
  }
}
