@import "../../../styles/index";
.authAside {
  width: 45.5%;
  box-sizing: border-box;
  background-color: #EFF3F6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &-header {
    padding: 40px 48px 0;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }
  &-content {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 80%;
  }
  &-content-header {
    margin-top: 84px;
  }
}

@media screen and (max-width: 1000px) {
  .authAside {
    width: 100%;
    padding: 40px;
    min-height: 220px;
    background-color: transparent;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 740px) {
  .authAside {
    &-content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 512px) {
  .authAside {
    padding: 10px;
  }
}