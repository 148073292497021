@import "../../../styles/variables.module.scss";
.custom-switch {
  .switch-root {
    width: 48px;
    height: 23px;
    padding: 0;
    display: flex;
  }
  .switch-switchBase {
    padding: 2.2px;
    &.switch-checked {
      transform: translateX(24px);
      & + .switch-track {
        opacity: 1;
        background-color: $blue-1;
      }
    }
  }
  .switch-thumb {
    color: white;
    width: 19px;
    height: 19px;
    box-shadow: unset;
  }
  .switch-track {
    opacity: 1;
    background-color: $purple-1;
    border-radius: 16px;
  }
  &.orange {
    .switch-track {
      background-color: $purple-3;
    }
    .switch-switchBase {
      &.switch-checked {
        & + .switch-track {
          background-color: $orange-1;
        }
      }
    }
  }
  .switch-track {
    background-color: $purple-3;
  }
  .MuiFormControlLabel-label {
    text-transform: capitalize;
    margin-left: 16px;
    &:empty {
      margin-left: 0;
    }
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    user-select: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}
