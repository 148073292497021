@import "../../../../../styles/index.scss";
.eventSideDialogPollHeader-title {
  @include flex(flex-start, center);
  padding: 0;
  margin-top: 8px;
  &-img {
    width: 32px;
    height: 32px;
    background-color: $purple-1;
    border-radius: 50%;
    @include flex(center, center);
    padding: 10px;
    box-sizing: border-box;
    img {
      height: 13px;
      width: 13px;
    }
  }
  &-text {
    font-family: $font-heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: $blue-4;
    margin-left: 8px;
  }
}