@import "../../../../../styles/index.scss";
.communityMember {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  &-fullWidth {
    width: 100%;
  }
  &-img-container {
    margin-right: 8px;
    position: relative;
    .custom-avatar-fallback,
    .custom-avatar-image {
      border: 3px solid transparent;
      border-radius: 50%;
      transition: border-color 0.2s linear;
      box-sizing: border-box;
    }
  }
  &-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 34px);
    height: 100%;
    &-name {
      font-size: 20px;
      line-height: 23px;
      font-weight: 500;
      letter-spacing: 0.04em;
      margin: 0;
      color: $dark-blue;
      @include truncate-line;
      max-width: 210px;
    }
    &-email {
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.04em;
      margin: 0;
      color: $purple-2;
      @include truncate-line;
      max-width: 210px;
    }
  }
  &-positions {
    display: flex;
    flex-wrap: wrap;
    &-position {
      font-size: 12px;
      color: $grey-color;
      margin: 0 5px 0 0;
    }
  }
  &-selected {
    .communityMember-img-container {
      .custom-avatar-fallback,
      .custom-avatar-image {
        border-color: $orange-color;
      }
    }
  }
  &.stream {
    .communityMember {
      &-data {
        &-name {
          color: $white-color;
        }
      }
    }
  }
  &.communityMember-online {
    .communityMember-img-container {
      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 3px;
        right: 3px;
        background: #7FBA7A;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
      }
    }
  }
}
