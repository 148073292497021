@import "../../../../styles/variables.module.scss";

.channelListHeader {
  width: 100%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 24px;

  * {
    box-sizing: border-box;
  }

  &-heading {
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: bold;
  }

  &-actions {
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    align-items: center;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
    justify-content: flex-end;
  }

  &-addNew {
    display: inline-block;
  }

  &-search {
    display: flex;
    padding-right: 10px;
    background-color: $white-color;
    height: 100%;
    align-items: center;
    justify-content: flex-end;

    &.open {
      flex-grow: 1;
    }

    &_button {
      width: 40px;
    }

    .searchInput {
      width: calc(100% - 50px);
    }
  }

}
