@import "../../../../../styles/index.scss";

.recordings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &-title {
    font-family: $font-heebo;
    font-size: 16px;
    line-height: 28px;
    color: $blue-color;
    margin: 0 0 24px;
  }
  &-list {
    min-width: 516px;
    max-width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 16px;
    table {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
