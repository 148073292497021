.mau-slider-wrapper{
  margin-top: 40px;
  margin-bottom: 16px;
  .mau-title{
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: #181611;
    margin-bottom: 16px;
  }
  .mau-description{
    margin-bottom: 40px;
  }

  .total-price-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: -10px;

    .pricing {
      display: flex;
      width: 280px;
      justify-content: space-between;

      .total-price-title {
        font-family: Lora;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 38px;
        display: flex;
        align-items: center;
        color: #1D1D1D;
        margin-right: 24px;
      }

      .total-price-value {
        font-family: Lora;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 62px;
        text-align: right;
        color: #181611;
      }
    }

    .payment-details {
      margin-top: 18px;

      .payment-item {
        display: flex;
        justify-content: space-between;

        span {
          font-family: Heebo;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #8C8C8C;

          &:nth-child(1) {
            width: 140px;
          }
        }
      }
    }
  }
}