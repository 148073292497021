@import "../../../styles/index.scss";
.three-dots-popup {
  background: url("/assets/icons/dots.svg");
  width: 24px;
  height: 24px;
  margin-left: 14px;
  position: relative;
  &-wrapper {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transition-duration: 0.3s;
    transition-property: opacity, visibility;
    transition-delay: 0s;
    width: 136px;
    position: absolute;
    top: 30px;
    right: 0px;
    background: $white-color;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    z-index: 1;
  }
  &:hover {
    background: url("/assets/icons/dots-highlighted.svg");
    cursor: pointer;
    .three-dots-popup-wrapper {
      visibility: visible;
      opacity: 100;
    }
  }
}
