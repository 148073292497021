@import "../../../styles/index.scss";

.upload-dropzone {
  position: relative;
  cursor: pointer;

  div {
    height: 100%;
  }

  .error {
    font-family: $font-heebo;
    font-size: 13px;
    color: $red-color;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0;
    margin: 0;
    text-align: right;
    cursor: default;
  }
}
