@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";

.confirmDeletePollTemplateBtn {
  background: $error !important;
}

.pollTemplate {
  width: 100%;
  background-color: $blue-4;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 24px 0 0;
  box-sizing: border-box;
  &-title {
    padding: 0 24px;
    margin-bottom: 10px;
    &-label {
      @include flex(space-between);
      img {
        margin: 0 9px;
        cursor: pointer;
      }
      &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $grey-1;
    }
  }
  &-question {
    padding: 0 24px;
    margin-bottom: 10px;
    &-label {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $grey-3;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
  &-settings {
    padding: 0 24px;
    @include flex(flex-start, center);
    margin-bottom: 35px;
    &-showResults,
    &-multipleAnswers {
      @include flex(center, center);
      background-color: $orange-1;
      border-radius: 9px;
      height: 24px;
      margin-right: 6px;
      padding: 0 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $white-color;
    }
  }
  &-options {
    background-color: #e4ebf0;
    border-radius: 0 0 10px 10px;
    padding: 15px 24px;
    &-item {
      margin-top: 10px;
      &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
      &-text {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
      }
    }
  }
}
