.countdown-timer {
  font-weight: bold;
  font-size: 50px;
  line-height: 73px;
  letter-spacing: 0.08em;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 10px 0;
}
