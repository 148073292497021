@import "./variables.module.scss";
@mixin backgroundImage($image) {
  background-image: url($image);
}

@mixin truncate-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin truncate-lines($linesCount:1) {
  display: -webkit-box;
  -webkit-line-clamp: $linesCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin divider-short {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 108px;
  height: 5px;
  background-color: $dividers;
}

@mixin display-flex-center($justifyContent:center, $alignItems:center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin desktop($desktop-width) {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile($mobile-width) {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin flex($justify-content: center, $align-items: center, $flex-direction: row) {
  display: flex;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  align-items: $align-items;
}

@mixin item-appearance {
  border-radius: 20px;
  background-color: $event-input-background;
  color: $white-color;
}

@mixin item-input {
  @include item-appearance();
}
