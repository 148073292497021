@import "../../../styles/variables.module";

.linkHolder {
    width: 100%;
    position: relative;

    &-wrapper {
        margin-top: 16px;
        border-bottom: 1px solid $light-grey-color;
        max-width: 320px;
        min-height: 34px;
        position: relative;
        &::before {
            content: attr(data-text);
            position: absolute;
            padding: 8px 0;
            color: $grey-color;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            top: 0;
            left: 0;
            cursor: default;
            max-width: 305px;
        }
    }
    &-title {
        font-size: 12px;
        color: $grey-color;
        line-height: 130%;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: default;
    }
    &-placeholder {
        padding: 8px 0;
        color: $grey-color;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: default;
    }
    &-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        padding: 8px 0;
        outline: none;
        width: 100%;
    }
    .sm {
        max-width: 176px;
    }
    .md {
        max-width: 320px;
    }
    .lg {
        max-width: 376px;
    }
}
