@import "../../../styles/variables.module";
@mixin variant($variant) {
  @at-root .variant-#{$variant} #{&} {
    @content;
  }
}

.styledDialog {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  background-color: $semitransparent-color;
  display: flex;
  justify-content: center;
  align-items: center;
  &.variant-brown {
    background-color: $dark-popup-background-color;
  }
  &-body {
    width: 391px;
    background-color: $white-color;
    border-radius: 20px;
    overflow: hidden;
    @include variant("brown") {
      width: 512px;
      border-radius: 20px;
      background-color: $popup-bg;
    }
    &.no-transform-header {
      .styledDialog-text-title {
        text-transform: none;
      }
    }
  }
  &-container {
    height: 100%;
  }
  &-text {
    width: 100%;
    padding: 24px;
    &-title {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 24px;
      font-family: $font-heebo;
      font-weight: bold;
      text-transform: capitalize;
      color: $grey-1;
      @include variant("brown") {
        color: $white-color;
        font-family: $font-heebo;
        font-size: 22px;
        letter-spacing: 0.02em;
        line-height: 26px;
      }
    }
    &-divider {
      height: 5px;
      width: 96px;
      background-color: $wisp-pink-color;
      margin: 24px 0;
      @include variant("brown") {
        display: none;
      }
    }
    &-content {
      color: $grey-1;
      white-space: pre-line;
      font-family: $font-heebo;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 16px;
      opacity: 0.9;
      @include variant("brown") {
        color: $white-color;
        font-size: 16px;
        line-height: 28px;
        opacity: initial;
        letter-spacing: 0.02em;
      }
    }
    &.dialog-children {
      padding: 24px 24px 16px;
    }
  }
  .defaultButtons {
    width: 100%;
    box-shadow: none !important;
    background: initial;
    padding: 0 24px 24px;
    &-button {
      padding-top: 0 !important;
    }
  }
  &.variant-brown {
    .defaultButtons {
      background-color: rgba(126, 102, 119, 0.3);
      height: 88px;
      padding: 16px 24px;
      button {
        border-radius: 10px;
        text-transform: capitalize;
        letter-spacing: 0.04em;
      }
      &-confirm {
        min-width: 112px;
      }
      &-cancel {
        color: $purple-3;
      }
    }
  }
  &.hidden-overlay {
    background-color: unset;
  }
}
