@import "../../../../../../styles/variables.module.scss";
@import "../../../../../../styles/mixins.scss";
.createInteraction {
  .styledDialog-container {
    .styledDialog-text {
      background-color: #f8f9fa;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      padding: 24px;
      &-title {
        color: $blue-color;
        margin: 0;
        height: 24px;
        @include flex(flex-start);
      }
      &-divider {
        display: none;
      }
    }
    .defaultButtons {
      background-color: #f8f9fa;
      padding-top: 24px;
      &-cancel {
        border: 2px solid $blue-1;
        border-radius: 9px;
        width: 112px;
      }
    }
  }
  .pollCreation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &-content {
      width: 432px;
      margin: 10px 0;
      .text-field {
        background-color: $grey-6;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
      }
      .text-label {
        font-family: "Heebo";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
        margin-left: 16px;
      }
      &-titleField,
      &-textField {
        .custom-text-field-counter {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $grey-4;
        }
      }
      &-checkControl {
        @include flex(flex-start, flex-start, column);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
        margin: 24px 0 0 4px;
      }
      .optionsList-addOption {
        color: $blue-1;
      }
    }
  }
}
