@import "../../../../../styles/index.scss";
.videoMemberLabel {
  @include display-flex-center;
  box-sizing: border-box;
  position: absolute;
  padding-left: 10px;
  background: rgba($color: $common-panel-bg, $alpha: .3);
  border-radius: 40px;
  right: 8px;
  bottom: 8px;
  max-width: 120px;
  z-index: 1;
  &-member-name {
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: $white-color;
    height: 24px;
    @include truncate-line;
    margin-right: 32px;
  }
  &-microphone-indicator {
    position: absolute;
    bottom: 0;
    right: -1px;
    height: 24px;
    width: 24px;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}