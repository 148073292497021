@import "../../../styles/index";
.sideDialog {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 300;
  background-color: $semitransparent-color;
  font-family: $font-heebo;
  &-companyFullView {
    .sideDialog-body {
      width: 848px;
    }
  }
  &-company {
    z-index: 300;
  }
  &-body {
    background: $background-color;
    width: 424px;
    height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-body-sm {
    width: 344px;
  }
  &-body-md {
    width: 424px;
  }
  &-body-lg {
    width: 592px;
  }
  &-container {
    padding: 0 24px;
    margin-bottom: 24px;
  }
  &-header {
    border-bottom: 1px solid $soft-peach-color;
    padding: 24px 0;
    margin-bottom: 16px;
    &-actions {
      display: flex;
      justify-content: flex-start;
      button {
        background: transparent;
        font-family: $font-lora;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15em;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-align: right;
        border: none;
        outline: none;
        margin: 0;
        cursor: pointer;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &-edit {
        color: $blue-color;
      }
      &-remove {
        color: $red-color;
      }
    }
  }
  &-title {
    max-width: unset !important;
    flex: 1 !important;
    font-family: $font-lora;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: $black-color;
    text-transform: capitalize;
  }
  &-footer-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    background: $blue-color;
    button {
      min-height: 40px;
      background: transparent;
      color: $white-color;
      transition: 0.2s;
      cursor: pointer;
      outline: none;
      box-sizing: border-box;
      vertical-align: middle;
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15em;
      text-align: center;
      text-transform: uppercase;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &-confirm {
      // width: 184px;
      border: 1px solid $white-color;
      border-radius: $border-radius-rounded;
      padding: 2px 24px;
    }
    &-cancel {
      // width: 90px;
      border: none;
    }
  }
}