@import "../../../styles/mixins";
.mask-input {
  padding: 10px 20px;
  box-sizing: border-box;
  background: #EFF3F6;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  position: relative;
  input {
    letter-spacing: 10px;
    color: rgba($color: #000000, $alpha: 0.6);
    font-weight: normal;
    font-size: 34px !important;
    line-height: 38px;
    outline: none;
    border: none;
    background: transparent;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 1;
    &::placeholder {
      color: rgba($color: $grey-3, $alpha: 0.6);
      opacity: 1;
    }
  }
  &.empty {
    input {
      color: rgba($color: #8C8C8C, $alpha: 0.6);
    }
  }
  &.error {
    border-color: $error;
    background: rgba(239, 113, 113, 0.1);
  }
  &-error {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $error;
  }
}