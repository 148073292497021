@import "../../../styles/index";
.eventTooltip {
  .MuiTooltip-tooltip {
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    font-family: $font-heebo;
    font-size: 13px;
    line-height: 20px;
    &.MuiTooltip-tooltipArrow {
      .MuiTooltip-arrow {
        width: 0;
        height: 0;
        margin: unset;
        &::before {
          display: none;
        }
      }
    }
    &.MuiTooltip-tooltipPlacementLeft {
      .MuiTooltip-arrow {
        right: -9px;
        top: 50%!important;
        transform: translateY(-50%);
        border-top: 5px solid transparent;
        border-left: 10px solid rgba($color: $white-color, $alpha: 0.9);
        border-bottom: 5px solid transparent;
      }
    }
    &.MuiTooltip-tooltipPlacementTop,
    &.MuiTooltip-tooltipPlacementBottom {
      .MuiTooltip-arrow {
        left: 50% !important;
        bottom: -9px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid rgba($color: $white-color, $alpha: 0.9);
      }
    }
    &.MuiTooltip-tooltipPlacementBottom {
      .MuiTooltip-arrow {
        top: -9px;
        bottom: unset;
        border-top: unset;
        border-bottom: 10px solid rgba($color: $white-color, $alpha: 0.9);
      }
    }
  }
  &-light {
    .MuiTooltip-tooltip {
      color: $purple-1;
      background-color: rgba($color: $white-color, $alpha: 0.9);
      .MuiTooltip-arrow {
        color: rgba($color: $white-color, $alpha: 0.9);
      }
    }
  }
}