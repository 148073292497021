@import "../../../../../../styles/variables.module";
.stream-provider-card {
  width: 192px;
  height: 104px;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: $dark-grey;
  background: $white-color no-repeat center;
  border-radius: $border-radius;
  box-shadow: 0 3px 9px rgba(48, 48, 48, 0.12);
  span {
    text-align: center;
  }
  &.facebook {
    background-image: url("/assets/images/videoProviders/facebook_logo.png");
    background-size: 86px;
  }
  &.youtube {
    background-image: url("/assets/images/videoProviders/youtube_logo.png");
    background-size: 110px;
  }
  &.youtube_live {
    background-image: url("/assets/images/videoProviders/youtube_logo_live.png");
    background-size: 70px;
  }
  &.vimeo {
    background-image: url("/assets/images/videoProviders/vimeo_logo.png");
    background-size: 96px;
  }
  &.vimeo_live {
    background-image: url("/assets/images/videoProviders/vimeo_live.svg");
    background-size: 200px;
    position: relative;
    font-family: $font-heebo;
    .new-label {
      position: absolute;
      width: 24px;
      height: 16px;
      right: 8px;
      top: 8px;
      background: $error;
      border-radius: 2px;
      font-weight: bold;
      font-size: 8px;
      color: $white;
      line-height: 16px;
      text-align: center;
      vertical-align: middle;
    }
    .description {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $blue-2;
      margin: auto 0 16px;
    }
  }
  &.twitch {
    background-image: url("/assets/images/videoProviders/twitch_logo.png");
  }
  &.resi {
    background-image: url("/assets/images/videoProviders/resi_logo.png");
  }
  &.castr {
    background-image: url("/assets/images/videoProviders/castr_logo.png");
  }
  &.m3u8 {
    background-image: url("/assets/images/videoProviders/m3u8_logo.png");
  }
  &.dacast {
    background-image: url("/assets/images/videoProviders/dacast_logo.png");
  }
  &.churchstreaming {
    background-image: url("/assets/images/videoProviders/churchstreaming_logo.png");
  }
  &.streaming_church_tv {
    background-image: url("/assets/images/videoProviders/streaming_church_tv_logo.png");
  }
  &.altar,
  &.altar_live {
    background-position-y: 26px;
    background-image: url("/assets/images/videoProviders/altar_logo.png");
    position: relative;
    font-family: $font-heebo;
    .new-label {
      position: absolute;
      width: 24px;
      height: 16px;
      right: 8px;
      top: 8px;
      background: $error;
      border-radius: 2px;
      font-weight: bold;
      font-size: 8px;
      color: $white;
      line-height: 16px;
      text-align: center;
      vertical-align: middle;
    }
    .description {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $blue-2;
      margin: auto 0 16px;
    }
  }
  &.checked {
    box-shadow: 0 3px 9px $orange-color;
  }
  .radio-option {
    display: none;
  }
}
