@import "../../../../../../styles/index.scss";

.eventPollCreation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .pollCreation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    &-content {
      .custom-text-field-counter {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
      .styledTextArea {
        .helper-text {
          font-size: 13px;
        }
      }

      .text-field {
        color: $white;
        background-color: $popup-bg;
        .textarea {
          font-size: 16px !important;
        }
      }
      .text-label {
        color: $grey-3;
        font-weight: bold;
        font-size: 13px;
        margin: 0;
      }

      &-checkControl {
        margin: 36px 0 0 2px;
        color: $white;

        .styledCheckBox-icon {
          background-color: rgba(255, 255, 255, 0.1);
          &.withBorder {
            border-color: $beige-3;
          }
        }
      }
    }
  }

  &-set {
    margin-top: 24px;
    &-publishBtn {
      &:disabled {
        background-color: #93808d;
        cursor: not-allowed;
      }
    }

    &-draftBtn {
      &:disabled {
        cursor: not-allowed;
        color: #93808d;
      }
    }
  }
}
