@import "../../../../../../styles/index";

.stream-provider-details {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .provider-logo {
      min-width: 100px;
      height: 40px;
      @include display-flex-center;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      color: $dark-grey;
      background: no-repeat center left;
      background-size: contain;

      &.facebook {
        background-image: url("/assets/images/videoProviders/facebook_logo.png");
      }

      &.youtube {
        background-image: url("/assets/images/videoProviders/youtube_logo.png");
      }

      &.youtube_live {
        background-image: url("/assets/images/videoProviders/youtube_logo_live.png");
      }

      &.vimeo_live {
        background-image: url("/assets/images/videoProviders/vimeo_live.svg");
        background-size: 200px;
        min-width: 200px;
      }

      &.vimeo {
        background-image: url("/assets/images/videoProviders/vimeo_logo.png");
      }

      &.twitch {
        background-image: url("/assets/images/videoProviders/twitch_logo.png");
      }

      &.resi {
        background-image: url("/assets/images/videoProviders/resi_logo.png");
      }

      &.castr {
        background-image: url("/assets/images/videoProviders/castr_logo.png");
      }

      &.m3u8 {
        background-image: url("/assets/images/videoProviders/m3u8_logo.png");
      }

      &.dacast {
        background-image: url("/assets/images/videoProviders/dacast_logo.png");
      }

      &.churchstreaming {
        background-image: url("/assets/images/videoProviders/churchstreaming_logo.png");
      }

      &.streaming_church_tv {
        background-image: url("/assets/images/videoProviders/streaming_church_tv_logo.png");
      }

      &.altar,
      &.altar_live {
        background-image: url("/assets/images/videoProviders/altar_logo.png");
      }
    }
    .change-provider {
      position: relative;
      display: inline-block;
      margin-right: 10px;

      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: url("/assets/icons/back_arrow_icon.svg") center right 5px no-repeat;
        transform: rotate(-180deg);
        z-index: 1;
      }
    }
  }

  &-content {
    .description {
      margin-bottom: 0;
      ul {
        margin: 0 0 10px;
        padding-left: 25px;
      }
    }

    .label {
      font-size: 12px;
      color: #93808d;
      margin: 18px 0 5px;
    }

    .preview {
      display: flex;
      flex-direction: row;
      height: 224px;
      justify-content: space-between;

      &-inline {
        width: 328px;
        border-radius: $border-radius;
        background: $grey-5;
        overflow: hidden;
        @include display-flex-center;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
        color: $grey-color;

        .play-icon {
          display: inline-block;
          width: 14px;
          height: 20px;
          background-color: $grey-color;
          mask: url("/assets/icons/play_icon.svg") center no-repeat;
          mask-size: cover;
          margin-left: 3px;

          &-border {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid $grey-color;
            box-sizing: border-box;
            @include display-flex-center;
            margin-bottom: 10px;
          }
        }

        .uploading-progress {
          @include display-flex-center;
          height: 100%;
          width: 100%;
          flex-direction: column;
          font-family: $font-heebo;
          font-size: 16px;
          line-height: 24px;
          color: $nav-bg;

          .total {
            width: 256px;
            height: 8px;
            background-color: $white;
            margin: 10px 0;
            border-radius: 4px;

            .loaded {
              height: 100%;
              background-color: $orange-1;
              border-radius: 4px;

              &.completed {
                background-color: #44eb9b;
              }
            }
          }
          .percent {
            color: $grey-3;
          }
        }

        .altar-video {
          height: calc(100% - 40px);
        }

        .file-actions {
          @include display-flex-center(space-between, center);
          flex-shrink: 0;
          height: 40px;
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          background-color: $white;
          font-family: $font-heebo;
          font-size: 13px;
          line-height: 20px;
          color: $nav-bg;
          border: 1px solid $grey-5;
          border-top: none;
          border-radius: 0 0 10px 10px;

          .file-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .delete-file {
            height: 15px;
            width: 15px;
            cursor: pointer;

            &.uploading {
              height: 11px;
              width: 11px;
            }
          }
        }
      }

      &-fullscreen {
        width: 271px;
      }
    }
  }

  .delete-video-alert {
    .styledDialog-text-content {
      font-size: 14px;
    }
    .delete-button {
      background: $error;
      width: 156px;
      margin-left: 40px;
    }
  }
}
