@import "../../../../styles/variables.module.scss";
@import "../../../../styles/mixins";
.stream {
  &-container {
    @include flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: relative;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  &-content {
    max-width: 1024px;
    width: 100%;
    height: 100%;
    padding: 40px 16px 0;
    box-sizing: border-box;
    position: relative;

    &.without-rooms {
      padding-top: 0;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1580px) {
  .stream {
    &-content {
      &.small {
        .benchRow {
          flex-direction: column;
          margin-bottom: 0;
          &-item {
            margin: 0 0 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1246px) {
  .stream {
    &-content {
      &.medium {
        .benchRow {
          flex-direction: column;
          margin-bottom: 0;
          &-item {
            margin: 0 0 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1152px) {
  .stream {
    &-content {
      &.small {
        .videoStream {
          height: 40%;
          .videoStream-container {
            .text {
              width: fit-content;
              top: 30%;
              right: unset;
              left: 50%;
              transform: translateX(-50%);
              &-welcome {
                font-size: 18px;
              }
              &-description {
                font-size: 12px;
              }
            }
          }
        }
        .eventCountDown {
          height: 40%;
        }
        .benchRow {
          .benchAttendee {
            margin-right: 2px;
            transform: scale(0.9);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1110px) {
  .stream {
    &-content {
      &.small {
        .videoStream,
        .eventCountDown {
          height: 32%;
        }
        padding: 40px 8px 0;
        .benchRow {
          .benchAttendee {
            margin-right: -10px;
            transform: scale(0.8);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .stream {
    &-content {
      &.small {
        .benchRow {
          .benchAttendee {
            margin-right: -20px;
            transform: scale(0.68);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 936px) {
  .stream {
    &-content {
      .benchRow {
        flex-direction: column;
        margin-bottom: 0;
        &-item {
          margin: 0 0 10px;
        }
      }
    }
  }
}
