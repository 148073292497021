@import "../../../../styles/index.scss";
@import "../../../../styles/mixins.scss";
.dashboard {
  min-height: 100vh;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  font-family: $font-heebo;
  &-logo {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
  }
  .collapse-arrows-wrapper {
    position: absolute;
    right: -12px;
    cursor: pointer;
    z-index: 0;
    width: 32px;
    height: 32px;
    top: 5px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.08);
  }
  .collapse-arrows {
    transform: rotate(180deg);
    position: absolute;
    right: -8px;
    top: 2px;
    cursor: pointer;
    z-index: 100;
  }
  .collapse-arrows.collapsed {
    transform: rotate(0);
    top: 9px;
  }
  &-panel {
    @include mobile($mobile-width) {
      display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 224px;
    background: #edf0f2;
    z-index: 100;
    box-shadow: 2px 0px 10px 10px rgba(0, 0, 0, 0.03);
    &-collapsed {
      width: 72px;
      .dashboard-no-community {
        margin: 8px;
        .dashboard-logo {
          width: 40px;
          height: 40px;
          margin-right: 0;
        }
      }
    }
    &-container {
      padding: 0 0 10px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #eff3f6 !important;
    }
  }
  &-community-info {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  &-nav {
    margin: 0 auto;
    width: 100%;
    color: $white-color;
    text-align: left;
    align-items: start;
    padding-top: 0 !important;
    &-title {
      padding: 0 24px;
      margin: 0 0 8px 0;
      font-family: $font-heebo;
      font-size: 12px;
      color: $grey-color;
    }
  }
  &-navItem {
    margin: 13px auto !important;
    border-radius: 10px !important;
    width: 192px !important;
    padding: 4px !important;
    min-width: 192px;
    height: 40px;
    &-disabled {
      pointer-events: none;
      &.dashboard-navItem-collapsed .dashboard-navIcon {
        pointer-events: none;
        width: 40px !important;
        height: 40px !important;
      }
    }
    &-collapsed {
      .dashboard-navIcon {
        margin: 0 auto;
        width: 40px !important;
        min-width: 40px !important;
        pointer-events: all;
      }
      &.dashboard-navItem {
        pointer-events: none;
      }
    }
    &-active {
      background: transparent !important;
      .dashboard-navItem-text {
        font-weight: bold;
      }
      .dashboard-navIcon {
        background: #d8e1e9;
      }
    }
    &-text {
      font-family: $font-heebo !important;
      font-style: normal !important;
      font-size: 13px !important;
      line-height: 13px !important;
      display: flex;
      align-items: center;
      color: #1d1d1d;
    }
  }
  &-navContainer {
    width: auto !important;
  }
  &-navIcon {
    margin: 0 16px 0 0;
    padding: 0;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    min-width: 20px !important;
    position: relative;
    .notification,
    .new-mark {
      position: absolute;
      background: $red-color;
    }
    .notification {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: -4px;
      right: -4px;
      overflow: hidden;
    }
    .new-mark {
      font-weight: bold;
      font-size: 8px;
      color: $white-color;
      padding: 4px 3px;
      border-radius: 2px;
      top: -10px;
      right: -10px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  &-footerContainer {
    margin-top: auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 16px 16px 0 16px;
    &.collapsed {
      padding: 16px 0 0;
      .progressMenu {
        left: 88px;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &-divider {
    border: 1px solid #d8e1e9;
    width: 192px;
    &.collapsed {
      width: 40px;
      margin-bottom: 16px;
    }
  }
  &-profile {
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    border-top: 1px solid rgba($light-grey-color, 0.2);
    &-user {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
        &.empty {
          font-size: 20px;
          line-height: 26px;
          font-weight: bold;
          color: $white-color;
          background-color: $cyan;
          @include display-flex-center;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 8px;
          z-index: 1;
        }
      }
      &-name {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        display: flex;
        align-items: center;
        color: #1d1d1d;
      }
    }
    &-logout {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: $grey-color !important;
      cursor: pointer;
      &-img {
        margin-right: 12px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &.collapsed {
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .dashboard-profile-user-image {
        width: 40px;
        height: 40px;
        margin-right: 0;
        &.empty {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  &-body {
    width: 100%;
    padding: 0 0 0 224px;
    background-color: #f8f9fa;
    &-collapsed {
      padding: 0 0 0 72px;
    }
    &-logout-mobile {
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      display: none;
      color: $orange-1;
      padding: 16px 23px;
      box-sizing: border-box;
    }
    @include mobile($mobile-width) {
      padding: 0;
      &-logout-mobile {
        @include flex(flex-end);
      }
    }
  }
  &-body-disabled {
    padding: 0;
  }
  &-find {
    cursor: pointer;
    font-family: $font-heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7b9eb9;
    margin: 8px auto 0 auto;
    &-arrow {
      margin-left: 6px;
      width: 10px;
      height: 10px;
      background-image: url("/assets/icons/arrow_right_blue.svg");
    }
  }
  &-no-community {
    margin: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $light-blue-color;
    .dashboard-logo {
      margin-right: 10px;
    }
    > div {
      width: 100%;
      h4 {
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
        margin: 0;
      }
      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.dashboard-disabled {
  display: none;
}

.editCommunity {
  display: none;
  justify-content: space-between;
  align-items: center;
  font-family: $font-heebo;
  margin-bottom: 16px;
  &-status {
    font-size: 16px;
    line-height: 28px;
    color: $tabs-blue-color;
  }
  &-icon {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  &-btn {
    display: flex;
    align-items: center;
    color: $white-color;
    cursor: pointer;
    margin: 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    &.collapsed {
      margin: 0 auto;
      .editCommunity-icon {
        margin: 0 auto;
      }
    }
    p {
      margin: 0;
    }
    &-img {
      margin-right: 8px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.dashboardSubscription {
  &-activate {
    width: 100%;
    height: 40px;
    border: 0;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    background: $orange-color;
    border-radius: $border-radius-rounded;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      text-decoration: none !important;
    }
    &-text {
      font-family: $font-lora;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: $white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        background: url("/assets/icons/right_arrow_white.svg") no-repeat 100%;
        width: 16px;
        height: 16px;
        margin-left: 10px;
      }
    }
    &.collapsed {
      background: rgba(255, 255, 255, 0.15);
      border-radius: 0;
      height: 16px;
      .dashboardSubscription-activate-text {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 28px;
        letter-spacing: 0.03em;
        &::after {
          content: none;
        }
      }
    }
  }
  margin-bottom: 16px;
}

.responsive {
  &-btn {
    cursor: pointer;
    &-close,
    &-open {
      margin-right: 17px;
      margin-bottom: 2px;
    }
  }
  &-closed,
  &-opened {
    font-family: $font-heebo;
    display: none;
    align-items: center;
    &-text {
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.02em;
      margin: 0;
    }
  }
  &-closed {
    position: absolute;
    left: 24px;
    top: 24px;
    &-text {
      color: $black-color;
    }
  }
  &-opened {
    width: 256px;
    margin-bottom: 24px !important;
    &-text {
      color: $white-color;
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    // &-body {
    //   padding-left: 24px;
    //   padding-right: 24px;
    //   padding-top: 60px;
    // }
    &-body-disabled {
      padding: 0;
    }
    &-logoContainer {
      display: none;
    }
    &-nav {
      &-title {
        display: none;
      }
    }
  }
  .responsive {
    display: flex;
    &-opened {
      display: flex;
    }
    &-closed {
      &-visible {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    &-nav {
      width: 100%;
    }
    &-body {
      &-opened {
        box-sizing: border-box;
      }
    }
  }
}
