.scheduler {
  &-row {
    display: flex;

    &:last-child {
      flex: 1;
      margin-bottom: -16px;
    }
  }
  &-row-month {
    height: 16px;
    padding: 16px 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #7B9EB9;
  }
}
