@import "../../../styles/index.scss";

.styledRadio {
  &:hover {
    background-color: unset !important;
  }
  &-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $white-color;
    background-image: url("/assets/icons/radio_icon.svg");
  }
  &-checkedIcon {
    background-image: url("/assets/icons/radio_icon_checked.svg");
  }
}
