.payment-details-wrapper{
  box-sizing: border-box;
  .payment-information-wrapper{
    display: flex;
    .main-content-wrapper{

      margin: 0 130px;
      width: 630px;
    }
    .right-sidebar-content-wrapper{
      margin-top: -24px;
    width: 430px;
    }
  }
}