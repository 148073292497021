@import "../../../../../styles";

.meetingRoomCard {
  @include flex(flex-start);
  width: 560px;
  height: 96px;
  background-color: $blue-4;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 26px;
  &-img {
    @include flex();
    width: 64px;
    height: 64px;
    background: $white-color;
    border-radius: 50%;
    margin-left: 24px;
    flex-shrink: 0;
  }
  &-name {
    @include flex(flex-start);
    height: 48px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    margin-left: 24px;
  }
  &-actions {
    margin-left: auto;
    margin-right: 10px;
    .three-dots-popup {
      &-wrapper {
        background: $white-color;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        .edit,
        .copyUrl,
        .recordings,
        .duplicate,
        .delete {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          color: $blue-1;
          margin: 16px 24px;
        }
        .delete {
          color: $error;
        }
        .divider {
          width: 88px;
          height: 0;
          border: 1px solid $grey-5;
        }
      }
    }
  }
  &-labels {
    height: 100%;
    margin: 0 10px;
    @include flex(center center column);
    .label {
      font-size: 13px;
      line-height: 20px;
      padding: 2px 8px;
      height: 24px;
      border-radius: 9px;
      display: flex;
      align-items: center;
      &.draft {
        background: $grey-5;
        color: $grey-3;
      }
    }

  }
}
