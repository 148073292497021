@import "../styles.scss";

.sermonHeader {
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }

  &-title {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: $black-color;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
  }

  &-subtitle {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $grey-color;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    span {
      white-space: nowrap;
      position: relative;
    }

    .meeting-subtitle-subject {
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      position: relative;
      padding-right: 16px;

      &::after {
        content: "\00b7";
        margin: 0 6px;
        color: inherit;
        font-size: 36px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: -3px;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button, a {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .sermonHeader-link-back {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $grey-color;
    position: relative;
    padding-left: 16px;
    cursor: pointer;

    &::before {
      content: url("/assets/icons/arrow.svg");
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
