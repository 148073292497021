@import "../../../../styles/variables.module";
@import "../../../../styles/mixins";
.table-list-wrapper {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  .table-list-content {
    @include flex(center, flex-start);
    width: 100%;
    min-height: 100%;
    .tables {
      width: 776px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 32px 16px;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1598px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        &.tables-sideBar {
          width: 528px;
        }
      }
    }
  }
}

@media screen and (max-width: 1351px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        &.tables-sideBar {
          padding: 32px 4px;
          width: 256px;
        }
      }
    }
  }
}

@media screen and (max-width: 1223px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        width: 528px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        &.tables-sideBar {
          .table-item-wrapper {
            width: 256px;
            margin: 0 0 8px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        &.tables-sideBar {
          width: 240px;
          .table-item-wrapper {
            width: 240px;
            margin: 0 0 8px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 974px) {
  .table-list-wrapper {
    .table-list-content {
      .tables {
        width: 280px;
      }
    }
  }
}
