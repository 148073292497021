@import "../../../styles/index.scss";
.overlay {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 900;
  &.light {
    background-color: $semitransparent-color;
  }
  &.dark {
    background-color: $dark-popup-background-color;
  }
}