@import "../../../../../styles/index.scss";
.table-item-wrapper {
  flex-shrink: 0;
  width: 240px;
  @include display-flex-center;
  margin: 0 0 8px 8px;
  .table-content {
    width: 240px;
    height: 264px;
    display: flex;
    flex-direction: column;
    background-color: rgba(14, 10, 12, 0.3);
    border-radius: 20px;
    .table-name {
      font-family: $font-heebo;
      margin: 16px 16px 0;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: $purple-2;
      &.can-edit {
        span {
          cursor: pointer;
          padding-right: 20px;
          &:hover {
            color: $purple-4;
            background: url("/assets/icons/edit_icon_2.svg") top right no-repeat;
          }
        }
      }
    }
    .table-seats {
      cursor: pointer;
      position: relative;
      height: 208px;
      width: 208px;
      align-self: center;
      margin: 8px 16px 16px;
      &.not-available {
        cursor: not-allowed;
        .button-wrapper {
          color: #7e6677 !important;
        }
        .benchAttendee-wrapper {
          cursor: not-allowed;
        }
      }
      .table {
        background: $popup-bg;
        border-radius: 50%;
        width: 112px;
        height: 112px;
        position: absolute;
        @include position-center;
        text-align: center;
        .button-wrapper {
          margin-top: 45px;
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          text-align: center;
          letter-spacing: 0.04em;
          color: #e1a077;
          background: none;
          border: none;
          outline: none;
        }
      }
      .attendee-list {
        height: 100%;
        position: relative;
        .attendee {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 4px solid #93808d;
          background: #7e6677;
          position: absolute;
          &:hover {
            .attendee-name-wrapper {
              display: flex;
            }
          }
          .attendee-name-wrapper {
            display: none;
            position: absolute;
            height: 60px;
            border-radius: 50px;
            z-index: -1;
            background: #0e0a0c;
            opacity: 0.5;
            align-items: center;
            width: 200px;
            .attendee-name {
              padding-left: 75px;
              padding-right: 10px;
              font-size: 13px;
              font-weight: bold;
              color: #f3f3f3;
            }
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .initials {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .fallback {
              font-weight: bold;
              font-size: 16px;
              line-height: 26px;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              text-transform: uppercase;
            }
          }
          &.active {
            border: 4px solid #282025;
            z-index: 1;
          }
          &.top {
            top: -32px;
            right: 24px;
          }
          &.bottom {
            bottom: -34px;
            right: 24px;
          }
          &.right {
            right: -34px;
            top: 24px;
          }
          &.left {
            left: -32px;
            top: 24px;
          }
        }
        .benchAttendee {
          width: 76px;
          height: 66px;
          position: absolute;
          align-items: center;
          margin-right: 0;
          .benchAttendee-wrapper {
            width: 54px;
            height: 54px;
            border-radius: 17px;
            background: $purple-2;
            opacity: 0.6;
            &::before {
              border-radius: 17px;
            }
          }
          .benchAttendee-half-circle {
            width: 76px;
            height: 26px;
            border-bottom-left-radius: 110px;
            border-bottom-right-radius: 110px;
            border: 6px solid $purple-2;
            border-top: 0;
            opacity: 0.6;
            position: absolute;
            bottom: initial;
            &::before,
            &::after {
              width: 6px;
              height: 6px;
              background: $purple-2;
            }
          }
          .benchAttendee-name-wrapper {
            height: 62px;
            top: 8px;
          }
          &.active {
            z-index: 1;
            .benchAttendee-wrapper {
              opacity: 1;
            }
            .benchAttendee-half-circle {
              opacity: 1;
              &::before,
              &::after {
                opacity: 1;
              }
            }
          }
          &.top {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            flex-direction: column;
            justify-content: flex-end;
            .benchAttendee-half-circle {
              top: 0;
              left: 0;
              transform: rotate(180deg);
            }
          }
          &.bottom {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            flex-direction: column;
            justify-content: flex-start;
            .benchAttendee-half-circle {
              bottom: 0;
            }
            .benchAttendee-name-wrapper {
              top: -4px;
            }
          }
          &.right {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            justify-content: flex-start;
            height: 76px;
            width: 66px;
            .benchAttendee-half-circle {
              transform: rotate(-90deg) translateY(15px);
            }
            .benchAttendee-name-wrapper {
              left: -4px;
            }
          }
          &.left {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            justify-content: flex-end;
            height: 76px;
            width: 66px;
            .benchAttendee-half-circle {
              transform: rotate(90deg) translateY(15px);
            }
            .benchAttendee-name-wrapper {
              left: -4px;
              transform: translate(-24%) scaleX(0);
              border-radius: 9px 23px 23px 9px;
              .benchAttendee-name {
                text-align: left;
                padding-left: 10px;
                padding-right: 75px;
                justify-content: flex-end;
              }
            }
          }
          &:hover.left {
            .benchAttendee-name-wrapper {
              transform: translate(-63%) scaleX(1);
            }
          }
          &-toFind.active {
            .benchAttendee-half-circle {
              border-color: $orange-2;
              &::before,
              &::after {
                background: $orange-2;
              }
            }
            .benchAttendee-name-wrapper {
              transform: translate(0) scaleX(1);
            }
            .benchAttendee-wrapper {
              z-index: 2;
            }
            .benchAttendee-wrapper {
              &::before {
                border-color: $orange-2;
              }
            }
          }
        }
      }
    }
  }
}
