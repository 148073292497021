@import "../../../../../styles/mixins";
.upcoming-event-component-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  .badge {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  .upcoming-event-wrapper {
    width: 100%;
    height: 450px;
    background-color: $grey-1;
    position: relative;
    margin: 0 auto;
    .live-block {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 450px;
        button {
          padding: 16px;
          background: #eb5757;
          border-radius: 8px;
          font-family: $font-heebo;
          font-weight: 500;
          font-size: 19px;
          line-height: 30px;
          color: #ffffff;
          outline: none;
          border: none;
          cursor: pointer;
          position: absolute;
          display: flex;
          align-items: center;
          &::before {
            content: " ";
            background-image: url("/assets/icons/play-live.svg");
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .videoStream {
      height: 100%;
      .text,
      .blurScreen,
      .unmute {
        display: none;
      }
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.default-image {
        //object-fit: cover;
      }
    }
    .event-count-down {
      position: absolute;
      color: var(--text-color);
      background: var(--content-backgroud-color);
      bottom: 10px;
      left: 10px;
      border-radius: 8px;
      height: 52px;
      width: fit-content;
      .count-down {
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-family: $font-heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        height: 52px;
        white-space: inherit;
        white-space: nowrap;
        .countdown-timer {
          font-family: $font-heebo;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          display: flex;
          align-items: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: var(--text-color);
          margin: 0 8px;
        }
        button {
          height: 100%;
          background: var(--accent-color);
          border-radius: 0px 8px 8px 0px;
          font-family: $font-heebo;
          font-weight: 500;
          font-size: 19px;
          line-height: 130%;
          color: var(--text-color-link);
          margin-right: -14px;
          outline: none;
          border: none;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 95%;
          text-transform: capitalize;
        }
      }
    }
  }
  .event-information-wrapper {
    padding: 24.5px 20px 20px 20px;
    width: 100%;
    box-sizing: border-box;
    background: var(--content-backgroud-color);
    .event-date-wrapper {
      margin-bottom: 14.5px;
      .date-start {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--text-color);
      }
      .private-badge {
        margin-left: 24px;
      }
    }
    .event-title-wrapper {
      font-family: $font-heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 130%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: var(--text-color);
    }
    .event-description-wrapper {
      @include truncate-lines(15);
      min-height: 80px;
      .event-description-text {
        font-family: $font-heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        color: var(--text-color);
      }
      margin-bottom: 19px;
    }
    .event-speakers-wrapper {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 1s;
      .speaker {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 20px;
        &-info {
          &-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 130%;
            color: $white-color;
            &-title {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              margin-left: 8px;
            }
          }
          &-description {
            margin-top: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #9a9fa5;
          }
        }
      }
      &.active {
        opacity: 1;
        max-height: 530px;
      }
    }
  }
  .event-footer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    &-moreInfo {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #e1a077;
      &.active {
        img {
          rotate: 180deg;
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 800px) {
  .upcoming-event-component-wrapper {
    .event-information-wrapper {
      padding: 16px;
      .event-date-wrapper {
        margin-bottom: 12px;
        .date-start {
          font-size: 11px;
          line-height: 140%;
        }
        .private-badge {
          margin-left: 12px;
        }
      }
      .event-title-wrapper {
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 12px;
      }
      .event-description-wrapper {
        margin: 0;
      }
      .event-speakers-wrapper {
        .speaker-info {
          &-name {
            font-size: 16px;
            &-title {
              font-size: 14px;
            }
          }
          &-description {
            font-size: 14px;
          }
        }
        &.active {
          max-height: 380px;
        }
      }
    }
    .image-wrapper {
      height: 200px;
      .live-block {
        .button-wrapper {
          height: 200px;
          button {
            height: 34px;
            font-size: 13px;
          }
        }
      }
      .event-count-down {
        .count-down {
          padding: 8px;
          font-size: 11px;
          line-height: 140%;
          .countdown-timer {
            font-size: 11px;
            line-height: 140%;
          }
          button {
            font-size: 13px;
            right: -56px;
            height: 31px;
            margin: -16px;
          }
        }
      }
    }
    .event-footer {
      justify-content: space-between;
      font-size: 14px;
      margin-top: 16px;
      transform: scale(0.8) translate(-12%);
      .social {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 488px) {
  .upcoming-event-component-wrapper {
    .event-count-down {
      transform: scale(0.8) translate(-12%, 10%);
    }
  }
}

@media screen and (max-width: 394px) {
  .upcoming-event-component-wrapper {
    .event-count-down {
      transform: scale(0.55) translate(-42%, 10%);
    }
  }
}
