@import "../../../styles/index.scss";
.top-notification {
  display: flex;
  height: 70px;
  position: fixed;
  z-index: 501;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  font-size: 16px;
  line-height: 24px;
  color: $white-color;
  &>div {
    height: 100%;
    background-color: $blue-1;
  }
  &-left {
    width: 60px;
    transform: skewX(20deg);
    border-bottom-left-radius: 25px 20px;
    margin-right: -35px;
  }
  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    border-radius: 0 0 20px 20px;
    min-width: 342px;
    &-close {
      height: 10px;
      width: 10px;
      margin-left: 25px;
      mask-image: url("/assets/icons/cross_arrow_white.svg");
      mask-repeat: no-repeat;
      mask-size: contain;
      background-color: $blue-3;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &-right {
    width: 60px;
    transform: skewX(-20deg);
    border-bottom-right-radius: 25px 20px;
    margin-left: -35px;
  }
}

@media screen and (max-width: 440px) {
  .top-notification {
    transform: scale(0.7) translate(-70%, -22%);
  }
}
