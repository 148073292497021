@import "../../../../../../styles/index";
.event-pre-event-step {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
    .lamp-link {
      display: inline-block;
      width: 18px;
      height: 24px;
      mask: url("/assets/icons/lamp.svg") no-repeat;
      background-color: $purple-color;
    }
  }
  &-content {
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: $blue-color;
    }
    label {
      display: inline-block;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      margin: 0 0 5px 16px;
    }
    .pre-images-interval {
      margin-bottom: 24px;
    }

    .pre-event-settings {
      margin-bottom: 24px;
    }

    .info-message,
    .error-message {
      color: $error;
      width: 100%;
      text-align: center;
      margin: 0;
    }

    .info-message {
      color: $grey-4;
    }

    .images {
      &-dropzone {
        user-select: none;
        flex: 1;
        &-item {
          display: flex;
          user-select: none;
          cursor: pointer;
          width: 156px !important;
          height: 176px !important;
          position: relative;
          pointer-events: none;
          &:active {
            cursor: grabbing;
            img {
              box-sizing: border-box;
              border: 3px solid #7b9eb9;
              border-radius: 10px;
            }
          }
          &-image {
            user-select: none;
            pointer-events: none;
          }
          &-template {
            position: absolute;
            width: 156px;
            height: 176px;
            pointer-events: auto;
            &.fullscreen {
              width: 328px;
            }
          }
          .imageUpload {
            border-radius: 10px;
            &-uploaded-options {
              pointer-events: auto;
              height: 24px;
              top: 11px;
              right: 11px;
              z-index: 2;
            }
          }
        }
      }
      &-formWrapper {
        width: 100%;
      }

      .editIcons {
        &-edit,
        &-add {
          display: none;
        }
        &-delete {
          background-color: rgba(217, 217, 217, 0.5);
          border-radius: 50%;
        }
        &-drag {
          position: absolute;
          top: 11px;
          left: 11px;
          width: 24px;
          height: 24px;
          background-color: rgba(217, 217, 217, 0.5);
          border-radius: 50%;
          z-index: 2;
          pointer-events: auto;
        }
      }

      .error-message {
        color: $error;
        width: 100%;
        text-align: right;
        margin: 0;
      }
    }
    .pre-event-type {
      label {
        margin-right: 62px;
        margin-left: 0;
      }
    }

    .preview {
      display: flex;
      flex-direction: row;
      height: 224px;
      justify-content: space-between;

      &-inline {
        width: 328px;
        border-radius: $border-radius;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
      }

      &-fullscreen {
        width: 271px;
      }
    }

    .preview-video {
      display: flex;
      flex-direction: row;
      height: 224px;
      justify-content: space-between;

      &-inline {
        width: 328px;
        border-radius: $border-radius;
        background: $grey-5;
        overflow: hidden;
        @include display-flex-center;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
        color: $grey-color;

        .play-icon {
          display: inline-block;
          width: 14px;
          height: 20px;
          background-color: $grey-color;
          mask: url("/assets/icons/play_icon.svg") center no-repeat;
          mask-size: cover;
          margin-left: 3px;

          &-border {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid $grey-color;
            box-sizing: border-box;
            @include display-flex-center;
            margin-bottom: 10px;
          }
        }

        .uploading-progress {
          @include display-flex-center;
          height: 100%;
          width: 100%;
          flex-direction: column;
          font-family: $font-heebo;
          font-size: 16px;
          line-height: 24px;
          color: $nav-bg;

          .total {
            width: 256px;
            height: 8px;
            background-color: $white;
            margin: 10px 0;
            border-radius: 4px;

            .loaded {
              height: 100%;
              background-color: $orange-1;
              border-radius: 4px;

              &.completed {
                background-color: #44eb9b;
              }
            }
          }
          .percent {
            color: $grey-3;
          }
        }

        .altar-video {
          height: calc(100% - 40px);
        }

        .file-actions {
          @include display-flex-center(space-between, center);
          flex-shrink: 0;
          height: 40px;
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          background-color: $white;
          font-family: $font-heebo;
          font-size: 13px;
          line-height: 20px;
          color: $nav-bg;
          border: 1px solid $grey-5;
          border-top: none;
          border-radius: 0 0 10px 10px;

          .file-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .delete-file {
            height: 15px;
            width: 15px;
            cursor: pointer;

            &.uploading {
              height: 11px;
              width: 11px;
            }
          }
        }
      }

      &-fullscreen {
        width: 271px;
      }
    }

    .event-section {
      padding-top: 24px;
      border-top: 1px solid $wisp-pink-color;
      margin-top: 24px;
      &-label {
        margin: 0;

        label {
          margin: 0;
        }
      }
      .MuiFormControlLabel-root {
        .MuiFormControlLabel-label {
          user-select: none;
        }
      }
      .styledCheckBox-icon {
        background-color: $blue-4;
      }
    }

    .upload-file {
      margin-bottom: 25px;
    }
  }

  .text-field-with-icon {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: self-end;

    .item {
      width: 100%;
      margin-right: 8px;
    }

    img {
      margin-bottom: 12px;
    }
  }

  .altar-video-upload {
    height: 150px;
    background-color: $blue-4;
    border-radius: $border-radius;
    padding-bottom: 27px;
    box-sizing: border-box;

    &-inner {
      height: 100%;
      @include display-flex-center(flex-end);
      flex-direction: column;
      color: $blue-1;
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;

      span {
        margin-top: 17px;
      }
    }

    &.active {
      background-color: $blue-3;
    }
  }
}

.delete-video-alert {
  .styledDialog-text-content {
    font-size: 14px;
  }
  .defaultButtons-confirm {
    &.delete-button {
      background: $error;
      width: 156px;
      margin-left: 40px;
    }
  }
}
