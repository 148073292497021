@import "../../../../../styles/index.scss";
.event-types {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white-color;
  border-radius: 8px;
  padding: 34px;
  color: $black-color;
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    .close {
      mask: url("/assets/icons/close_icon.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      background: #93808d;
      opacity: 0.5;
      position: absolute;
      width: 12px;
      height: 12px;
      top: -20px;
      right: -20px;
      cursor: pointer;
    }
  }
  &__body {
    display: flex;
  }
  &__footer {
    margin-top: 32px;
    padding-top: 24px;
    @include flex(space-around);
    display: none;
    width: 100%;
    &--show {
      border-top: 1px solid $grey-5;
      display: flex;
    }

    .meeting-learnMore {
      text-transform: none;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: "";
        mask: url("/assets/icons/link2.svg") no-repeat;
        mask-size: contain;
        background-color: $blue-1;
        left: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        transform: rotate(77deg) skew(-14deg);
      }
    }
  }
}
