.table-classroom-switcher-wrapper {
  margin: 32px auto 0;
  text-align: center;
  width: 400px;
  display: flex;
  align-self: center;
  .tab-item {
    padding: 7.5px 70px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #93808d;
    background: #382e35;
    border-radius: 8px;
    cursor: pointer;
    &.active {
      background: #7e6677;
      color: #eff3f6;
    }
  }
}

@media screen and (max-width: 1196px) {
  .table-classroom-switcher-wrapper {
    width: 268px;
    .tab-item {
      padding: 7.5px 40px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .table-classroom-switcher-wrapper {
    width: 220px;
    .tab-item {
      padding: 7.5px 24px;
    }
  }
}