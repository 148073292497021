@import "../../../styles/index";

.attentionBanner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -20px 0 10px;
  width: 100%;

  .attentionBanner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 46px;
    background: #ef7171;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

    &-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 60%;

      img {
        margin: 0 16px 0 25px;
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.04em;
        color: $white-color;
        margin-right: 24px;
      }

      &-description {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: $white-color;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 40%;

      &-billing {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        text-decoration-line: underline;
        color: $white-color;
        cursor: pointer;
      }

      &-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #f28d8d;
        cursor: pointer;
        margin: 16px 16px 16px 24px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
