@import "../../../../styles/variables.module";

.typingIndicator,
.isInterlocutorLeftChannel {
  display: flex;
  width: 100%;
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  color: $grey-color;
  min-height: 18px;
  background: $background-color;
  padding: 0 16px;
  p {
    margin: 0;
  }
}
