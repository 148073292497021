.testimonials-wrapper {
  .testimonial {
    display: flex;
    padding: 24px;

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .person {
      .person-name {
        font-family: Lora;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.87);
      }

      .person-company {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #93808D;
        margin-bottom: 5px;
      }

      .person-quote {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #403E3E;
      }
    }

    &:nth-child(2n){
      margin-left: 24px;
    }
  }
}