@import "../styles.scss";

.copy-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: $black-color;
  margin-bottom: 16px;
  .copy-item-label {
    color: $grey-color;
    margin-right: 6px;
    text-transform: capitalize;
    min-width: 70px;
  }
  .copy-item-content {
    border: 1px solid $soft-peach-color;
    box-sizing: border-box;
    border-radius: $border-radius;
    padding: 8px 46px 8px 16px;
    width: 100%;
    height: 40px;
    position: relative;
    cursor: pointer;
    @include truncate-line;
    &::after {
      content: "";
      background: url("/assets/icons/copy_icon.svg") no-repeat 100%;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }
  }
}
