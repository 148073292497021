@import "../../../../styles/index.scss";
.class-room-container-wrapper {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  .class-room-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100% - 121px);
    .classrooms {
      width: 100%;
      max-width: 768px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 32px 16px;
      box-sizing: border-box;
      &.fixed-height {
        height: calc(100vh - 195px);
      }
      .classroom-item {
        margin-bottom: 8px;
        width: 100%;
        padding: 16px;
        border-radius: 20px;
        background: #201a1e;
        min-height: 264px;
        .title-wrapper {
          display: flex;
          margin-bottom: 16px;
          align-items: center;
          .classroom-title {
            font-family: Heebo;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #ffffff;
            flex: 90%;
            text-align: center;
            margin-right: -56px;
          }
          .count {
            background: rgba(56, 46, 53, 0.8);
            border-radius: 4px;
            padding: 4px 8px;
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #ffffff;
            width: 40px;
            text-align: center;
          }
        }
        .classroom-body {
          .classroom-description {
            text-align: center;
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: $purple-3;
            width: 100%;
            max-width: 500px;
            margin: 0 auto 24px;
          }
          .attendees-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            .attendee {
              width: 48px;
              height: 48px;
              border-radius: 16px;
              margin-right: 8px;
              &:last-child {
                margin-right: 0;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                border-radius: 16px;
              }
              .custom-avatar--circle {
                width: 48px;
                height: 48px;
                border-radius: 16px;
              }
              &.counter {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                background: rgba(123, 158, 185, 0.7);
                color: $white-color;
              }
            }
          }
          .join-wrapper {
            margin: 0 auto;
            text-align: center;
            button {
              background: $event-input-background;
              border-radius: 8px;
              padding: 16px 75px;
              outline: none;
              border: none;
              cursor: pointer;
              font-family: Heebo;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
              letter-spacing: 0.04em;
              color: $orange-light-color;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .class-room-container-wrapper {
    .class-room-content {
      .classrooms {
        padding: 16px 4px;
        .classroom-item {
          padding: 16px 10px;
        }
      }
    }
  }
}