@import "../../../../../../styles/";

$label-text-color: $grey-3;
$form-divider-color: #382E35;
$item-space-size: 24px;
$item-padding-size: 24px;

@mixin item-appearance {
  border-radius: 20px;
  background-color: $event-input-background;
  color: $white-color;
}

@mixin item-input {
  @include item-appearance();
}

.announcement-form-wrapper {
  @include flex(space-between, stretch, column);
  height: 100%;
  color: $label-text-color;
  font-size: $font-size-normal;

  .announcement-form {
    height: calc(100% - 100px);
    overflow: auto;
  }

  .dropDown,
  .square-text-field .text-field,
  .styledInput {
    @include item-input();
    &:not(.text-area) {
      height: 48px;
    }
  }

  .dropDown-optionsList {
    @include item-appearance();
    max-height: 144px;
  }

  .dropDown-selectedName,
  .dropDown-selectedOpt:not(:hover),
  .MuiFormControlLabel-label {
    color: $white-color;
  }

  .dropDown-option {
    padding: 13px 16px;
    &:hover {
      background: rgba(14, 10, 12, 0.3);
      color: $white-color;
    }
  }

  .announcement-form-row {
    padding: 0 $item-padding-size;
    margin-bottom: $item-space-size;
    &.announcement-form-row-title {
      margin-bottom: 34px;
    }
  }

  .announcement-form-divider {
    width: 100%;
    height: 2px;
    background-color: $form-divider-color;
    margin-bottom: $item-space-size;
  }

  .announcement-form-buttons {
    padding: $item-padding-size;
    button:first-child {
      margin-bottom: 10px;
    }
  }

  .label {
    font-size: $font-size-small;
    margin-bottom: 2px; 
    margin-left: 16px; 
  }

  .custom-text-field {
    .custom-text-field-counter {
      font-size: $font-size-small;
    }
  }

  .styledCheckBox-icon.withBorder {
    background: rgba(255, 255, 255, 0.1);
    border-color: #EEE3E1;
  }
}