@import "../../../../../styles/index";

.mau-limit-banner {
  height: 56px;
  @include flex(flex-start);
  position: fixed;
  top: 0;
  margin: 24px;
  z-index: 1000;
  background-color: $blue-1;
  font-family: $font-heebo;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

  &.panel {
    &-hidden {
      width: calc(100% - 48px);
    }
    &-collapsed {
      width: calc(100% - 120px);
    }
    &-shown {
      width: calc(100% - 272px);
    }
  }

  div {
    &.attention-icon {
      margin: 0 16px 0 25px;
    }

    &.need-more-mau {
      margin-left: auto;

      .need-more-mau-link {
        text-decoration-line: underline;
      }
    }

    &.close-banner {
      @include flex;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #95b1c7;
      justify-self: flex-end;
      margin: 0 16px 0 24px;
      cursor: pointer;
      flex-shrink: 0;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
