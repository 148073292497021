@import "../../../../styles/index.scss";
.user-profile {
  width: 320px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 9px 30px rgba(31, 28, 28, 0.12);
  border-radius: 0 25px 0 0;
  overflow: hidden;
  &-header {
    position: relative;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: $white-color;
    background-color: $blue-color;
    padding: 24px 35px 24px 24px;
    .close {
      mask: url("/assets/icons/close_icon.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      background: $tabs-blue-color;
      position: absolute;
      width: 12px;
      height: 12px;
      top: 34px;
      right: 20px;
      cursor: pointer;
    }
  }
  &-body {
    background-color: $white-color;
    padding: 0 24px;
    &-data {
      padding: 24px 0 8px;
      &-email,
      &-phone {
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 16px;
      }
      &-email {
        background-image: url("/assets/icons/email_icon.svg");
      }
      &-phone {
        background-image: url("/assets/icons/phone_icon.svg");
        background-position-x: 2px;
      }
    }
    &-links {
      padding: 15px 0;
      border-top: 1px solid $soft-peach-color;
      border-bottom: 1px solid $soft-peach-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &-logout {
      padding: 15px 0;
      border-bottom: none;
      display: flex;
      align-items: center;
      .logout-icon {
        mask-image: url("/assets/icons/logout.svg");
        mask-repeat: no-repeat;
        mask-size: 16px 14px;
        background-color: $grey-color;
        display: block;
        width: 16px;
        height: 14px;
        margin-top: -2px;
        cursor: pointer;
      }
    }
    .link {
      font-family: $font-heebo;
      font-size: 16px!important;
      letter-spacing: 0.1em;
      width: auto;
    }
  }
  &.dark {
    background-color: $popup-bg;
    .user-profile {
      &-header {
        background-color: rgba($color: $purple-1, $alpha: 0.3);
      }
      &-body {
        background-color: $popup-bg;
        color: $white-color;
        &-data {
          &-email {
            background-image: url("/assets/icons/email_icon_purple.svg");
          }
          &-phone {
            background-image: url("/assets/icons/phone_icon_purple.svg");
            background-position-y: 2px;
          }
        }
        &-links {
          border-top: 1px solid rgba($color: $purple-1, $alpha: .3);
          border-bottom: 1px solid rgba($color: $purple-1, $alpha: .3);
        }
        .link {
          color: $blue-2;
        }
        &-logout {
          .link {
            color: $purple-3;
          }
          .logout-icon {
            mask-image: unset;
            background-color: unset;
            background-position: left center;
            background-repeat: no-repeat;
            background-image: url("/assets/icons/exit_icon.svg");
            padding-right: 4px;
            margin-top: 0;
            width: 24px;
            height: 18px;
          }
        }
      }
    }
  }
}
