@import "../../../../../../styles/variables.module";
@import "../../../../../../styles/mixins";

.interaction {
  width: 100%;
  height: 152px;
  border: 1px solid #eee6e4;
  border-radius: 10px;
  margin-top: 16px;
  @include flex(center, flex-start, column);
  cursor: default;
  &-body {
    @include flex(space-between, center);
    width: 100%;
    height: 96px;
    &-type {
      max-width: 520px;
      height: 100%;
      margin-left: 46px;
      @include flex(center, flex-start, column);
      flex-grow: 1;

      &-title {
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
        margin-left: 16px;
        height: 29px;
        @include flex();
      }
      &-picker {
        background-color: #f5f5f5;
        border-radius: 10px;
        height: 40px;
        width: 100%;
        @include flex(flex-start);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
        padding: 0 16px;
        box-sizing: border-box;
      }
    }
    &-postTime {
      width: 182px;
      height: 100%;
      @include flex(center, flex-start, column);
      margin-left: 16px;
      &-title {
        @include flex(flex-start);
        margin-left: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
      }

      &-picker {
        width: 100%;
      }
    }

    &-action {
      height: 100%;
      margin: 0 8px;
      @include flex(center, flex-end);
      img {
        margin: 0 8px 24px;
        cursor: pointer;
      }
    }
  }
  &-footer {
    height: 56px;
    width: 100%;
    background-color: #f4f3f3;
    @include flex(space-between);
    &-switcher {
      margin-left: 22px;
    }
    &-status {
      text-transform: capitalize;
      margin-right: 12px;
      border-radius: 9px;
      border: solid 2px;
      padding: 0 10px;
      &.scheduled {
        border-color: $orange-1;
        color: $orange-1;
      }
      &.draft {
        border-color: $grey-4;
        color: $grey-4;
      }
      &.published {
        border-color: $blue-1;
        color: $blue-1;
      }
    }
  }
}

.interactionTimer-picker {
  .styled-timepicker-wrapper {
    width: 182px;
  }
}
