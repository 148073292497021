@import "../../../../styles/variables.module";
.dateSeparator {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin: 20px 0;
  &-date {
    margin: 0;
  }
  &-date {
    font-family: $font-heebo;
    font-size: 13px;
    line-height: 20px;
    padding: 4px 15px;
    color: $blue-2;
    background: #eff3f6;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
    cursor: default;
    &.event {
      color: $purple-1;
      background: #272025;
    }
  }
}
