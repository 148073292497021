@import "../../../../../styles/index.scss";
.eventBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: $popup-bg;
  padding: 8px 16px;
  border-radius: 40px;
  font-family: $font-heebo;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  &-text {
    color: $purple-1;
  }
  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}