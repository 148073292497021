@import "../../../../../../styles/variables.module.scss";
@import "../../../../../../styles/mixins.scss";
.interactionsStep {
  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 16px;
  }
  &-switch {
    margin: 0 0 32px 10px;
    .MuiFormControlLabel-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
    }
  }
  &-interactions {
    .interactions-dropzone {
      user-select: none;
      flex: 1;
      &-item {
        background-size: cover;
        display: flex;
        user-select: none;
        position: relative;
        max-width: 624px;
      }
    }

    .editIcons {
      &-drag {
        cursor: pointer;
        position: absolute;
        top: 68px;
        left: 11px;
        width: 24px;
        height: 24px;
        background-color: rgba(217, 217, 217, 0.5);
        border-radius: 50%;
        z-index: 1;
        pointer-events: auto;
      }
    }
  }
  &-add {
    @include flex(flex-start);
    margin-top: 32px;
    span {
      margin: 0 16px;
    }
    button {
      @include flex(space-between);
    }
  }
  &-new {
    @include flex(center, flex-start, column);
    box-sizing: border-box;
    width: 100%;
    &-kind {
      @include flex();
      margin-left: 16px !important;
    }
    &-template {
      box-sizing: border-box;
      width: 100%;
      padding: 24px;
    }
    &-alertDialog {
      overflow: visible;
    }
  }
}
