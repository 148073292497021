@import "../../../../../styles/variables.module.scss";
@import "../../../../../styles/mixins";
.settings-block {
  &-bottom-divider {
    border-bottom: 1px solid #dad2d0;
    padding-bottom: 50px;
    margin-bottom: 60px;
  }
  display: flex;
  flex-direction: column;
  &-title {
    font-family: $font-heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $grey-1;
    max-width: 100%;
    padding-bottom: 24px;
  }
  &-description {
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: $grey-1;
    max-width: 530px;
    padding-bottom: 24px;
  }
  .settings-block-divider {
    margin-bottom: 32px;
  }
  .settings-block-content {
    display: flex;
  }
}
