@import "../../../../../../styles/mixins";
.stream-relay-social {
  position: relative;
  margin-bottom: 16px;
  border-radius: $border-radius;
  border: 1px solid;
  border-top: 56px solid;
  border-color: rgba($color: $purple-4, $alpha: 0.5);
  height: 0;
  padding: 0 16px;
  transition: .2s;
  .stream-relay-social-input {
    @include display-flex-center();
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.open {
    border: 1px solid;
    border-top: 56px solid;
    border-color: rgba($color: $purple-4, $alpha: 0.5);
    height: 244px;
    visibility: visible;
    opacity: 1;
    padding: 16px 16px 24px;
    .stream-relay-social-input {
      visibility: visible;
      opacity: 1;
      transition: .5s;
    }
    .stream-relay-social-link,
    .stream-relay-social-success {
      display: block;
    }
  }
  &-switcher {
    position: absolute;
    top: -44px;
    left: 0;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    @include display-flex-center(space-between);
    padding: 0 16px 0 26px;
  }
  .stream-relay-social-link {
    text-transform: unset;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    display: none;
  }
  &-success {
    background-color: rgba($color: $success, $alpha: 0.23);
    color: #219653;
    border-radius: 12px;
    padding: 0 16px;
    display: none;
    font-size: 13px;
    line-height: 24px;
  }
}