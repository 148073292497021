@import "../../../../styles/index.scss";

.sermon-details {
  &-column {
    background: $white-color;
    box-shadow: $card-shadow;
    border-radius: $border-radius;
    width: 100%;
    max-width: 424px;
    min-height: 720px;
    padding: 24px;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
    position: relative;
    &:first-child {
      margin-left: 16px !important;
    }
    &-title {
      font-family: $font-lora;
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: $dark-blue;
      margin-bottom: 16px;
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 30px;
      h3{
        margin: 0;
      }
    }
    &-label {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: $grey-color;
      &.type{
        color: $purple-color;
      }
    }
    .item {
      display: flex;
      margin-bottom: 16px;
      & > .not-last {
        margin-right: 16px;
      }
    }
    .presenters-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .event-details {
    .tab-container {
      .sermon-details-column {
        min-height: 720px;
        width: 100%;
        min-width: 100%;
        margin: 0 0 16px !important;
      }
    }
  }
}

@media screen and (max-height: 812px) {
  .sermon-details {
    &-column {
      min-height: calc(100vh - 92px);
    }
  }
}
