@import "../../../../../styles";

.progressMenu {
  position: absolute;
  @include flex(flex-start, flex-start, column);
  left: 236px;
  bottom: 88px;
  width: 350px;
  height: 385px;
  background: $white-color;
  box-shadow: 0px 2px 28px 4px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  &-item {
    height: 24px;
    margin-top: 24px;
    .styledCheckBox {
      &-icon {
        border-radius: 50%;
        background-color: $white-color !important;
        border: 2px solid $grey-5 !important;
        box-sizing: border-box;
        width: 28px;
        height: 28px;
      }
      &-checkedIcon {
        background-image: url(/assets/icons/checkedCircle_green.svg);
        border: none;
      }
    }
    &-label {
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      color: $black-color;
      &.complete {
        opacity: 0.4;
        cursor: not-allowed;
        &:hover {
          color: inherit;
        }
      }
      &:hover {
        color: $blue-1;
      }
    }
  }
  &-divider {
    width: 350px;
    height: 1px;
    background: #c4c4c4;
    opacity: 0.4;
    margin-top: 34px;
  }
  &-skip {
    align-self: center;
    height: 24px;
    @include flex();
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: $blue-1;
    margin: auto 0;
    cursor: pointer;
  }
}
