@import "../../../../../styles/variables.module.scss";

.steps {
  .step {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 32px;

    &.active {
      background: $tabs-blue-color;
      border-radius: $border-radius;

      .step-number {
        background: $blue-color;
        color: $white-color;
      }

      .step-title {
        font-weight: bold;
        color: $dark-grey;
      }
    }

    &.completed {
      .step-number {
        background-image: url("/assets/icons/check_mark.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-color: $polo-blue-color;
        border: 2px solid $blue-color;
        box-sizing: border-box;
      }
    }

    &-number {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      background: $white-color;
      color: $purple-color;
    }

    &-title {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: $purple-color;
      margin-left: 8px;
    }
  }
}
