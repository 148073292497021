@import "../../../../../styles/index.scss";

.dialog-card {
  width: 100%;
  padding: 12px 16px;
  background: $popup-bg;
  border-radius: $border-radius;
  box-sizing: border-box;
  margin-bottom: 16px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}