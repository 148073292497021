@import "../../../../styles/variables.module";
@import "../../../../styles/mixins.scss";

.channelEditDialog {
  * {
    box-sizing: border-box;
  }
  &-alertDialog {
    width: 464px;
    .styledDialog-text-content {
      letter-spacing: 0;
    }
  }
  &-confirmBtn {
    background: $error !important;
    color: $white-color !important;
  }
  button {
    padding: 0 !important;
  }
  .channelEditDialog {
    &-channel_name {
      margin-bottom: 24px;
      font-size: 18px;
      p {
        margin: 0;
      }
      &_label {
        font-size: 16px;
        line-height: 28px;
        color: $purple-color;
        text-transform: capitalize;
        margin-bottom: 8px !important;
      }
      .text-label {
        margin-left: 0;
      }
    }

    &-members {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $soft-peach-color;
        padding-top: 16px;
        &_add {
          @include flex(flex-end);
          max-width: 75px;
        }

        &_title {
          color: $purple-color;
          font-size: 13px;
          line-height: 28px;
          font-style: normal;
          font-weight: normal;
          margin: 0;
        }
      }
    }

    &-member {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_card {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &_status {
        color: $grey-color;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
      }

      &_remove {
        max-width: 100px;
        @include flex(flex-end);
      }
      .communityMember {
        padding: 8px 0;
        &-data-name {
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          color: $black-color;
        }
      }
    }
  }
  &-header {
    border-bottom: none;
    margin-bottom: 0;
    .sideDialog {
      &-header {
        &-actions {
          button {
            @include flex(flex-end);
            margin: 0;
            font-family: $font-heebo;
            text-transform: capitalize;
            letter-spacing: 0.04em;
          }
        }
      }

      &-title {
        font-size: 16px;
        font-family: Heebo;
      }
    }
  }
  &-footer {
    background: $background-color;
    button {
      font-family: $font-heebo;
      color: $red-color;
      text-align: right;
      padding: 0;
      text-transform: capitalize;
      letter-spacing: 0.04em;
    }
  }
}
