@import "../../../../../styles";

$announcement-icon-size: 32px;

.eventSideDialog {
  padding: 0;
  .eventSideDialog-header-text {
    .custom-avatar {
      @include flex();
      background-color: rgba($color: #7e6677, $alpha: 0.6);
      min-width: $announcement-icon-size;
      min-height: $announcement-icon-size;
      max-width: $announcement-icon-size;
      max-height: $announcement-icon-size;
      border-radius: 50%;
      &::before {
        content: "";
        position: absolute;
        background: url("/assets/icons/announcement_tab_icon_white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 16px;
        height: 16px;
        opacity: 0.4;
      }
      .custom-avatar-fallback {
        display: none;
      }
    }
  }
}

.eventAnnouncementsDialog {
  height: 100%;
}

.styledDialog-text-content {
  word-wrap: break-word;
  margin-bottom: 0;
}
