.anonymous-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(100vw - 600px);
  min-height: 500px;
  height: calc(100% - 80px);
  box-sizing: border-box;
  margin: 40px;
  .information-side {
    display: flex;
    flex: 50%;
    flex-direction: column;
    margin-right: 56px;
    .meeting-title {
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 38px;
      color: #e9e6e8;
      margin-bottom: 16px;
    }
    .meeting-description {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 16px;
    }
    .information-tip {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #93808d;
    }
  }
  .wallpaper-wrapper {
    display: flex;
    width: 464px;
    height: 264px;
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
    img {
      right: 0;
      bottom: 0;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
