@import "../../../styles/index.scss";

.blackTextButton {
  background: transparent;
  color: $black-color;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  &-text {
    vertical-align: middle;
    font-family: $font-lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
  }
}
