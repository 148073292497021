@import "../../../styles/index.scss";

.switch-control {
  span.label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    padding: 0 15px;
  }
  &-root {
    display: inline-flex;
    height: 40px;
    padding: 0;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  &-base {
    width: 50%;
    top: 0;
    right: 0;
    position: absolute;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
  }

  &-checked {
    transform: translateX(-100%);
  }

  &-label {
    display: flex;
  }

  &-track {
    display: flex;
    width: 100%;
    background-color: $tabs-blue-color;
    user-select: none;

    span {
      width: calc(50% - 30px);
      color: $blue-color;
    }
  }

  &-thumb {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    position: relative;
    background-color: $orange-color;
    color: $white-color;

    @at-root .disabled #{&} {
      background-color: $grey-color;
    }
  }
}
