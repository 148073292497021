@import "../../../../../../styles/variables.module.scss";
@import "../../../../../../styles/mixins.scss";
.navigation-step {
  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 14px;
  }
  &__learn {
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      mask: url("/assets/icons/link2.svg") no-repeat;
      mask-size: contain;
      background-color: $blue-1;
      left: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      transform: rotate(77deg) skew(-14deg);
    }
  }
  &__start {
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: $blue-color;
      margin-bottom: 14px;
    }
    &-content {
      @include flex(flex-start, flex-start);
      margin-top: 32px;
      align-items: flex-end;
      .content__action {
        @include flex(center, flex-start, column);
        margin-right: 32px;
        &-radio {
          &--was-started {
            color: $grey-4;
            cursor: not-allowed;
            .MuiFormControlLabel-label {
              @include flex(flex-start, center);
            }
          }
        }
        &-selector {
          margin-top: 32px;
          width: 100%;
        }
        &-description {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: $grey-3;
        }
      }
    }
  }
  &__end {
    @include flex(space-between, flex-start, column);
    height: 325px;
    border-top: 1px solid #eee6e4;
    padding-top: 32px;
    margin-top: 32px;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: $blue-color;
      margin-bottom: 14px;
    }
    &-setting {
      @include flex(space-between, center);
      width: 100%;
      .setting__actions {
        height: 221px;
        width: 320px;
        @include flex(space-between, flex-start, column);
        &-description {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: $grey-3;
        }
      }
    }
  }
}
