@import "../../../styles/index.scss";
.authWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .authWrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
}