@import "../../../../styles/variables.module";
.communitySwitch {
  font-family: $font-heebo;
  font-style: normal;
  position: relative;
  width: 224px;
  height: 100%;
  background: #ffffff;
  background-image: url("/assets/images/community_switch_bg.svg");
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  min-height: 96px;
  &.collapsed {
    width: 72px;
    margin: 0;
  }
  &-img {
    cursor: pointer;
    margin: 26px auto 26px auto;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 3px solid #d8e1e9;
    border-radius: 60px;
    &.no-image {
      object-fit: contain;
      border: none;
      cursor: default;
    }
    &.collapsed {
      margin: 26px auto 0px auto;
      width: 48px;
      height: 48px;
    }
  }
  &-text {
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    align-items: center;
    color: #181611;
    margin: 6px auto 0 auto;
    width: 172px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    &.collapsed {
      display: none;
    }
  }
  &-switch {
    cursor: pointer;
    font-family: $font-heebo;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7b9eb9;
    margin: 8px auto 0 auto;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    &.disabled {
      color: $grey-4;
      cursor: default;
    }
    &.collapsed {
      margin: 12px auto;
    }
    &-arrow {
      &.collapsed {
        display: none;
      }
      margin-left: 6px;
      width: 10px;
      height: 10px;
      background-image: url("/assets/icons/arrow_right_blue.svg");
    }
  }
  &-labels {
    width: 200px;
    height: 16px;
    display: flex;
    align-items: center;
    color: #181611;
  }
  &-counts {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 24px auto;
    &.collapsed {
      display: none;
    }
  }
  &-counter {
    font-family: $font-heebo;
    width: 80px;
    height: 32px;
    margin: 0 8px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-label {
      font-size: 10px;
      line-height: 13px;
      color: #93808d;
      letter-spacing: 0.04em;
    }
    &-number {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #1d1d1d;
    }
  }
  &-label {
    width: 60px;
    height: 14px;
    margin: 0 5px;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #7b9eb9;
  }
  &-activate {
    cursor: pointer;
    margin: 0px 24px 0 24px;
    width: 176px;
    min-width: 176px;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-bottom: 24px;
    &-label {
      font-family: $font-heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      margin: auto;
      background: #7b9eb9;
      border-radius: 10px;
      color: $white-color;
    }
    &.collapsed {
      button {
        margin-bottom: 0;
        border-radius: 0;
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.04em;
          color: #ffffff;
        }
      }
      background: #7b9eb9;
      width: 72px;
      min-width: 72px;
      height: 40px;
      margin: 0 0 5px 0;
      border-radius: 0;
    }
  }
  &-edit {
    cursor: pointer;
    background-color: transparent;
    border: 1.5px solid #7b9eb9;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 16px 24px 0 24px;
    width: 176px;
    min-width: 176px;
    height: 40px;
    &-label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      margin: auto;
      color: #7b9eb9;
      border-radius: 10px;
    }
    &.collapsed {
      display: none;
    }
  }
  &-activities {
    display: flex;
    margin: 0 24px 24px;

    &.collapsed {
      margin: 0;

      button {
        border-radius: 0;
      }
    }
  }
}

.leave-community-popup-confirm {
  background: $error !important;
}
