@import "../../../../styles/index.scss";

.registration {
  width: 100%;
  min-height: 100vh;
  display: flex;

  .authAside {
    background-color: $blue-4;

    &-header {
      background-color: $blue-4;
    }
  }

  .authMain {
    background-color: $white-2;
  }

  &-aside {
    position: relative;
    z-index: 1;
    width: 90%;

    &-header {
      font-family: $font-lora;
      font-weight: bold;
      font-size: 26px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: $purple-color;
      margin: 0 0 32px;
    }

    &-img {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
    }

    &-figure {
      position: absolute;
      z-index: -1;
      bottom: 25%;
      left: 0;
      transform: scale(1.35);
    }

    &-description {
      margin-bottom: 144px;
    }
  }

  &-divider {
    height: 5px;
    width: 104px;
    margin: 24px 0;
    background-color: $wisp-pink-color;
  }

  &-main {
    width: 100%;
    max-width: 416px;
    padding: 20px 0;
    box-sizing: border-box;

    .text-label {
      color: $grey-4 !important;

      .MuiFormLabel-asterisk {
        color: $red-color !important;
      }
    }

    .text-field {
      background-color: $grey-6;
    }

    &-header {
      .back {
        display: inline-block;
        color: $blue-color;
        position: relative;
        padding-left: 15px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 20px;
          height: 20px;
          background: url("/assets/icons/back_arrow_icon.svg") center right 5px no-repeat;
          z-index: 1;
        }
      }

      h3 {
        font-family: $font-lora;
        font-size: 26px;
        line-height: 36px;
        color: $grey-1;
        margin: 0 0 16px;
      }
    }

    &-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .styledTextArea {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }

      &.avatar {
        width: 104px;
        height: 104px;
      }
      input::-ms-reveal {
        display: none;
      }
    }
  }
}
