@import "../../../../styles/mixins";
.customLanding-wrapper {
  @include flex(center, center, column);
  background: #7a49c8;

  .scrollToSchedule {
    @include flex(center, center);
    position: fixed;
    left: calc(50% - 100px);
    z-index: 1;
    img {
      margin-right: 12px;
      transform: rotate(180deg);
    }
    bottom: 25px;
  }

  .customLanding-header {
    position: relative;
    width: 100%;
    &-image {
      width: 100%;
    }
    &-gradient {
      width: 100%;
      position: absolute;
      bottom: -150px;
      height: 325px;
      background: linear-gradient(180deg, rgba(24, 22, 17, 0) 1%, #7a49c8 50%);
    }
  }

  .customLanding-content {
    @include flex(center, center, column);
    margin: 170px 100px 0;

    &-welcome {
      @include flex(center, center, column);
      align-items: center;
      margin-bottom: 150px;
      max-width: 1200px;
      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 130%;
        color: $white-color;
      }
      &-description {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: $white-color;
        margin: 40px 0;
      }
    }

    &-guide {
      @include flex(center, center, column);
      align-items: center;
      margin-bottom: 150px;
      max-width: 1200px;
      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 130%;
        color: $white-color;
      }
      &-description {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: $white-color;
        margin: 20px 0;
      }
      &-items {
        margin-top: 30px;
        @include flex(center, center);
        .item {
          img {
            border-radius: 15px 15px 0 0;
            background-color: $white-color;
          }
          max-width: 314px;
          @include flex(center, center, column);
          margin: 0 45px;
          background: #34312a;
          border-radius: 15px;
          padding: 0 12px 25px;
          box-sizing: border-box;
          &-info {
            @include flex(center, center, column);
            width: 100%;
            padding: 24px 14px;
            &-text {
              color: $white-color;
              font-style: normal;
              font-size: 12px;
              line-height: 130%;
              text-align: center;
              &-title {
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 12px;
              }
              &-description {
                font-weight: 300;
              }
            }
            &-price {
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 130%;
              color: #e1a077;
              margin-top: 12px;
            }
          }
        }
      }
    }

    &-sponsors {
      width: 100%;
      @include flex(center, center, column);
      margin-bottom: 145px;
      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 130%;
        color: $white-color;
        margin-bottom: 84px;
      }
      &-items {
        @include flex(center, space-around);
        flex-wrap: wrap;
        .sponsor {
          @include flex(flex-start, center, column);
          width: 350px;
          margin: 0 50px 50px;
          cursor: pointer;
          img {
            width: 300px;
            height: 124px;
            //border-radius: 12px;
          }
          &-title {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 130%;
            color: $white-color;
            margin-top: 33px;
          }
          &-description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #9a9fa5;
            margin-top: 12px;
            text-align: center;
          }
        }
      }
    }

    &-speakers {
      @include flex(center, center, column);

      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 130%;
        color: $white-color;
        margin-bottom: 80px;
        align-self: flex-start;
      }
      &-keynotes {
        width: 100%;
        @include flex(space-between, flex-start, column);
        margin-bottom: 30px;
        .keynote {
          img {
            border-radius: 25px;
          }
          @include flex(space-between, flex-start);
          margin-bottom: 65px;
          &-info {
            @include flex(flex-start, flex-start, column);
            margin-left: 57px;

            &-name {
              @include flex();
              font-style: normal;
              font-weight: 700;
              font-size: 22px;
              line-height: 130%;
              color: $white-color;
              margin-bottom: 23px;
              &-badge {
                @include flex(center, center);
                width: 170px;
                height: 30px;
                background: #34312a;
                border-radius: 6px;
                color: $white-color;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                margin-left: 8px;
              }
            }
            &-title {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #9a9fa5;
              margin-bottom: 23px;
            }
            &-description {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: $white-color;
            }
          }
        }
      }
      &-main {
        @include flex(center, flex-start);
        flex-wrap: wrap;
        gap: 50px 93px;

        .speaker {
          position: relative;
          width: 280px;
          cursor: pointer;

          &-image {
            border: 2px solid transparent;
            border-radius: 28px;
          }

          &-title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #9a9fa5;
          }
          &-description {
            position: absolute;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: $white-color;
            padding: 24px 32px;
            width: 380px;
            background: #3f373c;
            border-radius: 25px;
            filter: drop-shadow(0px 4px 23px $grey-1);
            top: 0;
            z-index: 1;
            left: 300px;

            a {
              color: $white-color;
            }

            &:after {
              content: "";
              position: absolute;
              top: 43%;
              height: 0;
              width: 0;
              left: -30px;
              border-width: 15px;
              border-color: transparent #3f373c transparent transparent;
              border-style: solid;
            }
          }

          &:nth-child(4n) {
            .speaker {
              &-description {
                left: -460px;
                &:after {
                  border-color: transparent transparent transparent #3f373c;
                  left: 444px;
                }
              }
            }
          }
          &-name {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 130%;
            color: $white-color;
          }
          &.active {
            text-decoration-line: underline;
            text-decoration-color: #9a9fa5;
            .speaker-image {
              border-color: $white-color;
            }
          }
        }
      }
    }

    &-agenda {
      width: 100%;
      margin: 160px auto 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 130%;
      color: $white-color;
    }
  }
}

@media screen and (max-width: 1604px) {
  .customLanding-wrapper {
    .customLanding-content {
      &-speakers-main {
        justify-content: space-between;
        .speaker {
          &:nth-child(4n) {
            .speaker-description {
              left: 300px;
              &:after {
                left: -30px;
                border-color: transparent #3f373c transparent transparent;
              }
            }
          }
          &:nth-child(3n) {
            .speaker {
              &-description {
                left: -460px;

                &:after {
                  border-color: transparent transparent transparent #3f373c;
                  left: 444px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .customLanding-wrapper {
    .customLanding {
      &-header {
        &-gradient {
          height: 50px;
          bottom: -30px;
        }
      }
      &-content {
        max-width: 360px;
        &-welcome {
          &-title {
            font-size: 28px;
          }
          &-description {
            font-size: 12px;
            margin-top: 10px;
          }
        }

        &-guide {
          margin-bottom: 0;
          &-title {
            font-size: 28px;
            text-align: center;
          }
          &-description {
            width: 360px;
            font-size: 12px;
            margin-top: 10px;
          }
          &-items {
            flex-wrap: wrap;
            margin-top: 16px;
            .item {
              margin: 0 0 30px;
            }
          }
        }
        &-speakers {
          flex-wrap: wrap;
          width: 360px;
          &-title {
            font-size: 28px;
            margin-bottom: 40px;
            margin-top: 30px;
            align-self: center;
          }
          &-keynotes {
            .keynote {
              @include flex(center, center, column);
              img {
                border-radius: 25px;
              }
              &-info {
                @include flex(flex-start, flex-start, column);
                margin-left: 0;
                &-name {
                  @include flex(flex-start);
                  font-size: 16px;
                  width: 100%;
                  margin-top: 20px;
                  margin-bottom: 10px;
                  &-badge {
                    width: 100px;
                    height: 30px;
                    font-size: 10px;
                  }
                }
                &-title {
                  font-size: 12px;
                }
                &-description {
                  font-size: 12px;
                }
              }
            }
          }
          &-main {
            @include flex(center, center);
            gap: 30px 93px;
            .speaker {
              &-title {
                font-size: 12px;
              }
              &-description {
                font-size: 12px;
                padding: 14px 12px;
                max-width: 260px;
                top: 350px;
                left: 0;
                &:after {
                  top: -30px;
                  left: 43%;
                  border-color: transparent transparent #3f373c transparent;
                }
              }
              &:nth-child(4n),
              &:nth-child(3n) {
                .speaker {
                  &-description {
                    left: 0;
                    &:after {
                      border-color: transparent transparent #3f373c transparent;
                      left: 43%;
                    }
                  }
                }
              }
              &-name {
                font-size: 16px;
              }
            }
          }
        }
        &-agenda {
          display: flex;
          justify-content: center;
          margin: 64px auto 0;
          font-size: 24px;
          width: 360px;
        }
      }
    }
  }
}
