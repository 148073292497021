@import "../../../../../styles";

.styledLink {
  .content-cards-item {
    @include flex(flex-start);
    width: 560px;
    height: 112px;

    background: $white-color;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    text-transform: none;

    &-img {
      @include flex();
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: $blue-4;
      margin-left: 24px;
    }

    &-info {
      @include flex(flex-start, center, column);
      margin-left: 24px;
      &-title {
        width: 384px;
        height: 24px;
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: $dark-purple;
        margin-bottom: 8px;
      }
      &-description {
        width: 384px;
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $dark-purple;
        opacity: 0.9;
      }
    }
  }
}
