@import "../../../../styles/index.scss";
.eventContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    background-color: #282025;
    overflow: auto;
    &.empty {
      .empty-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        @include display-flex-center();
        .leave-event {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #282025;
    padding-right: $stream-dashboard-width;
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .alert-dialog-popup {
    & .styledDialog-text-title {
      text-transform: none;
    }
  }
}

.event-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $purple-color;
  &-title {
    font-weight: bold;
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 8px;
  }
  &-body {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.04em;
    margin: 0;
  }
}

.eventDiscussions {
  padding-bottom: 0;
  &-hidden {
    display: none;
  }
  &-chat {
    .eventSideDialog-title {
      font-size: 20px;
      line-height: 26px;
      padding: 0 4px 0 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      &-name {
        margin-right: 4px;
        max-width: 216px;
        @include truncate-line;
        &.withGreeter {
          max-width: 114px;
        }
      }
      &.noWrap {
        flex-wrap: nowrap;
      }
      .postfix {
        white-space: nowrap;
      }
      &.group {
        .eventSideDialog-title-name {
          max-width: 86px;
        }
      }
    }
    .eventSideDialog-header-back {
      align-self: flex-start;
    }
  }
}

.mobilePlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $pink-color;
  padding: 20px;
  font-family: "Heebo", sans-serif;
  background-image: url("/assets/images/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  .heading {
    font-size: 34px;
    padding-top: 32px;
  }
  .text {
    padding-top: 8px;
    font-size: 16px;
    text-align: center;
  }
  .icon {
    width: 200px;
    height: 112px;
    background-image: url("/assets/images/pc.svg");
  }
}

.knockRejected-alert {
  .styledDialog-text-title {
    text-transform: none;
  }
}

@media screen and (max-width: 1160px) {
  .eventContainer {
    &-body {
      padding-right: $stream-dashboard-width-small;
    }
    .empty {
      width: calc(100% - 80px);
    }
  }
}
