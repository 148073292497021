@import "../../../../../styles/mixins";
.featured-event-component-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  .featured-title-wrapper {
    flex: 4 1;
    margin: 15px 0;
    display: none;
    .section-title {
      font-family: $font-heebo;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 130%;
      color: var(--text-color);
    }
  }
  .featured-events-list {
    flex: 40 1;
    .featured-event {
      display: flex;
      margin-bottom: 20px;
      width: 450px;
      min-height: 200px;
      box-sizing: border-box;
      cursor: pointer;
      .img-wrapper {
        display: flex;
        width: 200px;
        min-width: 200px;
        background-color: black;
        position: relative;
        .badge {
          position: absolute;
          top: 4px;
          left: 4px;
          z-index: 1;
          padding: 6px 8px;
          font-size: 10px;
          line-height: 10px;
          border-radius: 4px;
        }
        img {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          &.default-image {
            object-fit: cover;
          }
        }
      }
      .information {
        display: flex;
        align-items: center;
        width: 300px;
        .event-information-wrapper {
          background: var(--content-backgroud-color);
          height: 100%;
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
          .event-date-wrapper {
            margin-bottom: 14px;
            .date-start {
              font-family: Heebo;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 130%;
              display: flex;
              align-items: center;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: var(--text-color);
              margin-bottom: 6px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .private-badge {
              margin-left: 16px;
            }
          }
          .event-title-wrapper {
            font-family: Heebo;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            color: var(--text-color);
          }
          .event-description-wrapper {
            width: 100%;
            @include truncate-lines(2);
            margin-bottom: 9px;
            .event-description-text {
              font-family: Heebo;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 130%;
              color: var(--text-color);
            }
          }
          .event-description-wrapper {
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            color: var(--text-color);
            font-size: 16px;
            max-height: 100%;
            .event-description-text {
              max-height: 100%;
            }
          }
        }
      }
      &:nth-child() {
        margin-bottom: 0;
      }
    }
  }
  .empty-featured-events-content {
    background: var(--content-backgroud-color);
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 500px;
    height: 100%;
    flex-direction: column;
    border: 3px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .empty-featured-image-wrapper {
      margin-top: 64px;
      margin-bottom: 32px;
    }
    .empty-title {
      font-family: Heebo;
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: var(--text-color);
      text-align: center;
    }
    .empty-description {
      margin-bottom: 48px;
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: var(--text-color);
      position: relative;
      text-align: center;
      max-width: 330px;
    }
  }
}

.upcoming-event-container-wrapper+.featured-events-wrapper {
  .featured-title-wrapper {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .featured-event-component-wrapper {
    margin-top: 20px;
    max-width: 100%;
    .featured-title-wrapper {
      margin-bottom: 16px;
      margin-left: 16px;
      display: block;
      .section-title {
        font-size: 20px;
      }
    }
    .featured-events-list {
      display: flex;
      overflow-x: scroll;
      .featured-event {
        margin: 0 16px;
        display: flex;
        flex-direction: column;
        width: 248px;
        height: 342px;
        .img-wrapper {
          width: 100%;
          height: 168px;
          min-height: 168px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .information {
          width: 242px;
          height: 175px;
          .event-information-wrapper {
            padding: 16px;
            width: 100%;
            .event-date-wrapper {
              margin-bottom: 8px;
              .date-start {
                font-size: 11px;
                line-height: 140%;
              }
              .private-badge {
                margin-left: 8px;
              }
            }
            .event-title-wrapper {
              font-size: 16px;
              line-height: 130%;
            }
          }
        }
      }
    }
    .empty-featured-events-content {
      min-height: 328px;
      height: unset;
      flex-direction: column;
      border: 3px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      width: 100%;
      margin: 0 16px;
      .empty-featured-image-wrapper {
        margin-top: 30px;
        margin-bottom: 32px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .empty-title {
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 14px;
      }
      .empty-description {
        margin-bottom: 24px;
        font-size: 11px;
        line-height: 130%;
        max-width: 220px;
      }
    }
  }
}