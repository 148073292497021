@import "../../../../styles/variables.module";
@import "../../../../styles/mixins.scss";

.analytics {
  width: 100%;
  background-color: $white-2;
  @include flex(center, center, column);
  box-sizing: border-box;
  padding: 48px 0;

  &-welcomeCard {
    width: 1142px;
    box-sizing: border-box;
    height: 320px;
    padding: 40px 56px 28px;
    border-radius: 10px;
    background-image: url("/assets/images/analytics_welcome_card.png");
    background-size: 1140px;
    background-repeat: no-repeat;
    &-title {
      color: $grey-1;
      font-family: "Lora";
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      width: 483px;
      margin-bottom: 16px;
    }
    &-description {
      width: 568px;
      font-family: "Heebo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    &-range {
      @include flex(space-between);
      position: relative;
      width: 464px;
      .dropDown {
        &-optionsList {
          border-radius: $border-radius;
        }
        &-option {
          padding: 9px 16px;
          &:hover {
            background-color: $blue-4;
            color: initial;
          }
        }
        &-selectedOpt {
          color: $blue-1;
        }
      }
      &-picker {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        position: absolute;
        width: 518px;
        height: 350px;
        top: 50px;
        box-shadow: 0 0 2px $grey-4;
        border-radius: 10px;
        background: $white-color;
        z-index: 1;
        &-control {
          display: flex;
          justify-content: space-between;
          width: 244px;
          margin: 0 16px 16px 0;
        }

        .rdrDateRangeWrapper {
          position: relative;
          width: 516px;
          border-radius: 10px;
          .rdrMonthAndYearWrapper {
            position: absolute;
            width: 100%;
            height: 40px;
            padding: 0;
          }
          .rdrMonthAndYearPickers {
            display: none;
          }
          .rdrMonths {
            .rdrMonthName {
              text-align: center;
            }
            .rdrDay {
              .rdrDayStartPreview,
              .rdrDayEndPreview,
              .rdrDayInPreview {
                height: 27px;
                width: 34px;
                top: 3px;
                left: 0;
              }
              .rdrStartEdge,
              .rdrEndEdge,
              .rdrInRange {
                height: 28px;
                width: 34px;
                top: 4px;
                left: 1px;
              }
            }
            .rdrDayToday {
              span {
                color: $orange-1;
                font-size: 14px;
                &:after {
                  background: none;
                  border: solid $orange-1 1px;
                  border-radius: 24px;
                  height: 26px;
                  width: 34px;
                  top: -1px;
                  left: 18px;
                  z-index: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &-engagement {
    width: 1142px;
    &-note {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 30px;
      span {
        font-weight: 700;
      }
    }
    &-chart {
      @include flex(center, flex-end);
      width: 100%;
      height: 374px;
      background: $white-color;
      border-radius: 10px;
    }
  }
  &-members {
    width: 1142px;
    @include flex(space-between);
    &-new,
    &-returning {
      width: 557px;
      &-chart {
        height: 286px;
        background: $white-color;
        border-radius: 10px;
        @include flex(center, flex-end);
      }
    }
  }
  &-topList {
    width: 1142px;
    @include flex(center, flex-start, column);
  }
  &-reports {
    width: 1142px;
    @include flex(center, flex-start, column);
  }
}
