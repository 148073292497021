@import "../../../../../styles";
.landing-header {
  width: 100%;
  max-width: 100%;
  background: var(--accent-color);
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  &-wrapper {
    width: 100%;
    max-width: $landing-page-width;
    height: 110px;
    margin: 0 auto;
    @include flex();
    box-sizing: border-box;
    .admin-panel-button {
      background: var(--content-backgroud-color);
      color: var(--text-color);
      padding: 20px 25px;
      border-radius: 9px;
      cursor: pointer;
      font-weight: bold;
      text-transform: var(--menu-case);
      font-family: "Heebo", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-left: 30px;
    }
    hr {
      display: none;
      width: 100%;
    }
    .mobile-nav {
      display: none;
      .mobile-menu-button {
        width: 40px;
        height: 40px;
        right: 4px;
        background-image: url("/assets/icons/landing_burger.svg");
        background-repeat: no-repeat;
        background-position: center;
        &.opened {
          background-image: url("/assets/icons/cross_icon_white.svg");
        }
      }
      .links-list {
        display: block;
      }
    }
    .logo-wrapper {
      display: flex;
      align-items: center;
      flex: 30;
      img {
        max-height: 60px;
        width: auto;
        margin-right: 24px;
      }
      &-community {
        font-weight: bold;
        color: var(--text-color-link);
        text-overflow: ellipsis;
        max-width: 248px;
        overflow: hidden;
        &.sm {
          font-size: 14px;
          line-height: 20px;
        }
        &.md {
          font-size: 16px;
          line-height: 22px;
        }
        &.lg {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
    .links-wrapper {
      flex: 50;
      display: flex;
      justify-content: flex-end;
      width: 745px;
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 1px;
        text-transform: var(--menu-case);
        color: var(--text-color-link);
        margin-right: 30px;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .menu-wrapper {
      display: flex;
      flex: 10;
      position: relative;
      justify-content: flex-end;
      .profile-wrapper {
        cursor: pointer;
        .profile-top {
          display: flex;
          background: var(--backgroud-color);
          border-radius: 40px;
          width: 130px;
          padding: 4px;
          align-items: center;
          position: relative;
          .menu-title {
            font-family: Heebo;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--text-color-link);
            padding: 8px;
          }
          .icon {
            position: absolute;
            background-color: var(--text-color-link);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            right: 4px;
            background-image: url("/assets/icons/landing-burger.svg");
            background-repeat: no-repeat;
            background-position: center;
            &.colapsed {
              background-color: var(--accent-color);
              background-image: url("/assets/icons/cross_icon_white.svg");
            }
          }
        }
      }
      .menu-content {
        background: var(--text-color-link);
        border-radius: 10px;
        position: absolute;
        width: 170px;
        left: -30px;
        margin-top: 8px;
        a {
          padding: 20px;
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 130%;
          display: flex;
          align-items: center;
          color: #000000;
          border-bottom: 1px solid #e8e8e8;
          text-decoration: none;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.landing-wrapper {
  width: 100%;
  min-width: fit-content;
}

@media screen and (max-width: 900px) {
  .landing-header {
    &-wrapper {
      .links-wrapper {
        a {
          margin-right: 12px;
        }
      }
      .admin-panel-button {
        margin-left: 12px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .landing-header {
    padding: 0;
    &-wrapper {
      min-height: 64px;
      height: auto;
      padding: 16px 16px 0;
      flex-flow: wrap;
      .logo-wrapper {
        img {
          max-height: 32px;
          width: auto;
          margin-right: 8px;
        }
        .logo-wrapper-community {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .links-wrapper {
        display: none;
        &.opened {
          display: block;
          a {
            display: block;
            text-align: center;
            padding: 24px;
            margin: 0;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
          }
        }
        width: 100%;
        background: var(--backgroud-color);
        margin: 8px -16px -20px;
      }
      .mobile-nav {
        display: flex;
      }
      hr {
        display: block;
        margin: 0;
        border: none;
      }
      .admin-panel-button {
        display: none;
      }
    }
  }
  .landing-wrapper {
    min-width: 100%;
  }
}
