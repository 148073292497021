@import "../../../../styles/variables.module";

.selectItem {
  background: $light-grey-color;
  color: $black-color;
  font-family: $font-heebo;
  font-size: 12px;
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 16px;
  width: fit-content;
  &-cross {
    cursor: pointer;
    margin-left: 16px;
  }
}
