@import "../../../styles/variables.module";

.infoIcon {
  position: relative;
  margin-left: 8px;
  width: 25px;
  height: 25px;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;

    .infoIcon-image {
      filter: brightness(0) saturate(100%) invert(59%) sepia(3%) saturate(3573%) hue-rotate(164deg) brightness(103%)
        contrast(88%);
    }

    .infoIcon-text {
      visibility: visible;
      opacity: 1;
    }
  }

  &-image {
    filter: grayscale(80%);
  }

  &-text {
    transition: visibility, opacity 0.6s;
    opacity: 0;
    visibility: hidden;
    background: $white-color;
    position: absolute;
    text-align: center;
    padding: 16px;
    width: 260px;
    min-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: $black-color;
    border-radius: 10px;
    bottom: 40px;
    transform: translate(-47%, 0);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.06));

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      height: 0;
      width: 0;
      left: 46%;
      border-width: 15px;
      border-color: $white-color transparent transparent transparent;
      border-style: solid;
    }
  }
}
