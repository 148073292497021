@import "../../../../../styles/index.scss";
.video-menu-container {
  @include display-flex-center(space-between);
  box-sizing: border-box;
  width: 264px;
  z-index: 10;

  .video-menu-control-element {
    text-align: center;

    .circle-button {
      margin: auto;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #382e35;
      opacity: 0.7;
      cursor: pointer;
      position: relative;
      img {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &.end-call,
      &.disable {
        background: #592f32;
        opacity: 1;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .label {
      color: $purple-3;
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
