@import "../../../../../styles/index.scss";
.benchAttendee {
  @include display-flex-center;
  align-items: flex-start;
  position: relative;
  width: 72px;
  height: 64px;
  margin-right: 18px;
  &:last-child {
    margin-right: 0;
  }
  &-wrapper {
    width: 46px;
    height: 48px;
    background: #382e35;
    border-radius: 11px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .initials {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .fallback {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 3px;
        width: 100%;
        height: 100%;
        @include display-flex-center;
        color: #282025;
        text-transform: uppercase;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 11px;
      overflow: hidden;
      box-sizing: border-box;
      border: 2px solid transparent;
      transition: border-color 0.2s;
    }
  }
  &-half-circle {
    width: 72px;
    height: 32px;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    border: 7px solid #382e35;
    border-top: 0;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    bottom: 0;
    transition: border-color 0.2s;
    &-active {
      border: 7px solid $popup-bg-2;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      background: $popup-bg;
      border-radius: 50%;
      overflow: hidden;
      right: 100%;
      top: -2px;
      transition: background-color 0.2s;
    }
    &::after {
      right: unset;
      left: 100%;
    }
  }
  &.active {
    .benchAttendee {
      &-wrapper {
        background: $popup-bg-2;
      }
      &-half-circle {
        border: 7px solid $popup-bg-2;
        border-top: 0;
        &::before,
        &::after {
          background: $popup-bg-2;
        }
      }
    }
    border-radius: 20px;
  }
  &-name-wrapper {
    position: absolute;
    @include display-flex-center;
    top: -6px;
    left: 6px;
    min-width: 200px;
    height: 60px;
    border-radius: 16px;
    overflow: hidden;
    background: $common-panel-bg;
    z-index: 1;
    opacity: 0.5;
    pointer-events: none;
    transform: translate(-50%) scaleX(0);
    transition: 0.2s;
    box-sizing: border-box;
    .benchAttendee-name {
      @include display-flex-center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding-left: 75px;
      padding-right: 10px;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
      font-weight: bold;
      color: $grey-6;
      box-sizing: border-box;
    }
  }
  &:hover {
    .benchAttendee-name-wrapper {
      transform: translate(0) scaleX(1);
    }
    .benchAttendee-wrapper {
      z-index: 2;
    }
  }
  &-toFind.active {
    .benchAttendee-half-circle {
      border-color: $orange-2;
      &::before,
      &::after {
        background: $orange-2;
      }
    }
    .benchAttendee-name-wrapper {
      transform: scaleX(1) !important;
    }
    .benchAttendee-wrapper {
      z-index: 2;
    }
    .benchAttendee-wrapper {
      &::before {
        border-color: $orange-2;
      }
    }
  }
  &.currentMember {
    .benchAttendee-half-circle {
      border-color: $orange-2 !important;
      &::before,
      &::after {
        background: $orange-2 !important;
      }
    }
  }
}
