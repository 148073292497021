@import "../../../styles/index.scss";

.createButton {
  width: 168px !important;
  height: 40px !important;
  background: $orange-color;
  border-radius: $border-radius-rounded;
  &-icon {
    color: $white-color;
    vertical-align: middle;
    margin-top: -2px;
  }
  &-text {
    vertical-align: middle;
    font-family: $font-lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15em;
    color: $white-color;
  }
}
