@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";
.membersMenu {
  @include flex(space-between);
  position: fixed;
  left: 50%;
  transform: translate(-50%, 200px);
  bottom: 28px;
  height: 96px;
  width: 900px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background: $white;
  z-index: 110;
  transition: transform 0.3s;
  &.show {
    transform: translate(-50%, 0);
    .membersMenu {
      &-actions,
      &-members {
        opacity: 1;
      }
    }
  }
  &-actions {
    @include flex();
    opacity: 0;
    .button {
      margin-right: 24px;
    }
    .selectAll {
      margin-right: 32px;
    }
    .icon {
      @include flex;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $blue-3;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 16px;
      &:hover {
        background-color: $blue-1;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
      }
    }
    .remove {
      background-image: url(/assets/icons/trash.svg);
      background-color: #FFE1E1;
      transition: 0.2s;
      &:hover {
        background-image: url(/assets/icons/trash_white.svg);
        background-color: #EF7171;
      }
    }
    .edit {
      background-image: url(/assets/icons/edit_icon_blue.svg);
      &:hover {
        background-image: url(/assets/icons/edit_icon_white.svg);
      }
    }
    .export {
      background-image: url(/assets/icons/list_blue.svg);
      &:hover {
        background-image: url(/assets/icons/list_white.svg);
      }
    }
    .mail {
      background-image: url(/assets/icons/mail_blue.svg);
      margin-right: 0;
      &:hover {
        background-image: url(/assets/icons/mail_white.svg);
      }
    }
  }
  &-members {
    @include flex;
    margin-right: 10px;
    opacity: 0;
    &-description {
      @include flex(flex-start);
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
      min-width: 166px;
      &-count {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
        margin-right: 4px;
      }
      p {
        margin: 0;
      }
    }
  }
}
