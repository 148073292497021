@import "../../../styles/index.scss";

.outlinedBlueButton {
  background: transparent;
  color: $blue-color;
  border: 1px solid $blue-color !important;
  border-radius: $border-radius-rounded;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  &-text {
    vertical-align: middle;
    font-family: $font-lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
  }
}
