.community-settings-wrapper {
  margin-top: 90px;
  &.subscriptionAttention {
    margin-top: 120px;
  }
  .settings-header {
    &.active {
      .header-title {
        position: relative;
        margin-left: 32px;
        &::before {
          content: "";
          background-image: url("/assets/icons/chevron-right.svg");
          width: 12px;
          height: 22px;
          background-repeat: no-repeat;
          position: absolute;
          left: -32px;
          top: 6px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .settings-content-wrapper {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    margin-top: 2px;
    .navigation-wrapper {
      .navigation-item {
        width: 736px;
        height: 80px;
        left: 0px;
        top: 0px;
        background: #d8e1e9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;
        .title {
          margin-left: 24px;
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 26px;
          display: flex;
          align-items: center;
          color: #382e35;
          flex: 85;
        }
        .chevron {
          background-image: url("/assets/icons/chevron-right.svg");
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          margin-right: 24px;
        }
      }
    }
  }
  .dashboard-body-collapsed {
    & {
      width: calc(100% - 72px);
    }
  }
  .dashboard-body-overflow & .header {
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.1);
  }
}
