@import "../../../../../styles/index.scss";
.eventEditProfileDialog {
  font-family: $font-heebo;
  width: 100%;
  height: 100%;
  background-color: $common-panel-bg;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
  color: $white-color;
  &-container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-right: 4px;
    box-sizing: border-box;
    .styledTextArea {
      .text-label {
        margin: 0;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.04em;
        color: $grey-3 !important;
      }
      .text-field {
        background: $popup-bg;
        border-radius: 20px;
        color: $blue-4;
        min-height: 48px;
        .textarea {
          &::placeholder {
            color: $purple-2;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .MuiIconButton-root {
          padding: 8px;
        }
      }
    }
    &.avatar {
      width: 104px;
      height: 104px;
      margin: 0 auto;
      margin-bottom: 24px;
      .editIcons.editIcons-edit {
        border: 1px solid $popup-bg;
        box-sizing: border-box;
      }
    }
    &.submit {
      width: 100%;
      button {
        background: $purple-1;
      }
    }
    &.password-btn {
      justify-content: flex-end;
    }
    &-alert {
      .styledDialog-text-title {
        text-transform: none !important;
      }
    }
    &.deleteUser {
      display: flex;
      flex-direction: column;
      padding-right: 4px;
      &-divider {
        height: 1px;
        background-color: #363538;
      }
    }
    input::-ms-reveal {
      display: none;
    }
  }
  .email-help-text {
    font-size: 16px;
    line-height: 28px;
    color: $orange-color;
    padding: 0 12px;
    margin: -10px 0 20px;
  }
}
