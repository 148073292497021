@import "../../../../styles/index.scss";
.channelWrap {
  width: 100%;
  display: flex;
  height: 100%;
  &.subscriptionAttention {
    flex-wrap: wrap;
    height: calc(100% - 50px);
    .channel {
      height: 100%;
    }
    .attentionBanner-wrapper {
      margin: 4px 24px 0;
    }
  }
  * {
    box-sizing: border-box;
  }
  .str-chat {
    &__list,
    &__virtual-list {
      position: relative;
      background: $background-color !important;
      padding: 0 16px !important;
      .customMessage-header-userName {
        margin-right: 6px !important;
      }
    }
    &-channel-list {
      background: $white-color;
      height: 100%;
    }
    &__channel-list-messenger {
      min-width: 376px;
    }
    &__channel-list-messenger__main {
      padding: 0;
    }
    &__main-panel {
      padding: 0 !important;
    }
    &__li,
    &__virtual-list-message-wrapper {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &-channel {
      max-height: 100%;
    }
    &__reverse-infinite-scroll {
      padding-top: 0 !important;
    }
    .notification-error {
      display: none;
    }
  }
  .rfu-dropzone {
    background: $background-color !important;
  }
  .channelWrap &-addBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $orange-color;
    display: flex;
    font-size: 0.83em;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    img {
      width: 10px;
      height: 10px;
      object-fit: cover;
      transform: rotate(45deg);
    }
  }
  &.stream {
    min-width: 328px;
    &.anonymous {
      .str-chat {
        &-channel-list {
          height: calc(100% - 88px);
        }
      }
    }
    .channelWrap-addBtn {
      display: none;
    }
    .channelList {
      background-color: transparent;
      text-align: center;
      &-addBtn {
        margin-bottom: 8px;
      }
      .str-chat {
        background-color: transparent;
      }
    }
    .channelItem {
      padding: 0 8px 0 0;
      &-name {
        color: $purple-1;
        font-size: 16px;
        line-height: 24px;
      }
      &-date {
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
        opacity: 0.5;
      }
      &-content_bottom {
        &_text {
          flex-shrink: 1;
          margin: 0;
          font-size: 13px;
          line-height: 20px;
          @include truncate-line;
          color: $grey-5;
        }
      }
      &-container {
        padding: 12px 0;
        border: none;
      }
      &.active {
        background-color: transparent;
      }
    }
    .str-chat {
      background-color: transparent;
      &-channel-list {
        height: calc(100% - 62px);
      }
      &__main-panel {
        justify-content: flex-end;
      }
      &__list,
      &__virtual-list {
        background-color: transparent !important;
        padding: 0 !important;
      }
      .customMessageInput {
        &Wrapper {
          background-color: $popup-bg;
          border: none;
          padding-left: 14px;
          margin: 2px 12px 20px 0;
          &-owner {
            .customMessage-header {
              justify-content: flex-end;
            }
          }
        }
        &-actions {
          padding: 14px 0;
          margin-right: 12px;
          .sendBtn {
            height: 20px;
            width: 20px;
            transition: opacity 0.2s;
            margin-right: 0;
            &:disabled {
              opacity: 0.4;
            }
          }
        }
        &-input {
          min-height: 48px;
          justify-content: center;
          align-items: center;
          .MuiInput-input {
            font-size: 13px !important;
            color: $white-color;
            &::placeholder {
              font-size: 13px;
            }
          }
        }
      }
      .customMessage {
        background-color: $popup-bg;
        border-radius: 4px 20px 20px 20px;
        margin-top: 8px;
        margin-right: 10px;
        margin-left: 40px;
        &-owner {
          background-color: $purple-1;
          border-radius: 20px 4px 20px 20px;
          margin-right: 46px;
          margin-left: 0;
        }
        &-text {
          color: $white-color;
        }
        &-header {
          &-messageInfo {
            margin: 0;
          }
          &-actionsBtn {
            display: none;
          }
          &-userName,
          &-date {
            color: $purple-1;
            font-weight: bold;
          }
        }
        &-attachments {
          margin-top: 4px;
          margin-right: 8px;
          margin-left: 40px;
          .str-chat__message-attachment {
            width: auto;
            border-radius: 4px 20px 20px 20px !important;
            &-file {
              &--item {
                background-color: #382e35;
                &-text {
                  margin-right: 14px;
                  max-width: 218px;
                  a {
                    color: $white-color;
                  }
                }
              }
            }
          }
          &-owner {
            margin-right: 44px;
            margin-left: 0;
            .str-chat__message-attachment {
              border-radius: 20px 4px 20px 20px !important;
              img {
                margin-left: auto;
              }
              &-file--item {
                background-color: #7e6677;
                &-text {
                  margin-right: 14px;
                  max-width: 222px;
                }
              }
            }
          }
        }
      }
      .typingIndicator {
        background-color: transparent;
      }
      .rfu-dropzone {
        background-color: transparent !important;
      }
    }
  }
}

.channelList {
  width: 392px;
  background: $white-color;
  box-shadow: 1px 0 17px rgba(0, 0, 0, 0.08);
  display: inline-block;
  height: 100%;
  z-index: 2;
  .str-chat {
    width: 100%;
    background-color: $white-color;
    height: calc(100% - 94px);
    float: none;
    overflow: auto;
  }
  &-event {
    .str-chat {
      height: 100%;
    }
  }
  &-fullWidth {
    width: 100%;
  }
  &-hidden {
    display: none;
  }
}

.channel {
  width: calc(100% - 392px);
  display: inline-block;
  overflow: auto;
  &-fullWidth {
    width: 100%;
  }
  &-hidden {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .channelList {
    .str-chat-channel-list.messaging {
      position: relative;
      left: 0;
      top: 0;
      z-index: 1;
      min-height: unset;
      box-shadow: unset;
    }
  }
}

@media screen and (max-width: 1160px) {
  .channelWrap {
    &.stream {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      .str-chat {
        .customMessage {
          margin: 8px 0 0 14px;
          &-owner {
            margin: 8px 14px 0 0;
          }
          &-attachments {
            .str-chat__message-attachment {
              margin: 8px 0 0 14px !important;
              max-width: 236px;
            }
            &-owner {
              .str-chat__message-attachment {
                margin: 8px 14px 0 0 !important;
              }
            }
          }
        }
      }
      .str-chat {
        .customMessageInputWrapper {
          &.withError {
            margin: 2px 12px 48px 0;
          }
        }
      }
    }
  }
}
