@import "../../../styles/variables.module";
@import "../../../styles/mixins.scss";

.square-time-picker {
  .timePicker {
    border-radius: $border-radius;
    background-color: $grey-transparent-background-color;
  }
}

.styled-timepicker-wrapper {
  @include flex();
  .styled-timepicker-column {
    width: 40px;
    margin-top: 21px;
    margin-bottom: 21px;
    @include flex(flex-start, center, column);
    &:nth-child(1) {
      margin-left: 16px;
      margin-right: 8px;
    }
    &:nth-child(3) {
      margin-left: 8px;
      margin-right: 16px;
    }
    svg {
      cursor: pointer;
    }
    .styled-timepicker-field {
      margin: 14px 0;
      background-color: #F3F3F3;
      mix-blend-mode: normal;
      border-radius: 10px;
      font-size: 16px;
      width: 48px;
      height: 40px;
      line-height: 24px;
      color: #181611;
      @include flex();
    }
  }
  .styled-toggle-button-group {
    margin: 0 16px;
    background-color: #EFF3F6;
    border-radius: 30px;
    button {
      background-color: #EFF3F6;
      font-size: 16px;
      line-height: 24px;
      color: #8C8C8C;
      border-radius: 30px;
      border: none;
      width: 56px;
      &:first-child {
        margin-right: 3px;
      }
      &.Mui-selected {
        color: $white;
        background-color: #7B9EB9;
      }
    }
  }
  .hours-divider {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.styled-time-picker-popover {
  .MuiPaper-root {
    margin-top: 8px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  }
}

.timepicker-divider {
  width: 100%;
  border-bottom: 1px solid #EEE6E4;
}

.timepicker-buttons {
  @include flex(flex-end);
  button {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    max-width: 80px;
    max-height: 32px;
    margin: 10px 0;
  }
}
