@import "../../../../../styles/index.scss";

.recordingsItem {
  font-family: $font-heebo;
  display: block;
  margin-bottom: 25px;
  td {
    height: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-title {
    &-name {
      font-size: 18px;
      line-height: 28px;
      color: $black-color;
      max-width: 152px;
      text-align: left;
      @include truncate-line;
    }
    &-duration {
      font-size: 16px;
      line-height: 28px;
      color: $grey-color;
      min-width: 110px;
      text-align: left;
    }
  }
  &-actions {
    &-download {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
    &-download {
      color: $purple-color;
      &::before {
        background: url("/assets/icons/download_icon.svg") no-repeat 100%;
      }
    }
    &-processing {
      font-size: 16px;
      line-height: 28px;
      color: $grey-color;
      padding-right: 10px;
    }
  }
}
