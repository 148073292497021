@import "../../../../../styles/index.scss";
.eventCountDown {
  display: flex;
  font-family: $font-heebo;
  color: $purple-2;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
  &.isMobile {
    height: initial;
    .eventCountDown {
      &-content {
        &.back-img {
          min-height: 250px;
          margin: 0 8px;
          @media screen and (max-width: 420px) {
            background: no-repeat bottom left 5% url("/assets/images/count_down_img.svg");
            background-size: 85px;
          }
        }
      }
      &-image {
        @include flex();
        max-height: 250px;
        max-width: 320px;
      }
      &-label {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .countdown-timer {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .countdown-timer {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    color: $white;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &.back-img {
      border-radius: 20px;
      border: $purple-2 solid;
      background-image: url("/assets/images/count_down_img.svg");
      background-repeat: no-repeat;
      background-position: bottom left 5%;
      @media screen and (max-width: 1279px) {
        background: none;
      }
      @media screen and (min-width: 1280px) {
        background-size: 115px;
      }
      @media screen and (min-width: 1366px) {
        background-size: 135px;
      }
      @media screen and (min-width: 1440px) {
        background-size: 160px;
      }
      @media screen and (min-width: 1560px) {
        background-size: 195px;
      }
      @media screen and (min-width: 1680px) {
        background-size: 240px;
      }
      @media screen and (min-width: 1920px) {
        background-size: 320px;
      }
    }
    &-timer {
      text-align: center;
    }
    .pre-event-video {
      width: 100%;
      height: 100%;
      @include flex;
      position: relative;
      .unmute {
        position: absolute;
        right: 6%;
        top: 10px;
        z-index: 2;
        color: $popup-bg;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        span {
          background-image: url("/assets/icons/no_sound.svg");
          background-repeat: no-repeat;
          background-position: center left;
          padding-left: 28px;
        }
      }
    }
  }
  &-label {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    text-align: center;
  }
  &-image {
    border-radius: 15px;
    overflow: hidden;
    width: 90%;
    height: 100%;
    max-height: 380px;
    max-width: 848px;
    padding-bottom: 6px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
