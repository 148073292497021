@import "../../../../styles/index.scss";

.knockJoinEventPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #382e35;
  box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 328px;
  height: 138px;
  top: 86px;
  left: 16px;
  padding: 16px;

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: $purple-4;

    img {
      cursor: pointer;
    }
  }

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    &-avatar {
      display: flex;
      align-items: center;
      min-width: 58px;
      height: 100%;
      &.multiply {
        min-width: 68px;
      }
      .selectedMembers {
        .custom-avatar-image {
          border: none;
        }
        &-imgContainer {
          &-multiply {
            width: 34px;
            height: 34px;
            @for $i from 1 through 3 {
              &:nth-child(#{$i}) {
                top: ($i * 9px - 28px);
              }
            }
          }
        }
      }
    }

    &-description {
      color: #c9c0c6;
      font-size: 13px;
      line-height: 20px;
      &-name {
        max-width: 105px;
        float: left;
        font-weight: bold;
        color: $white-color;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-and {
        float: left;
        color: #c9c0c6;
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
