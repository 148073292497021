@import "../../../styles/variables.module.scss";

.member-image-avatar {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  font-family: $font-heebo;
  font-weight: bold;
  line-height: 0;
  color: $white-color;
  background-color: $cyan;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.empty {
    background-color: $cyan;

    > img {
      width: 50px;
      height: 50px;
    }
  }
}
