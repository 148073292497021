@import "../../../../../styles/index.scss";

.waitingToJoinMember {
  width: 284px;
  height: 145px;
  background: #382e35;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $white-color;
      margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-time {
      font-size: 13px;
      line-height: 20px;
      color: $purple-1;
      margin-left: 8px;
    }
  }

  &-description {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $white-color;
  }

  &-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
}
