@import "../../../../styles/index.scss";
.channelItem {
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  transition: background-color 0.1s;
  * {
    font-family: $font-heebo !important;
  }
  &-container {
    align-items: center;
    display: flex;
    border-bottom: 1px solid $soft-peach-color;
    padding: 24px 0;
    width: 100%;
  }
  &:last-child {
    .channelItem-container {
      border-bottom: none;
    }
  }
  &.active {
    background-color: $tabs-blue-color;
    .channelItem-container {
      border-bottom: none;
    }
  }
  &-image {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    line-height: 48px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    padding-left: 8px;
    &_top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-basis: 100%;
      position: relative;
    }
    &_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &_text {
        flex-shrink: 1;
        margin: 0;
        font-size: 16px;
        line-height: 28px;
        @include truncate-line;
      }
      &_dot {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        background: $red-color;
        border-radius: 50%;
      }
    }
  }
  &-name {
    &-value {
      max-width: 115px;
      font-size: 16px;
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
      overflow: hidden;
      color: $purple-1;
    }
  }
  &-date {
    white-space: nowrap;
  }
  &-new_message {
    min-width: 24px;
    height: 16px;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    padding: 0 4px;
    margin-right: 8px;
    background: $purple-1;
    color: $white-color;
    border-radius: 10px;
    overflow: hidden;
  }
  &-additional {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
    font-size: 16px;
    color: $purple-color;
    flex-shrink: 0;
  }
  &-greeter {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    .greeter {
      color: $orange-color;
      @include flex(space-between, center);
      width: 100%;
      &-title {
        @include truncate-line();
        margin-right: 8px;
        background-color: $blue-1;
        border-radius: 20px;
        padding: 0 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $blue-4;
        max-width: 70px;
      }
    }
    .channelItem-container {
      border: none;
    }
    .custom-avatar-fallback {
      border: 3px solid #f09761;
      box-sizing: border-box;
    }
  }
}
