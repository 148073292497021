@import "../../../../../../styles/index.scss";

.pollControl {
  &-deletePoll,
  &-closePoll {
    .styledDialog-text-title {
      text-transform: none;
    }
    .confirmClosePollBtn {
      &.defaultButtons-confirm {
        background: $orange-1;
      }
    }
    .confirmDeletePollBtn {
      &.defaultButtons-confirm {
        background: $error;
      }
    }
  }

  .divider {
    width: 100%;
    height: 2px;
    background-color: $purple-1;
    margin-top: 16px;
  }

  &-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    &-statusShow {
      display: inline-flex;
      align-items: center;
      height: 24px;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 13px;
      border-radius: 9px;
      padding: 2px 8px;

      &.draft {
        background-color: #695563;
      }

      &.published {
        background-color: #607184;
      }

      &.closed,
      &.scheduled {
        background-color: #9e725d;
      }
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-deleteBtn {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

.pollControlList {
  &-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: #554b52;
    transition: 0.2s;
    border-radius: 50%;
    hr {
      border-radius: 50%;
      border: none;
      width: 3px;
      height: 3px;
      background-color: #c9c0c6;
      margin: 1px;
      transition: 0.2s;
    }
    &.opened {
      background-color: $purple-color;
      hr {
        background: #e7e1e5;
      }
    }
  }
  &-options {
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $popup-bg;
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 100;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.6);
    &-item {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.04em;
      width: 134px;
      text-align: left;
      color: $purple-3;
      box-sizing: border-box;
      padding: 14px 15px;
      transition: 0.2s;
      &.delete {
        color: $error;
      }
      &:hover {
        background: #272025;
      }
    }
  }
}
