@import "../../../../../styles/variables.module.scss";
.benches-gradient {
  width: calc(100% - 96px);
  height: 70px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-image: linear-gradient(180deg, rgba(40, 32, 37, 0) 0%, rgba(40, 32, 37, 0.839) 69.9%, #282025 100%);
  pointer-events: none;
  &.with-stream {
    left: $stream-stream-participants-width;
    width: calc(100% - 440px);
  }
  &.with-side-dialog {
    width: calc(100% - 472px);
    &.with-stream {
      width: calc(100% - 816px);
    }
  }
}

@media screen and (max-height: 960px) {
  .benches-gradient {
    &.with-stream {
      left: $stream-stream-participants-width-medium;
      width: calc(100% - 396px);
    }
    &.with-side-dialog {
      &.with-stream {
        width: calc(100% - 772px);
      }
    }
  }
}
