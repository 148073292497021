@import "../../../../../../styles/variables.module";
@import "../../../../../../styles/mixins";
.stream-relay-input {
  width: 100%;
  &-wrapper {
    @include display-flex-center(flex-start);
    flex-wrap: wrap;
    width: 100%;
    .disabled-text {
      color: $grey-1;
    }
    .styledTextArea {
      width: 538px;
    }
  }
}

.stream-relay {
  .stream-relay-input {
    .label {
      font-size: 13px;
      color: $grey-4;
      margin: 18px 0 5px 16px;
    }
  }
}
