@import "../../../../../styles/variables.module";

.appearance-page {
  display: flex;
  .left-side,
  .right-side {
    flex: 50%;
  }
  .left-side {
    margin-right: 48px;
  }
  .styledTextArea label + .text-field {
    margin-top: 16px !important;
  }
  .landingSettings-domain {
    position: relative;
    .subdomain-value {
      position: absolute;
      right: 30px;
      top: 62px;
      font-size: 18px;
      font-family: Heebo;
    }
    .domain-settings-information-text {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .domain-settings-copy-link {
      &.right-side {
        margin-top: 62px;
      }
      button {
        width: 210px;
        display: flex;
        justify-content: space-between;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: #7b9eb9;
        margin-left: 30px;
        &.disabled {
          pointer-events: none;
          color: #bababa;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    label {
      display: none;
    }
    input {
      max-width: 60%;
    }
  }
}
