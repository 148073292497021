@import "../../../../../styles";

.completeIntroSuccessPopup {
  width: 441px;
  height: 526px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @include flex(space-between, center, column);
  padding: 78px 49px 57px;
  background: radial-gradient(50% 70.87% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(228, 235, 238, 0.2) 100%),
    #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  img {
  }
  border-radius: 10px;
  &-title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 26px;
    color: $black-color;
  }
  &-description {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  &-footer {
    button {
      width: 215px;
    }
  }
}
