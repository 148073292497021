@import "../../../../../../styles/index.scss";
.event-team-step {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }

    .lamp-link {
      display: inline-block;
      width: 18px;
      height: 24px;
      mask: url("/assets/icons/lamp.svg") no-repeat;
      background-color: $purple-color;
    }
  }
  &-content {
    &:focus-visible {
      outline: none;
    }
    .team {
      .greeter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            color: $purple-2;
            margin-left: 8px;
          }
        }
        &-is-admin {
          padding-top: 2px;
          label {
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
      .add-member {
        height: 72px;
        background: $background-color;
        border: 1px solid $pink-color;
        border-radius: $border-radius;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: 0.04em;
        color: $purple-color;
        text-transform: none;
      }
    }
    .greeter-message {
      padding-top: 24px;
      border-top: 2px solid $wisp-pink-color;
      margin-top: 24px;
      position: relative;
      .helper-text {
        font-family: $font-heebo;
        font-size: 13px;
        line-height: 20px;
        color: $error;
        position: absolute;
        top: 100%;
        right: 0;
        bottom: 0;
        padding: 0;
        margin: 0;
        text-align: right;
      }
    }
    .hostNotes {
      border-top: 1px solid #eee6e4;
      margin-top: 32px;
      width: 100%;
      min-height: 300px;

      &-info {
        margin: 32px 0 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        color: $black-color;
      }

      &-label {
        margin: 30px 0 6px 16px;
        font-size: 12px;
        color: $purple-color;
      }

      &-helperText {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $error;
      }

      .rdw-editor-wrapper {
        margin-top: 4px;
        color: #181611;
        background: #f3f3f3;
        border-radius: 10px;

        .rdw-editor-toolbar {
          padding: 0;
          border-radius: 10px 10px 0 0;

          .rdw-option-wrapper {
            background: none;
            border: none;
          }

          .rdw-inline-wrapper,
          .rdw-list-wrapper {
            margin: 5px 0;
          }
        }

        .rdw-editor-main {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          padding: 15px;
          min-height: 180px;

          .public-DraftStyleDefault-block {
            margin: 0;
          }
        }
      }
    }

    .empty-greeters {
      margin: 65px 0 72px;
      text-align: center;
      .message {
        color: #e0e0e0;
        font-size: 18px;
        font-weight: bold;
      }
      .empty-add-member {
        text-transform: none;
        line-height: 23px;
        letter-spacing: 0.04em;
      }
    }
  }
}
