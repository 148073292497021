@import "../../../styles/index.scss";

.orangeButton {
  background: $orange-color;
  border-radius: $border-radius-rounded;
  &-disabled{
    background: $grey-color;
  }
  &-text {
    vertical-align: middle;
    font-family: $font-lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    color: $white-color;
  }
}
