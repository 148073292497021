@import "../../../../../styles/index.scss";
.eventWelcomeDialog {
  font-family: $font-heebo;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: $common-panel-bg;
  font-family: $font-heebo;
  overflow: auto;
  &-card {
    width: 100%;
    padding: 24px 24px;
    background: $popup-bg;
    border-radius: $border-radius;
    box-sizing: border-box;
    margin-bottom: 16px;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    &-body {
      margin-bottom: 24px;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-duration {
      font-size: 13px;
      line-height: 25px;
      color: $white-color;
      margin: 0;
    }
  }
  .greeter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 25px;
    &-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-fallback {
        font-family: $font-lora;
        width: 100%;
        height: 100%;
        border: 3px solid $orange-color;
        box-sizing: border-box;
        background-color: $orange-2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
        border-radius: 50%;
        font-weight: bold;
        font-size: 14px;
        line-height: 120%;
        text-transform: uppercase;
      }
    }
    &-title {
      margin-right: 4px;
      color: $white-color;
    }
    &-subtitle {
      color: $purple-1;
    }
    &-message {
      color: $white-color;
    }
  }
}

@media screen and (max-width: 1160px) {
  .eventWelcomeDialog {
    &-card {
      padding: 12px;
    }
    .dialog-card {
      padding: 12px;
      .productTour-title {
        font-size: 14px;
        line-height: 18px;
      }
      .productTour-btn {
        width: 70px;
      }
    }
  }
}