@import "../../../../../../styles/variables.module";
.event-stream-step {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
    .lamp-link {
      display: inline-block;
      width: 18px;
      height: 24px;
      mask: url("/assets/icons/lamp.svg") no-repeat;
      background-color: $purple-color;
    }
    .error {
      color: $red-color;
    }
  }
  &-content {
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: $blue-color;
      text-align: center;
    }
    .stream-type-radio {
      flex-direction: row;
      margin-bottom: 24px;
      label {
        margin: 0;
      }
    }
  }
  .video-options {
    margin-left: -9px;
    .option {
      margin: 3px 0;
    }
  }
}
