@import "../../../../../../styles/index.scss";

.pollResults {
  &-item {
    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-text {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }

      &-data {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $white;
        width: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        img {
          margin-right: 10px;
        }
      }
    }

    &-stripe {
      .totalStripe {
        width: 100%;
        height: 8px;
        background-color: #282025;
        margin: 10px 0;
        border-radius: 4px;

        &-fill {
          height: 100%;
          background-color: $orange-1;
          border-radius: 4px;
          margin-right: 5px;
        }
      }
    }
  }
}
