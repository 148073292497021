@import "../../../../../../styles/variables.module.scss";
@import "../../../../../../styles/mixins.scss";
.roomsStep-wrapper {
  .validation-error {
    color: $error;
    margin-bottom: 30px;
  }
  .step-title {
    font-family: $font-heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #1d1d1d;
  }
  .add-step {
    display: flex;
    align-items: center;
    text-transform: none;
    line-height: 23px;
    letter-spacing: 0.04em;
    img {
      margin-right: 16px;
    }
  }
  .room-size {
    order: 1;
    margin-bottom: 16px;
  }
  .empty-rooms {
    margin: 65px 0 72px;
    text-align: center;
    .message {
      color: #e0e0e0;
      font-size: 18px;
      font-weight: bold;
    }
    .add-step {
      justify-content: center;
      width: 100%;
    }
  }
  .classroom-add-wrapper {
    width: 100%;
    order: 3;
    margin-top: 50px;
    .classroom {
      margin-bottom: 32px;
      .item {
        margin-bottom: 24px;
      }
      .class-room-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .title {
          font-family: $font-heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          display: flex;
          align-items: center;
          color: #1d1d1d;
        }
        .remove-button {
          cursor: pointer;
          img {
            margin-right: 8px;
          }
          font-family: $font-heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          /* identical to box height */
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: right;
          letter-spacing: 0.04em;
          text-transform: capitalize;
          /* Misc. / Error */
          color: $error;
        }
      }
    }
  }
  .auditoriumRooms {
    &-control {
      &-radio {
        margin-bottom: 16px;
        position: relative;
        @include flex(flex-start);
        column-gap: 5px;
        width: 320px;
        .text-field {
          margin: 0 !important;
          padding: 2px 10px;
          width: 54px;
          .textarea {
            text-align: center;
          }
        }
        .text-label {
          width: 250px;
          position: absolute;
          left: 50px;
          top: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: #181611 !important;
          cursor: pointer;
        }
        .helper-text {
          width: max-content;
          left: 0;
        }
      }
      .disabled {
        .text-label {
          color: $grey-3 !important;
        }
        .text-field {
          color: transparent;
        }
        .helper-text {
          display: none;
        }
      }
      &-description {
      }
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: $grey-4;
      width: 320px;
    }
  }
  .lobbyRoomsControl {
    @include flex(flex-start, center);
    padding-bottom: 72px;
    height: 60px;
    .custom-text-field {
      width: 100%;
      .styledTextArea {
        width: 100%;
        .text-field {
          width: 64px;
          margin-top: 0 !important;
        }
        .text-label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 240px;
          height: 40px;
          font-size: 14px;
          line-height: 160%;
          color: #181611 !important;
          margin: 0;
          transform: translate(84px) scale(1) !important;
          &-description {
            position: absolute;
            top: 60px;
            width: 325px;
            right: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            color: $grey-4;
          }
        }
        .helper-text {
          width: 200px;
          top: 35px;
          left: 84px;
          text-align: left;
        }
      }
    }
    &.withError {
      .textarea {
        color: $error;
      }
    }
  }
  .custom-text-field-counter {
    font-size: 13px;
    line-height: 20px;
    color: $grey-4;
  }
}
