@import "../../../../styles/index.scss";
.videoStream {
  height: 60%;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  &-container {
    position: relative;
    width: 100%;
    display: flex;

    .text {
      position: absolute;
      top: 44%;
      right: calc((50% - 141px));
      font-style: normal;
      color: $white;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-welcome {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
      }

      &-description {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      &.isMobile {
        top: 35%;
      }
    }

    .blurScreen {
      position: absolute;
      z-index: 1;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      filter: blur(20px);
      backdrop-filter: blur(20px);
      width: 100%;
      height: 100%;
    }

    .unmute {
      position: absolute;
      z-index: 2;
      left: calc(50% - 67px);
      top: 58%;
      background-color: transparent;
      border: 2px solid $white;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
      color: $white;
      animation-name: playVideoBtn;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      @keyframes playVideoBtn {
        50% {
          transform: scaleX(1.05) scaleY(1.05);
        }
        100% {
          transform: scaleX(1) scaleY(1);
        }
      }

      span {
        background-image: url("/assets/icons/play_video.svg");
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 28px;
      }
      &.isMobile {
        top: 67%;
        animation: none;
        transform: scaleX(1.05) scaleY(1.05);
      }
    }

    .sync-video {
      font-size: 13px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
      color: #382E35;
      @include flex;

      .refresh-icon {
        margin-right: 8px;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
    &.ended::after,
    &.paused::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      pointer-events: none;
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 64px 64px;
      background-image: url("/assets/icons/play.svg");
    }
    &.paused::after {
      bottom: 46px;
    }
    &-loading {
      opacity: 0;
      visibility: hidden;
    }
  }
  .player {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .fb-video {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
