@import "../../../styles/variables.module";

.styledMultiSelect {
  display: inline-block;
  position: relative;
  font-family: $font-heebo;
  font-size: 14px;
  line-height: 180%;
  color: $black-color;

  &-dropButton {
    position: absolute;
    right: 0;
    display: inline-block;
    padding-top: 16px;
    padding-left: 10px;
    cursor: pointer;
  }

  &-title {
    font-size: 12px;
    color: $grey-color;
    line-height: 130%;
  }

  &-text {
    font-size: 14px;
    display: inline-block;
    border: none;
    height: 32px;
    font-family: $font-heebo;
    margin-left: 0;
    text-align: left;
    width: calc(100% - 25px);
    white-space: nowrap;
    cursor: pointer;
    outline: unset;

    &-focus {
      outline: none;
    }
  }

  &-error {
    position: absolute;
    color: $red-color;
    font-size: 12px;
  }

  &-container {
    border-bottom: 1px solid $light-grey-color;
    transition: border-color 0.2s linear;

    &-error {
      border-bottom: 2px solid $red-color;
    }

    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  &-selectedItems {
    flex-wrap: wrap;
    display: flex;
  }
}

.companyDialogSelect {
  .styledMultiSelect {
    &-container {
      margin-left: 136px;
    }

    &-selectedItems {
      margin-top: 8px;
    }
  }
}
