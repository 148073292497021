@import "../styles.scss";

.meeting-records {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: -14px 0 24px;
  max-height: 310px;
  overflow-y: scroll;

  &-date {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $grey-1;
    margin-bottom: 8px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    min-height: 41px;
    background: $blue-4;
    border-radius: 8px;
    margin: 0 0 8px;
    cursor: pointer;
    text-decoration: none;
    &-name {
      display: flex;
      align-items: center;
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
      img {
        margin: 0 13px 0;
      }
    }
    &-duration {
      margin-right: 16px;
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: $purple-2;
    }
  }
}
.styledDialog {
  &-body {
    width: 480px;
  }
}
