@import "../../../../../../styles/index";

.altar-video-manager {
  margin: 32px 0 16px;

  .video-in-progress {
    @include display-flex-center;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.04em;
    color: $grey-4;
    position: relative;

    &.error {
      color: $error;
    }
  }

  .replace-video {
    border-width: 2px !important;
  }

  .altar-video-upload {
    height: 150px;
    background-color: $blue-4;
    border-radius: $border-radius;
    padding-bottom: 27px;
    box-sizing: border-box;

    &-inner {
      height: 100%;
      @include display-flex-center(flex-end);
      flex-direction: column;
      color: $blue-1;
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;

      span {
        margin-top: 17px;
      }
    }

    &.active {
      background-color: $blue-3;
    }
  }

  .video-status {
    @include flex(flex-start);
    margin: 24px 0;
    font-family: $font-heebo;
    font-size: 13px;
    line-height: 20px;
    color: $grey-1;

    .mark {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      flex-shrink: 0;
    }
  }
}
