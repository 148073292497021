@import "../../../../styles/index.scss";
.customChannelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 18px;
  background: $white-color;
  box-shadow: $card-shadow;
  min-height: 72px;
  z-index: 1;
  &-info {
    display: flex;
    align-items: center;
    &.clickable {
      cursor: pointer;
    }
    .avatar {
      margin-right: 8px;
      &-online {
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 9px;
          height: 9px;
          position: absolute;
          top: 80%;
          right: 0;
          background: $green-color;
          border: 1px solid $white-color;
          box-sizing: border-box;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
  &-data {
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    &-name,
    &-status {
      margin: 0;
      color: $black-color;
    }
    &-name {
      font-family: $font-heebo !important;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: $blue-1;
      text-transform: capitalize;
      margin-right: 8px;
    }
    &-status {
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;
      color: $grey-color;
    }
  }
  &-actions {
    flex: 1;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      cursor: pointer;
    }
    .customChannelHeader &-close {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba($color: $red-color, $alpha: 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 10px;
        height: 10px;
        object-fit: cover;
      }
    }
    &-leave {
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $red-color;
      cursor: pointer;
      outline: none;
      background: transparent;
      border: none;
      padding: 0;
    }
    &-groupActionsContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      &>img,
      >button,
      >div {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
    &-searchInput {
      width: 100%;
      max-width: 256px;
      &::before {
        top: unset;
      }
      &-input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        border: 1px solid $light-grey-color;
        &::placeholder {
          color: $grey-color;
        }
      }
      &-clear {
        &:before,
        &:after {
          background: $input-label-color !important;
        }
      }
    }
  }
  &-back {
    padding: 0;
    margin-right: 8px;
  }
  &.stream {
    display: none;
  }
}