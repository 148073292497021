@import "../../../../../styles";

.createEventCard {
  &:hover {
    .createEventCard__frontCard {
      display: none;
    }
    .createEventCard__backCard {
      display: flex;
    }
  }
  width: 368px;
  height: 272px;
  background: #ffffff;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-right: 25px;
  color: $dark-purple;

  &__backCard,
  &__frontCard {
    @include flex(center, center, column);
    height: 272px;
    position: relative;
    .cardBadge {
      @include flex();
      position: absolute;
      right: 0;
      top: 0;
      margin: 10px;
      width: 81px;
      height: 16px;
      background-color: $orange-1;
      border-radius: 2px;
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 100%;
      color: $white-color;
    }
    .cardTitle {
      @include flex();
      height: 64px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
    }
    .cardDescription {
      text-align: center;
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    .cardAction {
      @include flex();
      margin-top: 30px;
    }
    img {
      width: 368px;
      height: 211px;
    }
  }
  &__backCard {
    display: none;
  }

  &--disabledCard {
    filter: grayscale(80%);
    color: $grey-3;
  }
  &--disabledMeeting {
    color: $grey-3;
    .createEventCard__frontCard,
    .createEventCard__backCard {
      .cardTitle,
      .cardDescription,
      img {
        filter: grayscale(80%);
        color: $grey-3;
      }
    }
  }
}
