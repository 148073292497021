@import "../../../../../styles/variables.module.scss";
@import "../../../../../styles/mixins.scss";
.event-members-counter-wrapper {
  @include flex();
  width: 178px;
  position: fixed;
  bottom: 0;
  left: calc(50% - 50px);
  transform: translateX(-50%);
  background: #7b9eb9;
  border-radius: 20px 20px 0 0;
  z-index: 4;
  padding: 8px 0;
  cursor: pointer;
  &.leftSideBar {
    left: calc(50% + 126px);
  }
  &.rightSideBar {
    left: calc(50% - 238px);
  }
  &.bothSideBars {
    left: calc(50% - 62px);
  }
  .event-members-counter-icon {
    @include backgroundImage("/assets/icons/people_icon_small_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 14px;
    margin-right: 10px;
  }
  .event-members-counter-label {
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-heebo;
  }
}

@media screen and (max-height: 960px) {
  .event-members-counter-wrapper {
    &.leftSideBar {
      left: calc(50% + 100px);
    }
    &.bothSideBars {
      left: calc(50% - 88px);
    }
  }
}

@media screen and (max-width: 1160px) {
  .event-members-counter-wrapper {
    left: calc(50% - 42px);
    &.leftSideBar {
      left: calc(50% + 82px);
    }
    &.rightSideBar {
      left: calc(50% - 182px);
    }
    &.bothSideBars {
      left: calc(50% - 56px);
    }
  }
}
