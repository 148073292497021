@import "../../../../styles/index";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.module.scss";

%figure-shared {
  position: absolute;
  z-index: 0;
}

.auth-wrapper {
  min-height: 100vh;
  width: 100%;
  background: $white-2;
  box-sizing: border-box;
  position: relative;
  @include display-flex-center;
  flex-grow: 1;
  flex-direction: column;
  z-index: 1;
  &-header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 32px 40px;
    @include mobile($mobile-width) {
      width: 100%;
      @include flex();
      position: relative;
    }
  }
  &-figures {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    &-circle {
      @extend %figure-shared;
      top: -70px;
      left: 270px;
    }
    &-polygon {
      @extend %figure-shared;
      top: 0;
      right: 160px;
      transform: rotate(-90deg);
    }
    &-arch {
      @extend %figure-shared;
      left: 142px;
      bottom: 106px;
    }
    &-square-polygon {
      @extend %figure-shared;
      transform: scale(0.65);
      right: 16px;
      bottom: 16px;
    }
  }
}

@media screen and (max-width: 700px) {
  .auth-wrapper {
    &.with-padding {
      padding: 74px 24px 0 23px;
    }
    &-header {
      padding: 32px 24px;
    }
    &-figures {
      &-circle {
        left: 50px;
      }
      &-polygon {
        right: 0;
      }
      &-arch {
        left: 10px;
      }
    }
  }
}
