.faq-wrapper {
  .faq-title{
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #181611;
    margin-bottom: 24px;
  }
  .faq-content {
    margin-top: 32px;
    .faq-item {
      margin-bottom: 24px;

      .item-title {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }

        span {
          font-family: Lora;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 24px;
          color: #000000;
        }

        margin-bottom: 24px;
      }

      .item-content {
        margin-top: 8px;
        margin-left: 44px;
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #403E3E;
      }
    }
  }
}