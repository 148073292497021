@import "../../../../styles/index.scss";

.specificRoomAlert {
  width: 528px !important;

  .defaultButtons {
    &-confirm {
      &.disabled {
        background-color: $purple-2;
        cursor: not-allowed;
      }
    }
  }
  &-content {
    padding: 0 24px;
    &-roomList {
      display: flex;
      flex-direction: column;
      max-height: 288px;
      width: 465px;
      overflow-y: scroll;

      &-error {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $error;
        margin-bottom: 10px;
      }

      &-label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-name {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
          margin-left: 15px;
          max-width: 362px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-membersCount {
          margin-left: 10px;
          color: $purple-2;
        }
      }
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-4;
      margin: 16px 0;
    }
  }

  .styledDialog-text-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-transform: none;
    margin-bottom: 10px;
  }

  .MuiFormControlLabel-root {
    margin-left: -9px;
    .styledRadio-icon {
      background: url("/assets/icons/radio_icon_grey.svg");
      &.styledRadio-checkedIcon {
        background: url("/assets/icons/radio_icon_checked.svg");
      }
    }
  }
}
