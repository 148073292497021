@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins.scss";

.table {
  @include flex(flex-start, center, column);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  &-data {
    width: 100%;
    height: 56px;
    @include flex(space-around);
    border-top: $grey-6 solid 2px;
    background-color: $white-color;
    &:first-child {
      background-color: $blue-3;
      border: none;
      color: $blue-1;
      font-weight: 500;
    }
    &-number {
      width: 40px;
      margin-left: 30px;
    }
    &-date {
      width: 150px;
    }
    &-time {
      width: 180px;
    }
    &-name {
      width: 480px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
    }
    &-type {
      margin: 0 32px;
      width: 100px;
      text-align: center;
      &.event {
        background-color: $beige-4;
        color: $beige-1;
        border-radius: 8px;
      }
      &.meeting {
        background-color: $blue-4;
        color: $blue-1;
        border-radius: 8px;
      }
    }
    &-attenders {
      width: 168px;
    }
  }
}
