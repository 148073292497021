@import "../../../../styles/index.scss";
.eventHeader {
  font-family: $font-heebo;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  min-height: $stream-header-hight;
  background: $nav-bg;
  padding: 12px 26px;
  z-index: 500;
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  &-logo {
    min-width: 112px;
    margin-right: 56px;
    img,
    svg {
      cursor: pointer;
    }
  }
  &-title {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    &-event {
      font-size: 16px;
      line-height: 26px;
      color: $purple-4;
    }
    &-community {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: $purple-2;
    }
  }
  &-nav-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    .eventHeader-nav-item {
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      button {
        padding: 0;
        font-size: 13px;
        letter-spacing: 0.04em;
      }
      &.copy-url-btn {
        span {
          padding-left: 27px;
          background: url("/assets/icons/link_orange.svg") no-repeat center left;
        }
      }
      &.golive-btn {
        span {
          padding-left: 25px;
          background: url("/assets/icons/golive.svg") no-repeat center left;
        }
      }
      &.admin-menu {
        .dropDown-optionsList {
          max-height: 285px;
        }
        width: 155px;
        .end-event {
          display: flex;
          align-items: center;
          border-top: #5b4a56 1px solid;
          padding-left: 50px;
          color: $error;
          &:first-child {
            border-top: none;
          }
          &::before {
            content: "";
            position: absolute;
            left: 24px;
            width: 15px;
            height: 18px;
            background: url("/assets/icons/exit_icon_red.svg") center center no-repeat;
          }
        }
        .record {
          display: flex;
          align-items: center;
          padding-left: 50px;
          border-top: #5b4a56 1px solid;
          &::before {
            content: "";
            position: absolute;
            left: 16px;
            width: 24px;
            height: 24px;
            background: url("/assets/icons/record_dot_white.svg") center no-repeat;
          }
          &:hover {
            &::before {
              background: url("/assets/icons/record_dot_grey.svg");
            }
          }

          &.disabled {
            color: $grey-2;
            cursor: not-allowed;
            &:hover {
              color: lighten($black-color, 4);
            }
            &::before {
              background: url("/assets/icons/record_dot_grey.svg");
            }
          }
        }
        .followMe {
          display: flex;
          align-items: center;
          padding-left: 50px;
          border-top: #5b4a56 1px solid;
          &::before {
            content: "";
            position: absolute;
            left: 16px;
            width: 24px;
            height: 24px;
            background: url("/assets/icons/pin_white.svg");
          }
          &:hover {
            &::before {
              background: url("/assets/icons/pin_grey.svg");
            }
          }
          &.enabled {
            &:hover {
              &::before {
                background: url("/assets/icons/pin_crossed_grey.svg");
              }
            }
            &::before {
              background: url("/assets/icons/pin_crossed_white.svg");
            }
          }
        }
      }
      .stream-user-profile {
        bottom: unset;
        left: unset;
        right: 36px;
        top: 68px;
        box-shadow: 0px 6px 30px 10px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        .close {
          display: none;
        }
      }
      &.help-btn {
        button {
          color: $white-color;
          @include display-flex-center(space-between);
        }
        .styledLink {
          width: 58px;
          @include display-flex-center(space-between);
          color: $white-color;
          text-transform: none;
          font-size: 13px;
        }
      }
      .login-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 16px;
        line-height: 23px;
        img {
          margin-right: 14px;
        }
      }
    }
  }
  &-countdown-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      margin: 0;
    }
    .countdown-timer {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: $white-color;
      letter-spacing: initial;
      margin: 0;
    }
  }
  &-tooltip.eventTooltip {
    .MuiTooltip-tooltip {
      &.MuiTooltip-tooltipPlacementBottom {
        .MuiTooltip-arrow {
          left: unset !important;
          right: 5%;
        }
      }
    }
    &.golive {
      left: -42px !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .eventHeader {
    &-nav-items {
      .eventHeader-nav-item {
        margin-right: 10px;
      }
    }
  }
}
