@import "../../../../../styles/index.scss";
.event-blocker {
  width: 100%;
  @include flex(center, center, column);
  &__image {
    @include flex();
    width: 136px;
    height: 136px;
    border-radius: 50%;
    background-color: #382e35;
    margin-bottom: 32px;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 48px;
    color: $purple-2;
    margin-bottom: 16px;
  }
  &__description {
    width: 726px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: $purple-2;
    margin-bottom: 32px;
    text-align: center;
  }
  &__actions {
    @include flex(center, center, column);
    gap: 32px;
  }
}
