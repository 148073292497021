@import "../../../../styles/index.scss";
.recording {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin: 16px 0 0 16px;

  &-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 199px;
    height: 37px;
    background: $grey-1;
    border: 1px solid $grey-2;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    color: $white-color;
    margin-right: 4px;

    img {
      margin: 0 15px;
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: blink normal 2s infinite ease-in-out;
    }
  }

  &-stop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    background: $grey-1;
    border: 1px solid $grey-2;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
  }
}
