@import "../../../styles/index.scss";

.background {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 0 24px 32px;
    box-sizing: border-box;
    overflow: auto;
  }
  &-text {
    font-size: 18px;
    line-height: 28px;
    color: $black-color;
    &-description {
      color: $grey-color;
    }
  }
  &-color {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
  &-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    &-uploaded {
      width: 100%;
      height: auto;
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      overflow: hidden;
      &-remove {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-image.withImg{
    margin-bottom: 16px;
  }
  &-image-selector {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.1em;
    cursor: pointer;
    text-transform: uppercase;
    color: $blue-color;
  }

  &-reset{
    margin: 0 72px 0 0!important;
    min-width: 100px!important;
  }
}
