@import "../../../../../styles/index.scss";
.eventAuthDialog {
  font-family: $font-heebo;
  width: 100%;
  height: 100%;
  background-color: $common-panel-bg;
  overflow: auto;
  box-sizing: border-box;
  color: $white-color;
  &.embedded {
    padding-top: 6px;
  }
  &-container {
    height: 100%;
    form {
      height: 100%;
    }
  }
  &-description {
    font-size: 16px;
    line-height: 28px;
    color: $blue-4;
    margin: 0;
    p {
      margin: 0;
    }
  }
  &-email,
  &-password {
    margin-bottom: 24px;
    .text-label {
      margin-left: 0;
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      color: $grey-3 !important;
    }
    .textarea {
      font-size: 16px !important;
      line-height: 24px;
      color: $blue-4;
    }
    .text-field {
      border-radius: 20px;
      background-color: $popup-bg;
    }
    .helper-text {
      width: 100%;
      color: $error;
      font-size: 13px;
      line-height: 28px;
    }
    input::-ms-reveal {
      display: none;
    }
  }
  &-email {
    .eventAuthDialog-description {
      margin: 24px 0;
    }
  }
  &-password {
    .eventAuthDialog-description {
      margin-bottom: 24px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .proceed {
      font-size: 13px;
      line-height: 13px;
      height: 40px;
      border-radius: 9px;
    }
    button {
      height: 40px;
    }
  }
  &-forgot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }
  &-forgotBtn {
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: $purple-3;
    text-decoration: none;
  }
  &-error-container {
    margin-bottom: 24px;
    .eventAuthDialog-error {
      font-size: 13px;
      line-height: 25px;
      color: $error;
    }
  }
  .social-media-buttons-wrapper {
    margin-top: 32px;
    .social-button {
      margin-bottom: 24px;
      button {
        color: $white-color;
      }
    }
  }
  .divider {
    height: 26px;
    position: relative;
    .text {
      text-align: center;
      width: 40px;
      margin: 0 auto;
      span {
        position: absolute;
        font-size: 16px;
        line-height: 26px;
        font-weight: bold;
        color: $purple-1;
        background: $common-panel-bg;
        z-index: 1;
        width: 30px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #382e35;
      top: 12px;
    }
  }
  .event-auth-login {
    &-magic {
      .eventAuthDialog-description {
        margin-bottom: 16px;
      }
    }
    .divider {
      margin: 40px 0;
    }
  }
}
