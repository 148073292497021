@import "../../../../../../styles/index.scss";

.pollVoting {
  .MuiFormControlLabel-root {
    margin-left: -9px;
    .MuiFormControlLabel-label {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $white;
    }
    .styledRadio-icon {
      background: url("/assets/icons/radio_icon_grey.svg");
      &.styledRadio-checkedIcon {
        background: url("/assets/icons/radio_icon_checked.svg");
      }
    }
    .styledCheckBox-icon {
      background-color: rgba(255, 255, 255, 0.1);
      &.withBorder {
        border-color: $purple-3;
      }
    }
  }
  &-voteBtn {
    margin: 10px 0;

    &:disabled {
      background-color: #93808d;
      cursor: not-allowed;
    }
  }
}
