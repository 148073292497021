@import "../../../../../styles/index.scss";
.eventEnded {
  display: flex;
  font-family: $font-heebo;
  color: $purple-2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
    margin-top: -40px;
    &-records {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 27px;
    line-height: 48px;
    color: $purple-2;
    margin: 0 0 8px;
  }
  &-description {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    color: $purple-2;
    margin: 0;
  }
  .eventEnded-url {
    @include display-flex-center;
    margin-bottom: 10px;
    &-btn {
      font-weight: bold;
      font-size: 16px;
      line-height: 32px;
      text-transform: none;
      flex-wrap: nowrap;
      max-width: 360px;
      @include truncate-line;
    }
    &::after {
      content: url(/assets/icons/arrow_right_orange.svg);
      height: 24px;
      margin-left: 10px;
    }
  }
  &-download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    img {
      margin-right: 14px;
    }
  }

  &-image {
    border-radius: 15px;
    overflow: hidden;
    width: 90%;
    height: 100%;
    max-width: 848px;
    height: 350px;
    padding-bottom: 6px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
