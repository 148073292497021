@import "../../../../../styles/variables.module";

.table-rename-popup {
  padding: 0 24px 24px;
  width: 100%;
  box-sizing: border-box;

  .text-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.04em;
    margin-left: 0;
  }

  .text-field {
    background: #282025;
    border-radius: 20px;

    input {
      font-size: 16px;
      line-height: 24px;
      color: $blue-4;
    }
  }
}
