@import "../../../styles/index.scss";

.deleteUser {
  &-description {
    font-size: 13px;
    color: $grey-4;
    margin-top: 8px;
  }
}

.deleteUserAlert {
  &-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    &-communitiesList {
      margin: 16px 0;
      @include flex(center, flex-start, column);
      padding-inline-start: 25px;
      &-item {
        max-width: 100%;
        text-transform: none;
        margin: 4px 0 !important;
        font-weight: 700;
        padding-left: 20px;
        color: $blue-1 !important;
        @include truncate-line();
      }
    }
    &-changeMainContact {
      text-transform: none;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 13px;
      position: relative;
      padding-left: 25px;
      color: $blue-1 !important;

      &:before {
        content: "";
        mask: url("/assets/icons/link2.svg") no-repeat;
        mask-size: contain;
        background-color: $blue-1;
        left: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        transform: rotate(77deg) skew(-14deg);
      }
    }
  }
}
