@import "../../../../../styles/index.scss";

.pollsDialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: $white;

  &-content {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 0 0;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;

    &-poll {
      background-color: #382e35;
      border-radius: 10px;
      margin-bottom: 16px;
      padding: 16px;

      .pollDescription {
        cursor: pointer;

        &-title {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          color: $purple-4;
          margin-bottom: 18px;
          span {
            font-size: 13px;
            color: $purple-3;
          }
        }

        &-createInfo {
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 13px;
          color: $purple-2;
          margin-bottom: 18px;
        }

        &-question {
          font-family: Heebo;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $white;
          margin-bottom: 18px;
        }
      }
    }

    &-noPolls {
      @include flex(center);
      flex-direction: column;
      align-items: center;
      margin: auto 0;

      &-img {
        @include display-flex-center;
        align-self: center;
        width: 136px;
        height: 136px;
        min-height: 136px;
        border-radius: 50%;
        overflow: hidden;
        background: $popup-bg;
      }

      &-text {
        font-family: $font-heebo !important;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: $purple-1;
        text-align: center;
        margin: 8px 0 0;
      }
    }
  }

  &-addBtn {
    margin: 10px 0;
  }
}
