@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";

.confirmDeleteAnnouncementTemplateBtn {
  background: $error !important;
}

.announcementTemplate {
  width: 100%;
  background-color: $blue-4;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 24px 0 0;
  box-sizing: border-box;
  &-title {
    padding: 0 24px;
    margin-bottom: 10px;
    &-label {
      @include flex(space-between);
      img {
        margin: 0 9px;
        cursor: pointer;
      }
      &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $grey-1;
    }
  }
  &-description {
    padding: 0 24px;
    margin-bottom: 10px;
    &-label {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $grey-3;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
  &-type {
    padding: 0 24px;
    margin: 14px 0;
    &-content {
      @include flex();
      border-radius: 9px;
      padding: 2px 8px;
      width: fit-content;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $white-color;
      background-color: $orange-1;
    }
  }
  &-button {
    padding: 10px 24px;
    margin: 20px 0 0;
    background-color: #e4ebf0;
    border-radius: 0 0 10px 10px;
    &-text,
    &-link {
      &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-3;
      }
      &-content {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1;
      }
    }
    &-link {
      margin-top: 10px;
      &-content {
        color: $blue-1;
      }
    }
  }
}
