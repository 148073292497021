@import "../../../../styles/index.scss";

.breakout-room-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .videos-wrapper {
    height: calc(100% - 96px);
    position: relative;
    background-color: #191317;

    .video-list-container {
      width: 100%;
      max-height: fit-content;
      position: relative;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center center;
      box-sizing: border-box;

      .table-name {
        font-weight: bold;
        font-size: 28px;
        color: $purple-color;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .footer {
    width: 100%;
    height: 96px;
    position: absolute;
    bottom: 0;
    background-color: $nav-bg;
    @include display-flex-center();
  }

  .collapse-videos {
    position: absolute;
    top: 26px;
    right: 24px;
    cursor: pointer;
    font-size: 14px;
    color: #dbdbdb;
    letter-spacing: 0.04em;
    font-weight: bold;
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 30px;
      background-color: #dbdbdb;
      mask-image: url("/assets/icons/closed_arrow.svg");
      mask-repeat: no-repeat;
      mask-size: contain;
      transform: rotate(90deg);
      position: absolute;
      left: -40px;
      top: -7px;
    }
  }
}
