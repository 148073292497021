@import "../../../../styles/index.scss";
.emptyChatState {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100px;
  &-image {
    @include display-flex-center;
    width: 136px;
    height: 136px;
    min-height: 136px;
    border-radius: 50%;
    overflow: hidden;
    background: $blue-4;
    &.dark {
      background: $popup-bg;
    }
  }
  &-title {
    font-family: $font-heebo !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $blue-2;
    text-align: center;
    margin: 8px 0 0;
  }
  &.stream {
    .emptyChatState-image {
      background: $popup-bg;
    }
    .emptyChatState-title {
      color: $purple-1;
    }
  }
}