@import "../../../../styles/variables.module";

.community {
  min-height: 100vh;
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;

  &-overlay {
    z-index: 200;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: $semitransparent-color;
    height: 100vh;

    &-companiesBody {
      background-color: transparent !important;
    }
  }

  &-panel {
    width: 304px;
    background: $blue-color;
  }

  .searchInputContainer {
    background: none;

    &-input {
      input {
        height: 40px;
        font-size: 14px;
        width: 256px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(236, 236, 236, 0.3);
        color: $white-color;
        padding: 8px 16px;
        outline: none;
        letter-spacing: 0.02em;
        font-family: Apercu, sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 180%;
        display: flex;
        align-items: center;

        &::placeholder {
          color: $white-color;
        }

        &:hover, &:focus {
          background: none;
        }
      }
    }
  }

  .createCommunity {
    width: 256px;
    padding-top: 22px;

    &-link {
      display: flex;
      align-items: center;
      color: $gold-color;

      &-icon {
        margin-right: 8px;
        height: 16px;
      }

      &-text {
        display: flex;
        align-items: center;
        height: 16px;
        font-family: $font-heebo;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  .communitiesList {
    width: 99%;
    text-align: left;
    align-items: start;
    font-size: 16px;
    height: calc(100vh - 150px);
    min-height: 200px;
    overflow: auto;
    padding: 0;
    margin-top: 16px;

    &-container {
      width: 100%;
    }
  }

  &-companies-body {
    width: 100%;
    padding: 0 25px;
    background-color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: $light-grey-color;
  }
}

@media screen and (max-width: 768px) {
  .community {
    &-panel {
      width: 100%;
    }

    &-companies-body {
      display: none;
    }

    .searchInputContainer-input {
      width: 100%;
      max-width: unset;
      input {
        width: 100%;
      }
    }
    .createCommunity {
      width: 100%;
    }
  }
}
