@import "../../../../../styles/variables.module";

.communityDialog {
  .sideDialog {
    &-header {
      padding: 24px 24px 16px;
      margin: 0;
      border-bottom: none;
      background-color: $white-color;
    }
    &-title {
      font-family: $font-heebo;
      font-size: 16px;
      line-height: 26px;
      color: $grey-2;
    }
    &-body {
      background-color: #fcfafa;
      width: 344px;
    }
    &-container {
      padding: 0;
      margin: 0;
    }
    &-footer-actions {
      background-color: $white-color;
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
      padding: 12px 24px;
      &-cancel {
        color: $blue-1;
      }
      &-confirm {
        color: $blue-1;
        border: 1.5px solid $blue-1;
        border-radius: $border-radius;
        padding: 0 16px;
      }
    }
    &-header-actions button,
    &-footer-actions button {
      font-family: $font-heebo;
      text-transform: capitalize;
      letter-spacing: 0.04em;
    }
  }
}
