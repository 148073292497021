@import "../../../../styles/index.scss";

.preJoinPage {
  display: flex;
  flex-flow: row wrap-reverse;
  align-items: center;
  justify-content: center;
  height: 670px;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-blocked-audio {
    .styledDialog-text {
      padding: 32px 24px 0;
    }
  }

  &-settings {
    width: 420px;
    height: 264px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &-title,
    &-name {
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 170%;
      color: $white-color;
      text-overflow: ellipsis;
    }

    &-dropDown {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;

      .dropDown {
        width: 202px;
        height: 48px;
        background: #191317;

        &-option {
          &:hover {
            background: none;
            color: inherit;
          }
        }

        &-optionsList {
          width: 202px;
        }

        &-selectedOpt,
        &-selectedOpt:hover {
          background-color: $grey-6;
          color: $grey-1;
        }

        &-selectedName {
          display: flex;
          align-items: center;
          font-family: Heebo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          color: $white-color;
          img {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    &-join {
      &.requestedJoin {
        button {
          cursor: not-allowed;
          background-color: $purple-2;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &-video {
    position: relative;
    width: 470px;
    height: 264px;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.1);
    margin-left: 40px;

    &-mute {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      &-mic,
      &-camera {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background-color: #382e35;
        opacity: 0.7;
        border-radius: 50%;
        z-index: 1;
        margin: 8px;
        cursor: pointer;

        &.micMuted,
        &.cameraMuted {
          background-color: $error;
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scaleX(-1);
    }

    &-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: $grey-1;
      .custom-avatar-fallback {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 58px;
      }
    }
  }
}
