@import "../../../styles/variables.module";

.searchSelect {
  width: 100%;
  position: relative;

  &-title {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $purple-color !important;
    text-transform: capitalize;
    margin: 0 0 0 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-required {
    color: $grey-color;
    font-size: 13px;
  }

  &-inputWrap {
    position: relative;
    display: inline-flex;
    width: 100%;
    box-sizing: border-box;
    font-family: $font-heebo !important;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $dark-blue;
    border-radius: $border-radius;
    padding: 6px 25px 6px 16px;
    min-height: 40px;
    mix-blend-mode: normal;
    background-color: $input-background;
    margin-top: 30px;

    &-input {
      font-family: $font-heebo;
      font-size: 16px;
      outline: none;
      border: none;
      width: 100%;
      background-color: initial;

      &::placeholder {
        color: $grey-color;
      }

      &:hover {
      }
    }

    &-errorBorder {
      border-bottom: 2px solid $red-color;
      &:hover {
        border-bottom: 2px solid $red-color;
      }
    }

    &-close,
    &-arrow {
      position: absolute !important;
      right: 5px !important;
      top: 5px !important;
      background: transparent !important;
    }

    &-close {
      top: 7px !important;
      &:before,
      &:after {
        background-color: $grey-color !important;
      }
    }
    &-arrow {
      cursor: pointer;
    }
  }

  &-itemsContainer {
    position: absolute;
    width: 100%;
    height: max-content;
    max-height: 400px;
    background: $white-color;
    z-index: 1;
    overflow: auto;
    padding: 8px 0;
    box-shadow: 0 7px 12px rgba(0, 0, 0, 0.13);

    &-item {
      font-family: $font-heebo;
      font-size: 14px;
      line-height: 180%;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: $black-color;
      margin: 0;
      cursor: pointer;
      padding: 8px 16px;

      &:hover {
        background: rgba(#000000, 0.01);
      }
    }
  }

  &-additional {
    margin: 8px 16px;
    border-top: 1px solid $light-grey-color;

    &-title {
      font-size: 12px;
      line-height: 150%;
      color: $grey-color;
      font-weight: normal;
      margin: 8px 0;
    }

    &-text {
      font-family: $font-heebo;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $black-color;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0;
    }
  }

  &-error {
    color: $red-color;
    margin-bottom: 0;
    margin-top: 8px;
    font-size: 0.75rem;
  }
}
