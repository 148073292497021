@import "../../../styles/index.scss";

.progressCircle {
  position: relative;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  @include flex();
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    transform: rotate(270deg);
    circle {
      cursor: pointer;
    }
  }
  text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: $grey-2;
  }
}
