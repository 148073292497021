@import "../../../../styles/index.scss";

.media-devices-permissions {
  background: $white-color;
  box-shadow: $card-shadow;
  padding: 16px 24px;
  color: $black-color;
  margin-bottom: 20px;
  transition: opacity 600ms, visibility 600ms;

  &-title {
    float: left;
    height: 40px;
    display: flex;
    align-items: center;

    >span {
      background: url("/assets/icons/warning.svg") left center no-repeat;
      padding-left: 32px;
      font-family: $font-lora;
      font-weight: bold;
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 0.02em;
    }
  }
  &-action {
    float: right;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >span {
      font-size: 20px;
      line-height: 28px;
      background-size: 24px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 32px;
      margin-right: 30px;

      &.permissions-camera {
        background-image: url("/assets/icons/camera_enabled.svg");

      }
      &.permissions-microphone {
        background-image: url("/assets/icons/mic_enabled.svg");
      }
    }
  }
}
