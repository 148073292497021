@import "../../../styles/variables.module";

.colorpicker {
  height: 60px;
  width: 80px;
  border-radius: 5px;
  background-color: $soft-peach-color;
  transition: background-color 0.2s;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  box-sizing: border-box;

  &-open,
  &:hover {
    background-color: $pink-color;
  }

  &-selectedColor {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid $grey-color;
  }

  &-dropdown {
    position: absolute;
    z-index: 10;
  }
}
