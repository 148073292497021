@import "./variables.module.scss";
@import "./mixins.scss";
.hideOverflow {
  &-sideDialog,
  &-community {
    height: 100vh;
    overflow: hidden;
  }
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: $font-heebo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: 14px;
  .H_l_bottom {
    display: none;
  }
  .H_l_anchor {
    margin-top: 55px !important;
  }
  .H_btn[title="Zoom in"] {
    background-color: inherit;
    background-image: url("/assets/icons/plus.svg");
    width: 30px;
    padding: 0;
    > svg {
      visibility: hidden;
    }
  }
  .H_btn[title="Zoom out"] {
    padding: 0;
    background-color: inherit;
    background-image: url("/assets/icons/minus.svg");
    width: 30px;
    > svg {
      visibility: hidden;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $brown-color transparent;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brown-color;
    outline: none;
    border-radius: 30px;
  }
}

.arrows {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
  &:after {
    content: "";
    top: 0;
    cursor: pointer;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-color: $grey-color;
    border-width: 2px 2px 0 0;
    border-style: solid;
    transform-origin: 100% 0;
  }
  &-bottom {
    transform: rotate(135deg);
    top: -2px;
  }
  &-top {
    transform: rotate(315deg);
    margin-top: 5px;
  }
}

.editIcons {
  width: 24px;
  height: 24px;
  background: #000000;
  position: relative;
  cursor: pointer;
  margin: 0;
  &:before,
  &:after {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    background: $white-color;
  }
  &-add {
    &:before,
    &:after {
      width: 2px;
      height: 12px;
    }
    &:after {
      transform: rotate(90deg);
    }
  }
  &-delete {
    &:before,
    &:after {
      width: 2px;
      height: 14px;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
  &-edit {
    background: url("/assets/icons/edit_icon_black.svg") center no-repeat $gold-color;
    &:before,
    &:after {
      display: none;
    }
  }
  &-drag {
    background: url("/assets/icons/drag_icon.svg") center no-repeat;
    &:before,
    &:after {
      display: none;
    }
  }
}

.defaultButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  background: linear-gradient(85.11deg, #0c0c0c 0%, #3a3a3a 100%);
  box-shadow: 3px 0 18px rgba(152, 166, 182, 0.27);
  &-cancel,
  &-confirm,
  button,
  &-login {
    padding: 2px 10px;
    min-width: 112px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 24px;
  }
  &-login {
    height: 40px;
    color: $white-color;
    background-color: transparent;
    width: 100%;
    text-decoration: none;
    box-sizing: border-box;
  }
  .defaultButtons-confirm {
    box-sizing: border-box;
    background-color: $blue-1;
    color: $white-color;
    overflow: hidden;
    border-radius: 9px;
    padding: 2px 18px !important;
  }
  .defaultButtons-cancel {
    min-width: initial;
    padding: 0;
    background: transparent;
    color: $blue-1;
  }
  .defaultButtons-button {
    width: initial;
  }
  .defaultButtons-remove {
    background-color: $error;
  }
  .defaultButtons-info {
    background-color: $orange-1;
    color: $white-color;
  }
}

.defaultSearchInput {
  width: 200px;
  margin-right: 16px;
  &-clear {
    &:before,
    &:after {
      background: $grey-color !important;
    }
  }
  &-input {
    width: 100%;
    height: 40px;
    border: none;
    font-family: $font-heebo;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.02em;
    padding: 8px 16px;
    color: $black-color;
    background: $input-background;
    &::placeholder {
      color: $grey-color;
    }
  }
}

.defaultSnackStyle {
  border-radius: 0 !important;
}

.snackStyle {
  &-error {
    background: #e24439 !important;
    box-shadow: 0 2px 8px rgba(226, 68, 57, 0.4) !important;
  }
  &-success {
    background: $green-color !important;
    box-shadow: 0 2px 8px rgba(73, 171, 124, 0.4) !important;
  }
}

.hidden {
  display: none !important;
}

.no-mobile {
  @include mobile($mobile-width) {
    display: none;
  }
}

.only-mobile {
  display: none;
  @include mobile($mobile-width) {
    display: flex;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700;900&display=swap");
