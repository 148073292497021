@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins.scss";

.analyticsTitle {
  position: relative;
  @include flex(flex-start);
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 26px;
  margin: 48px 0 26px;
  &-info {
    border-radius: 50%;
    margin-left: 10px;
    &-card {
      display: none;
      justify-content: center;
      align-items: center;
      font-family: "Heebo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      position: absolute;
      width: 426px;
      height: 64px;
      padding: 8px 16px;
      background: $white-color;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      text-align: center;
      left: 123px;
      top: 42px;
      z-index: 1;
    }
    &:hover {
      cursor: pointer;
      .analyticsTitle-info-card {
        display: flex;
      }
    }
  }
}
