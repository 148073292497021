@import "../../../../styles/variables.module.scss";
.customMessageInputWrapper {
  padding: 0 4px 0 24px;
  background: $white-color;
  margin: 2px 16px 20px;
  border-radius: 20px;
  border: 1px solid $tabs-blue-color;
  position: relative;
  &-edit {
    display: flex;
    justify-content: right;
    padding: 8px 8px 8px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &-label {
      flex-grow: 1;
      color: $grey-color;
      font-size: 14px;
      letter-spacing: 0.02em;
    }
  }
  &-icon {
    flex-shrink: 0;
    cursor: pointer;
  }
  .rfu-image-previewer__image {
    width: 88px !important;
    height: 56px !important;
    .rfu-thumbnail__wrapper {
      width: 88px !important;
      height: 56px !important;
      .rfu-icon-button {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 4px;
        top: 4px;
        div {
          display: none;
        }
        &::before {
          content: "";
          background: url("/assets/icons/warning.svg") no-repeat 100%;
          background-size: contain;
          width: 16px;
          height: 16px;
          position: absolute;
        }
      }
    }
  }
  .rfu-image-previewer {
    .rfu-thumbnail-placeholder {
      border: 1px solid $pink-color;
      width: 88px !important;
      height: 56px !important;
      svg {
        display: none;
      }
      &::before {
        content: "";
        background: url("/assets/icons/plus_icon_pink.svg") no-repeat 100%;
        background-size: contain;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__preview-file {
    margin-right: 14px;
    &--stream {
      .rfu-file-previewer {
        &__file {
          color: $white-color;
          &:hover {
            background-color: transparent;
          }
          a {
            color: $white-color;
          }
        }
      }
    }
  }

  .helper-text {
    font-family: $font-heebo;
    font-size: 12px;
    line-height: 20px;
    color: $red-color;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    text-align: right;
  }
}

.customMessageInput {
  display: flex;
  align-items: flex-end;
  position: relative;
  @-moz-document url-prefix() {
    button,
    textarea {
      &:disabled {
        pointer-events: none;
      }
    }
  }
  &-emojiPicker {
    position: absolute;
    bottom: 100%;
    right: 0;
  }
  &-input {
    height: 100%;
    .MuiInput {
      &-root {
        height: 100%;
        padding-right: 20px;
      }
      &-input {
        font-family: $font-heebo !important;
        font-size: 18px;
        line-height: 160%;
        letter-spacing: 0.02em;
      }
      &-underline {
        &:before,
        &:after {
          display: none;
        }
      }
    }
    .MuiInputBase-input {
      min-height: 20px;
      &::placeholder {
        color: $purple-1;
        opacity: 1;
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    align-self: center;
    img {
      cursor: pointer;
      &:nth-child(n):not(:last-child) {
        margin-right: 11px;
      }
    }
    &__stream {
      display: flex;
      .rfu-file-upload-button {
        margin-right: 0;
      }
    }
  }
}

.sendBtn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 13px;
  &:disabled {
    opacity: 0.5;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.rfu-file-upload-button {
  margin-right: 11px;
  label {
    display: flex;
    align-items: center;
    img {
      height: 22px;
    }
  }
}
