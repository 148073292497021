@import "../../../../../styles/variables.module";
.edit-subscription-wrapper {
  margin-bottom: 80px;

  .edit-subscription-title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 26px;
    color: #181611;
  }

  .annual-settings-wrapper {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;

    .annual-title {
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #181611;
    }

    .switcher {
      &-wrapper {
        width: 214px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MuiFormControlLabel-root {
          margin: 0;
        }
      }
      &-customLabel {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .switch-control {
    &-root {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  .subscription-description {
    margin-bottom: 32px;
  }

  .plans-wrapper {
    .plan-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      width: 100%;

      .plan-title-wrapper {
        display: flex;

        .plan-title {
          font-family: Lora;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 38px;
          display: flex;
          align-items: center;
          color: #1d1d1d;
          margin-left: 16px;

          .label {
            margin-left: 8px;
            background: #01a712;
            border-radius: 2px;
            padding: 4px 3px;
            color: #f8f9fa;
            font-family: Heebo;
            font-weight: 500;
            font-size: 11px;
            line-height: 100%;
            text-transform: uppercase;

            &.recommended {
            }
          }
        }
      }

      .plan-description-wrapper {
        margin-top: 8px;
        display: flex;
        margin-left: 58px;
        justify-content: space-between;

        .plan-description {
          width: 320px;

          .description {
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #181611;
          }

          .pricing-page-link {
            font-family: Heebo;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.04em;
            text-transform: capitalize;
            color: #7b9eb9;
            margin-top: 8px;
            text-decoration: none;
          }
        }

        .plan-pricing-wrapper {
          display: flex;

          .plan-price {
            font-family: Lora;
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
            line-height: 62px;
            color: #181611;
          }

          .plan-price-details {
            position: relative;

            .month {
              font-family: Heebo;
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 26px;
              color: #372e35;
              margin-top: 25px;
              &.discount {
                margin-top: 0;
              }
            }

            .discount-badge {
              background: #ef7171;
              border-radius: 2px;
              padding: 4px 3px;
              font-family: Heebo;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 100%;
              text-transform: uppercase;
              color: #f8f9fa;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .mau-wrapper {
    margin-left: 44px;
  }

  .pricing-summary {
    margin: 26px 0 0 44px;
    display: flex;
    justify-content: space-between;

    .mau-summary-wrapper {
      width: 280px;

      .more-mau-users {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: #000000;
      }

      .mau-switch {
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: #7b9eb9;
        display: inline-block;
        cursor: pointer;
      }
    }

    .total-price-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: -10px;

      .pricing {
        display: flex;
        width: 300px;
        justify-content: space-between;

        .total-price-title {
          font-family: Lora;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 38px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #1d1d1d;
          &:nth-child(1) {
            width: 140px;
          }

          &.overLimit {
            margin: 0 0 0 auto;
          }
        }

        .total-price-value {
          font-family: Lora;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 62px;
          text-align: right;
          color: #181611;
        }
      }

      .payment-details {
        margin-top: 18px;

        .payment-item {
          display: flex;
          justify-content: space-between;

          span {
            font-family: Heebo;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #8c8c8c;

            &:nth-child(1) {
              width: 140px;
            }
          }
        }
      }
    }
  }

  .submit-button-wrapper {
    margin-top: 16px;
    margin-left: 44px;
    flex-direction: column;
    display: flex;

    .get-started-button {
      margin-top: 24px;
      background: #e1a077;
      border-radius: 9px;
      outline: none;
      border: none;
      padding: 8px;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: #ffffff;
      cursor: pointer;
      &.contact-us {
        background: #7b9eb9;
      }
      &.disabled {
        background: $grey-4;
        pointer-events: none;
      }
    }
    .error-message {
      margin-top: 16px;
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ef7171;
    }
  }
}
