@import "../../../../../styles";
.video-list-table-name {
  font-weight: bold;
  @include flex(center, center, column);
  // position: absolute;
  width: 100%;
  height: 100px;
  .table-title {
    color: $purple-color;
    font-size: 21px;
    line-height: 140%;
    margin-bottom: 25px;
  }
  .table-name {
    color: $purple-4;
    font-size: 32px;
    line-height: 140%;
    &.can-edit {
      cursor: pointer;
      padding: 0 30px;
      &:hover {
        color: $purple-4;
        background: url("/assets/icons/edit_icon_2.svg") center right no-repeat;
        background-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .video-list-table-name {
    height: unset;
    .table-title {
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .video-list-table-name {
    .table-title {
      font-size: 16px;
      margin-bottom: 0;
    }
    .table-name {
      font-size: 20px;
      &.can-edit {
        &:hover {
          background-size: 18px;
        }
      }
    }
  }
}
