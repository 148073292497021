@import "../../../styles/variables.module";
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 224px);
  position: fixed;
  top: 0;
  left: auto;
  padding: 24px 24px 24px 24px;
  right: 0;
  box-sizing: border-box;
  background: #f8f9fa;
  z-index: 50;
  &.subscriptionAttention {
    flex-wrap: wrap;
    padding-bottom: 12px;
  }
  &-isLightBlue {
    background: $white-2;
  }
  &-title {
    margin-right: 10px;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 22px;
    line-height: 38px;
    color: $black-color;
    letter-spacing: 0.02em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &-active {
      cursor: pointer;
    }
  }
  &-count {
    width: fit-content;
    height: 24px;
    max-height: 24px;
    padding: 0 7px;
    margin-left: 15px;
    background: #d8e1e9;
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #7b9eb9;
    display: grid;
    justify-items: center;
    align-items: center;
  }
  &-right {
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: nowrap;
  }
}

.dashboard-body-collapsed {
  .header {
    width: calc(100% - 72px);
    background: #f8f9fa;
  }
}
