@import "../../../../../../styles/variables.module.scss";
@import "../../../../../../styles/mixins.scss";
.roomsSection {
  position: relative;
  margin-bottom: 40px;
  .custom-switch {
    .MuiFormControlLabel-label {
      text-transform: unset;
      font-weight: 500;
    }
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
    .switch-switchBase {
      padding: 2px;
    }
  }
  &.open {
    .roomsSection-content {
      opacity: 1;
      height: fit-content;
      transition: opacity 0.3s;
    }
  }
  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: $black-color;
    margin: 0;
  }
  &-description {
    font-size: 14px;
    line-height: 160%;
    color: $grey-1;
    margin: 16px 0 16px;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    opacity: 0;
    height: 0;
    &-preview {
      order: 2;
    }
  }
}
