@import "../../../styles/index.scss";
%figure-shared {
  position: absolute;
  z-index: 0;
}

.blocker {
  margin: auto;
  @include display-flex-center;
  flex-direction: column;
  padding: 16px;
  position: relative;
  z-index: 1;
  &-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #282025;
  }
  &-image {
    margin-bottom: 32px;
  }
  &-title {
    font-weight: bold;
    font-size: 34px;
    line-height: 48px;
    color: $blue-4;
    margin: 0 0 8px;
    text-align: center;
    color: $purple-color;
  }
  &-description {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    max-width: 320px;
    margin-bottom: 56px;
    color: $purple-color;
  }
  &-figures {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;
    &-squarePolygon {
      @extend %figure-shared;
      transform: scale(.72);
      top: 60%;
      left: 2%;
      &.small {
        transform: scale(0.3);
        top: 27%;
        left: unset;
        right: 0;
      }
    }
    &-polygon {
      @extend %figure-shared;
      right: 5%;
      top: 80%;
      transform: scale(0.64);
    }
    &-arch {
      @extend %figure-shared;
      left: 10%;
      top: 2%;
      transform: scale(0.64);
      &.small {
        transform: rotate(90deg) scale(0.26);
        left: unset;
        top: unset;
        bottom: 24%;
        right: -2%;
      }
    }
    &-square {
      @extend %figure-shared;
      left: 20%;
      bottom: 6%;
      transform: scale(0.3);
    }
  }
}

@media screen and (max-width: 1024px) {
  .blocker {
    padding: 8px;
  }
}

@media screen and (max-width: 960px) {
  .blocker {
    flex-wrap: wrap;
    &-figures {
      &-squarePolygon {
        &.small {
          right: -4%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .blocker {
    padding: 0 20px;
  }
}