@import "../../../../../../styles/mixins";
@import "../../../../../../styles/variables.module";
.membersMenuTooltip {
  .MuiTooltip-tooltip {
    border-radius: 12px;
    padding: 5px 14px;
    box-sizing: border-box;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    color: $white;
    background: rgba(123, 158, 185, 0.7);
  }
  &.red {
    .MuiTooltip-tooltip {
      background: rgba(239, 113, 113, 0.7);
      ;
    }
  }
}
