@import "../../../styles/variables.module";

.square-text-field {
  .text-label {
    font-size: 12px;
    line-height: 18px;
  }

  .text-field {
    border-radius: $border-radius;

    input, textarea {
      font-size: 16px !important;
      line-height: 24px;

      &::placeholder {
        color: $grey-4;
        opacity: 1;
      }
    }
  }
}
