@import "../../../../styles/index.scss";
.eventSideDialog {
  width: $stream-sideDialog-width;
  min-width: $stream-sideDialog-width;
  height: 100%;
  margin: 0;
  background-color: $common-panel-bg;
  font-family: $font-heebo;
  padding: 24px 24px 16px;
  box-sizing: border-box;
  display: none;
  z-index: 100;
  margin-right: 0;
  .eventContainer-body & {
    margin-right: 0;
  }
  &-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    &.tabs {
      border-bottom: 2px solid $popup-bg;
      margin: 0 -24px;
      padding-right: 24px;
      .eventSideDialog-close-btn {
        margin-top: 4px;
      }
    }
    &-back {
      position: relative;
      padding-left: 16px;
      cursor: pointer;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
      color: $purple-2;
      &::before {
        content: url("/assets/icons/arrow_purple.svg");
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 11px;
        height: 7px;
      }
    }
    &-text {
      display: flex;
      align-items: center;
      margin-top: 8px;
      max-width: calc(376px - 48px);
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      >div {
        width: 100%;
      }
    }
    &-title-chat {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-tabs {
      display: flex;
      margin-bottom: -1px;
    }
    &-tab {
      padding: 2px 24px 10px;
      position: relative;
      button {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
      }
      &.notification {
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 12px;
          right: 12px;
          background: #ef7171;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      &.active {
        border-bottom: 6px solid $purple-1;
        button {
          color: $blue-4;
        }
      }
    }
  }
  &-title {
    font-weight: 400;
    color: $blue-4;
    margin: 0;
    padding: 0 8px;
    font-size: 20px;
    line-height: 48px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    max-width: calc(100% - 48px);
    &-ellipsis {
      white-space: nowrap;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-greeter {
      font-weight: 400;
      margin-left: 2px;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      color: $purple-3;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:not(:first-child) {
      padding-left: 8px;
    }
  }
  &-body,
  &-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &-linear-gradient {
    display: flex;
    height: 40px;
    position: absolute;
    top: 59px;
    z-index: 101;
    width: 100%;
    background: linear-gradient(180deg, #0e0a0c 0%, rgba(14, 10, 12, 0) 100%);
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s;
    &.visible {
      opacity: 1;
    }
  }
  &-container {
    @include flex(space-between,
    normal,
    column)
  }
  &-body {
    max-height: 100%;
    overflow: hidden;
  }
  &-close-btn {
    align-self: flex-start;
    margin-top: 14px;
  }
  &.open {
    display: block;
  }
}

@media screen and (max-width: 1160px) {
  .eventSideDialog {
    width: $stream-sideDialog-width-small;
    min-width: $stream-sideDialog-width-small;
    padding: 18px 12px 12px;
    &-header {
      &.tabs {
        margin: 0 -12px;
        padding-right: 12px;
      }
    }
  }
  &-title {
    max-width: 100%;
    padding: 0;
  }
}
