@import "../../../../styles/index.scss";
@import "../../../../styles/mixins.scss";
.email-sent {
  max-width: 416px;
  margin: 0 16px;
  color: $grey-1;
  &-title {
    width: max-content;
    font-family: $font-lora;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-align: center;
  }
  &-subtitle {
    width: max-content;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8C8C8C;
    .email-value {
      color: #E1A077;
    }
  }
  &-divider {
    height: 5px;
    width: 104px;
    margin: 40px 0;
    background-color: $wisp-pink-color;
  }
  &-help-text {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $nav-bg;
    &-link {
      color: $blue-1;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  &-buttons {
    margin-top: 40px;
    a.styledLink {
      @include display-flex-center;
      float: left;
      padding: 0 30px;
      margin: 0 8px;
      background: $blue-3;
      border-radius: $border-radius;
      letter-spacing: 0.04em;
      text-transform: none;
      color: $grey-1;
      height: 40px;
      font-size: 13px;
      box-sizing: border-box;
      img {
        margin-right: 12px;
      }
    }
  }
  &-input {
    margin-bottom: 40px;
    width: 240px;
    height: 72px;
  }
  &.event {
    max-width: 100%;
    height: 100%;
    min-height: 535px;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 10px;
    align-items: flex-start;
    .email-sent {
      &-title,
      &-subtitle,
      &-help-text {
        width: 100%;
        color: $white-color;
        text-align: left;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
      &-divider {
        margin: 30px 0;
        background-color: $popup-bg;
      }
      &-help-text {
        margin-bottom: 8px;
      }
      &-buttons {
        margin-top: auto;
        a {
          width: 100%;
          box-sizing: border-box;
          margin: 0 0 12px;
          color: $purple-4;
          background-color: $popup-bg;
          justify-content: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .email-sent {
    &-title,
    &-subtitle {
      width: 100%;
    }
  }
}

@media screen and (max-width: 440px) {
  .email-sent {
    &-buttons {
      width: 100%;
      a.styledLink {
        padding: 0 23px;
        margin: 0;
        &:last-of-type {
          float: right;
        }
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .email-sent {
    &-buttons {
      a.styledLink {
        width: 100%;
        &:last-of-type {
          margin-top: 15px;
        }
      }
    }
  }
}