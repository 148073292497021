.subscription-banner-wrapper {
  height: 470px;
  position: relative;
  margin: 64px auto 0;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .banner-information-wrapper {
    position: absolute;
    margin: 0 auto;
    text-align: center;

    .badge {
      width: 120px;
      background: #D8E1E9;
      border-radius: 14px;
      padding: 2px 12px;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #7B9EB9;
      margin: 0 auto 24px;
    }

    .banner-title {
      font-family: Lora;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      color: #372E35;
      margin-bottom: 16px;
    }

    .banner-description {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #372E35;
      margin-bottom: 40px;
    }

    .orange-button {
      background: #E1A077;
      border-radius: 9px;
      width: 180px;
      padding: 8px;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      text-decoration: none;
    }
  }
}