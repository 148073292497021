@import "../../../../../styles/index.scss";
.benchRow {
  @include flex(flex-start);
  margin-bottom: 18px;
  &-item {
    &:last-child {
      margin-left: 24px;
      .benchAttendee {
        &-name {
          &-wrapper {
            transform: translate(50%) scaleX(0);
            right: 7px;
            left: initial;
            border-radius: 9px 23px 23px 9px;
            .benchAttendee-name {
              padding-right: 75px;
              padding-left: 10px;
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
        &:hover {
          .benchAttendee-name-wrapper {
            transform: translate(0) scaleX(1);
          }
        }
      }
    }
    &:first-child {
      margin-right: 24px;
    }
  }
  &-oneInRow {
    .benchRow-item {
      margin-left: 0;
    }
  }
}