.landing-settings-wrapper {
  padding: 0 40px 40px;
  min-width: 800px;
  .appearance-item {
    font-family: Heebo;
    font-style: normal;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dad2d0;
    padding-bottom: 50px;
    margin-bottom: 60px;
    &.lastItem {
      border-bottom: none;
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #181611;
      width: 300px;
      padding-bottom: 24px;
    }
    .settings-block-divider {
      margin-bottom: 24px;
    }
    &-description {
      font-family: "Heebo";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
}
