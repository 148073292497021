@import "../../../../styles/variables.module";

.emptyDiscussionsState {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100px;

  &-img {
    margin-bottom: 10px;
  }

  &-text,
  &-title {
    font-family: $font-heebo !important;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: $grey-color;
    line-height: 28px;
    max-width: 308px;
    text-align: center;
    margin: 8px 0 0;
  }
  &-title {
    color: $blue-color;
    margin: 16px 0 0;
  }

  &-btn {
    font-family: $font-heebo !important;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $orange-color;
    line-height: 23px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
  }
}
