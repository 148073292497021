@import "../../../../../styles/mixins.scss";

.emoji-reaction-item-wrapper {
  @include flex();
  background-color: #7E6677;
  border-radius: 10px;
  padding: 3px;
  cursor: pointer;
  margin: 4px;
  border: 1px solid #7E6677;
  &.has-my-reaction {
    border-color: #C9C0C6;
  }
  .emoji-reaction-item-count {
    margin-right: 2px;
    margin-left: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
}