@import "../../../../styles/index";

.completeProfile {
  width: 100%;
  @include flex(flex-start, center, row);
  margin-bottom: 22px;
  .overlay {
    background-color: transparent;
  }

  &-stepText {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    color: $grey-2;
    margin: 12px 0 12px 12px;
    cursor: pointer;
    &:hover {
      color: $blue-1;
    }
  }
  &.isCollapsed {
    flex-direction: column;
    margin: 0;
    .completeProfile-stepText {
      margin: 12px 0;
      width: 34px;
    }
  }
}
