@import "../../../../styles/index.scss";

.login-options {
  @include display-flex-center();
  flex-direction: column;
  width: 100%;

  input::-ms-reveal {
    display: none;
  }

  .submit-button {
    margin-top: 24px;
  }

  .divider {
    width: 320px;
    position: relative;
    margin: 30px 0 20px;
    .text {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: $blue-3;
      position: relative;
      background: $white-2;
      z-index: 1;
      width: 85px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      background: $blue-3;
      top: 12px;
    }
  }
}
