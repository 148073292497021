@import "../../../../styles/index.scss";
.custom-avatar {
  position: relative;

  &-image {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: cover;
    text-align: center;
  }
  &--circle {
    border-radius: 50%;
  }
  &--rounded {
    border-radius: 6px;
  }
  &--square {
    border-radius: 0;
  }
  &-fallback {
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    color: white;
    object-fit: cover;
    text-align: center;
    text-transform: uppercase;
  }
  &-image,
  &-fallback {
    overflow: hidden;
    .custom-avatar {
      &--circle {
        border-radius: 50%;
      }
      &--rounded {
        border-radius: 6px;
      }
      &--square {
        border-radius: 0;
      }
    }
  }
  &.online {
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      right: 0;
      background: #7FBA7A;
      box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .mark {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
