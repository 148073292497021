@import "../../../styles/index.scss";
.timePicker-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  .timePicker-label {
    margin-top: 24px;
  }
  .pickerLabel {
    font-size: 13px;
    line-height: 20px;
    color: $grey-4;
    position: absolute;
    top: 0;
    left: 16px;
  }
  .timePicker {
    height: 40px;
    box-sizing: border-box;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    font-family: $font-heebo;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
    justify-content: space-between;
    cursor: pointer;
    background-color: $light-grey-color;
  }
}

@media screen and (max-width: 450px) {
  .timePicker-wrapper {
    .timePicker {
      font-size: 14px;
    }
  }
}