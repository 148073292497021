@import "../../../../../styles/index.scss";
.profileBtn {
  @include display-flex-center;
  background: $popup-bg;
  border-radius: 40px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  padding: 4px;
  padding-left: 10px;
  height: 48px;
  &-name {
    margin-right: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $white-color;
    @include truncate-line;
    max-width: 100px;
  }
  .profileBtn-user {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .initials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .fallback {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-transform: uppercase;
    }
  }
}