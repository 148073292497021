@import "../../../../../styles/index.scss";

.stripeCancelPopup {
  width: 416px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: url("/assets/images/stripe-cancel-bg.svg") no-repeat top, $white-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .close {
    mask: url("/assets/icons/close_icon.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background: $purple-color;
    opacity: 0.5;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .text {
    padding: 0 24px;
    font-size: 14px;
    color: $black-color;

    &-header {
      font-family: $font-lora;
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 0.01em;
      color: $blue-1;
      margin: 48px 0;
      text-align: center;
    }
  }

  .footer {
    margin: 24px;
  }
}
