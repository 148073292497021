.settings-wrapper {
  width: 100%;
  margin-top: -24px;
  // margin-bottom: 24px;
  height: 350px;
  .inner-wrapper {
    padding: 0 24px;
    .video-wrapper {
      width: 224px;
      height: 136px;
      position: relative;
      background-color: rgb(0, 0, 0);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
      border-radius: 8px;
      video {
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      &.mirrored {
        video {
          transform: scaleX(-1);
        }
      }
    }
    .audio-settings {
      //  display: flex;
      //   justify-content: space-between;
    }
    .option {
      .label {
        color: #8c8c8c;
        margin-top: 16px;
        margin-bottom: 8px;
      }
      canvas {
        width: 464px;
      }
      .dropDown {
        &.variant-brown {
          background: #282025;
          border-radius: 20px;
          .dropDown-selectedName {
            font-size: 16px;
            line-height: 24px;
            font-weight: normal;
            color: #eff3f6;
          }
        }
        .dropDown-optionsList {
          padding: 0;
          margin: 0;
          background: #282025;
          border-radius: 20px;
          right: auto;
          width: 100%;
          .dropDown-selectedOpt {
            background: #eff3f6;
            color: #282025;
          }
          .dropDown-option {
            padding: 5px;
            font-weight: normal;
          }
        }
      }
    }
  }
}