@import "../../styles/variables.module";
.defaultSnackStyle {
  border-radius: 10px;
  width: 352px;
  min-height: 50px;
  color: rgba(255, 255, 255, 1);
}

.initial-loader{
  min-height: 100vh;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  font-family: $font-heebo;
}

.snackStyle {
  &-success {
    background: rgba(75, 159, 34, 1);
    box-shadow: 0 2px 8px rgba(75, 159, 34, 0.7);
  }
  &-error {
    background: rgba(225, 77, 113, 1);
    box-shadow: 0 2px 8px rgba(225, 77, 112, 0.7);
  }
  &-info {
    background: $blue-color!important;
    box-shadow: 0 2px 8px rgba(9, 173, 226, 0.7);
  }
}

@media screen and (max-width: 600px) {
  .defaultSnackStyle {
    max-width: 300px;
  }
}

@media screen and (max-width: 540px) {
  .defaultSnackStyle {
    max-width: 272px;
  }
}
