@import "../../../styles/index.scss";

.muteAll-switch {
  .switch-root {
    width: 336px;
    height: 40px;
    padding: 0;
    border-radius: 20px;
  }
  .switch-thumb {
    width: 168px;
    height: 40px;
    border-radius: 20px;
    position: relative;
    background: $pink-gradient-reverse;
    &::before {
      content: "mute all";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      color: $white-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
    }
  }
  .switch-switchBase {
    padding: 0;
    box-shadow: $card-shadow;
  }
  .switch-checked {
    transform: translateX(168px) !important;
    color: $blue-color !important;
    .switch-thumb {
      &::before {
        content: "unmute All";
      }
    }
  }
  .switch-track {
    background-color: rgba(164, 170, 190, 0.1) !important;
    opacity: 1 !important;
    position: relative;
    &::before,
    &::after {
      content: "mute all";
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: $input-label-color;
    }
    &::before {
      left: 30px;
    }
    &::after {
      content: "unmute All";
      right: 30px;
    }
  }
}
