@import "../../../../../styles/index.scss";
.event-type {
  width: 289px;
  height: 289px;
  background: #eff3f6;
  cursor: pointer;
  border: solid 3px transparent;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 34px;
  padding: 24px 42px;
  position: relative;
  &:hover {
    border: 3px solid #e1a077;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &__badge {
    position: absolute;
    width: 81px;
    height: 16px;
    background-color: $orange-1;
    border-radius: 2px;
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 100%;
    color: $white-color;
    filter: grayscale(0);
    @include flex();
    right: 0;
    top: 0;
    margin: 10px;
  }
  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #1d1d1d;
    margin-top: 34px;
    margin-bottom: 16px;
  }
  &__description {
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    opacity: 0.9;
  }

  &--disabled {
    .event-type__icon,
    .event-type__name,
    .event-type__description {
      color: #8c8c8c;
      filter: grayscale(1);
    }
    &:hover {
      border: 3px solid transparent;
    }
    cursor: not-allowed;
  }
}
