@import "../../../../../../styles";
.eventAnnouncementTemplates {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 16px;
  &-card {
    background-color: $popup-bg;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 16px;
    padding: 24px 16px;
    box-sizing: border-box;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $purple-4;
      margin-bottom: 28px;
      @include truncate-line();
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $white-color;
      margin-bottom: 24px;
    }

    &-divider {
      margin: 17px 0 26px;
      height: 2px;
      background-color: $purple-1;
    }
    &-footer {
      @include flex(space-between);
      &-type {
        @include flex();
        height: 24px;
        width: fit-content;
        background-color: #607184;
        border-radius: 9px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $white-color;
        padding: 2px 8px;
      }
      &-controls {
        @include flex();
        &-publish {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
        }
        .three-dots-popup {
          min-width: 24px;
          .three-dots-popup-wrapper {
            background-color: $popup-bg;
            box-shadow: 0px 4px 8px rgb(0, 0, 0 / 5%);
            .eventAnnouncementTemplates-card-footer-controls-edit {
              border-radius: 20px;
              box-sizing: border-box;
              color: $white-color;
              padding: 18px;
              width: 100%;
              &:hover {
                background: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
