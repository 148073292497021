$font-heebo: "Heebo",
sans-serif;
$font-lora: "Lora",
serif;
$background-color: #f8f4f3;
$black-color: #1d1d1d;
$grey-color: #b8b8b8;
$blue-color: #7b9eb9;
$polo-blue-color: #96b9d4;
$light-grayish-blue-color: #edf0f2;
$tabs-blue-color: #d8e1e9;
$orange-color: #f09761;
$orange-light-color: #f0b48f;
$green-color: #4ccc96;
$white-color: #fff;
$olive-color: #7d745a;
$purple-color: #93808d;
$dark-purple: #241c22;
$pink-color: #dcc7c2;
$wisp-pink-color: #eee6e4;
$red-color: #f6766f;
$dividers: #eee6e4;
$dark-grey: #403e3e;
$dark-blue: #2d3348;
$dark-grey-color: #b2b2b2;
$grey-color-secondary: #ededed;
$light-grey-color: #f2f2f2;
$grey-input-bg: #eeebea;
$red-color-bright: #fb5a64;
$gold-color: #fddb8f;
$light-blue-color: #8fadc4;
$brown-color: #767676;
$input-label-color: #a4aabe;
$grey-background-color: #f1f3fb;
$soft-peach-color: #f0e6e4;
// colors for new design
$success: #75e99d;
$warning: #eeb36d;
$error: #ef7171;
$grey-1: #181611;
$grey-2: #403e3e;
$grey-3: #8c8c8c;
$grey-4: #bababa;
$grey-5: #e8e8e8;
$grey-6: #f3f3f3;
$white: #fff;
$white-2: #f8f9fa;
$blue-1: #7b9eb9;
$blue-2: #afc2d1;
$blue-3: #d8e1e9;
$blue-4: #eff3f6;
$blue-5: #55758d;
$purple-1: #7e6677;
$purple-2: #93808d;
$purple-3: #c9c0c6;
$purple-4: #e9e6e8;
$orange-1: #e1a077;
$orange-2: #f0b48f;
$orange-4: #fcf0e9;
$beige-1: #ccaca6;
$beige-2: #dcc7c2;
$beige-3: #eee3e1;
$beige-4: #f8f4f3;
$henna: #e5e3de;
$cyan: #95b7bc;
$popup-bg: #382e35;
$popup-bg-2:#61545C;
$common-panel-bg: #0e0a0c;
$nav-bg: #191317;
$green-bg-gradient: linear-gradient(264.6deg, #4b9f22 5.09%, #36671e 93.96%);
$dark-gradient: linear-gradient(83.88deg, #0c0c0c 0%, #3a3a3a 100%);
$loader-gradient: linear-gradient(-60deg, #fff200 50%, #ffd500 50%, #ffd500 75%, #fff200 75%);
$pink-gradient: linear-gradient(85.61deg, #fe8189 0%, #fdb499 100%);
$pink-gradient-reverse: linear-gradient(85.61deg, #fdb499 0%, #fe8189 100%);
$input-background: $grey-6;
$semitransparent-color: rgba(147, 128, 141, 0.4);
$grey-transparent-background-color: rgba(184, 184, 184, 0.15);
$dark-popup-background-color: rgba(14, 10, 12, 0.6);
$card-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
$card-shadow-orange: 0px 0px 3px 1px $orange-color;
$border-radius: 10px;
$border-radius-rounded: 30px;
// Stream variables
$stream-header-hight: 70px;
$stream-footer-hight: 88px;
$stream-dashboard-width: 96px;
$stream-dashboard-width_small: 80px;
$stream-sideDialog-width: 376px;
$stream-sideDialog-width-small: 280px;
$stream-stream-participants-width: 344px;
$stream-stream-participants-width-medium: 300px;
$stream-stream-participants-width-small: 250px;
:export {
  greyColor: $grey-color;
  blackColor: $black-color;
  redColor: $red-color;
  goldColor: $gold-color;
  lightGreyColor: $light-grey-color;
  whiteColor: $white-color;
  semiTransparentColor: $semitransparent-color;
  greenBGGradient: $green-bg-gradient;
  fontHeebo: $font-heebo;
  fontLora: $font-lora;
}

$event-input-background: #382E35;
$font-size-normal: 16px;
$font-size-small: 13px;
$mobile-width: 767px;
$desktop-width: 1440px;
$landing-page-width: 1220px;