@import "../../../styles/variables.module";

.entity-main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  position: relative;
  box-shadow: $card-shadow;
  overflow: hidden;
  background-color: $white-color;

  .item {
    padding: 12px 16px 0;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .avatar {
    height: 104px;
    width: 104px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    z-index: 1;
    font-family: $font-lora;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: $white-color;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.empty {
      background-color: $cyan;

      >img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .bg-el {
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: contain;

    &.el-1 {
      width: 76px;
      height: 76px;
      mask-image: url("/assets/icons/blue_circle.svg");
      bottom: 23px;
      right: -47px;
      opacity: 0.1;
      background-color: $blue-1;
    }

    &.el-2 {
      width: 51px;
      height: 22px;
      mask-image: url("/assets/icons/bg_el_2.svg");
      bottom: 135px;
      right: 51px;
      transform: rotate(180deg);
      background-color: $purple-2;
    }

    &.el-3 {
      width: 40px;
      height: 40px;
      background-image: url("/assets/icons/bg_el_3.svg");
      top: 0;
      left: 92px;
      transform: rotate(270deg);
    }

    &.el-4 {
      width: 47px;
      height: 58px;
      background-image: url("/assets/icons/bg_el_4.svg");
      bottom: 83px;
      left: 17px;
    }
  }
}
