@import "../../../../styles/index.scss";

.container-global {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  width: 1024px;
  min-height: 472px;
  background-color: $popup-bg;
  box-shadow: 0px 6px 30px 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.step1,
.step2,
.step3,
.step4 {
  display: flex;
  flex-direction: row;
  width: 100%;
  .info-block {
    margin: 0 56px 0 8px;

    .overflow-block {
      overflow: hidden;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 15px;
      width: 100%;
      height: 100%;
    }
  }

  .content-block {
    padding: 56px 59px 48px 0;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .text-block {
      font-family: $font-heebo;
      font-style: normal;

      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      .title {
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 16px;
      }
    }
  }
}

.switchers {
  display: flex;
  flex-direction: row;
  .step {
    width: 8px;
    height: 8px;
    padding: 0;
    margin-right: 8px;
    border-radius: 20px;
    border: 0;
    outline: none;
    background-color: $purple-1;
    cursor: pointer;
    &.active {
      background-color: $orange-1;
    }

  }
}

.actions {
  button {
    &.back-btn {
      margin-right: 16px;
    }
  }
}

.profile-block {
  display: flex;
  align-items: center;
  background: $popup-bg;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 17px;
  background: #7E6677;
  border-radius: 12px;
  height: 72px;
  padding: 0 10px;
  &-name {
    margin-right: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $white-color;
    width: 100%;
  }
  &-user {
    width: 67px;
    height: 52px;
    box-sizing: border-box;
    margin-right: 14px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .initials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .fallback {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-transform: uppercase;
    }
  }
}

.anonymous-block {
  margin-top: 16px;
  .dropDown,
  .text-field,
  .styledInput {
    border-radius: 20px;
  }

  .styledTextArea {
    label + .text-field {
      margin: 38px 0 24px 0 !important;
      background: #7E6677;
      color: $white;

      &::placeholder {
        color: #FFFFFF;
        opacity: 0.2;
      }
    }
  }

  .styledCheckBox-icon.withBorder {
    background: rgba(255, 255, 255, 0.1);
    border-color: #EEE3E1;
  }

  .square-text-field {
    .text-label {
      font-family: $font-heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-left: 0;
      color: $white !important;
    }
  }

  label {
    span {
      font-family: $font-heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }


}