@import "../styles.scss";

.duration {
  position: relative;
  flex: 1;
  font-size: 18px;
  &-divider {
    margin: 0 22px 0 8px;
    color: $purple-color;
  }
  &-label {
    margin-top: 30px;
  }
  .durationLabel {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $purple-color;
    position: absolute;
    top: 0;
    left: 16px;
  }
  &-controls {
    display: flex;
    align-items: center;
  }

  .helper-text {
    font-family: $font-heebo;
    color: $red-color;
    position: absolute;
    bottom: -34px;
    right: 0;
  }
}
