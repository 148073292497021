@import "../../../../../../styles/variables.module";
@import "../../../../../../styles/mixins";
.stream-relay {
  margin-top: 16px;
  &-section {
    &.common {
      margin-bottom: 48px;
    }
    &-header {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black-color;
    }
  }
  .vimeo-token-section{
    button{
      margin-left: 16px;
    }
    .vimeo-connect{
      white-space: nowrap;
      height: 40px;
      text-transform: capitalize;
      font-family: "Heebo",sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      outline: none;
      padding: 0;
      width: auto;
      transition: 0.2s;
      background: #7b9eb9;
      color: #ffffff;
      border: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      letter-spacing: 0.03em;
    }
  }
}
