@import "../../../styles/index";

@mixin variant($variant) {
  @at-root .variant-#{$variant} #{&} {
    @content;
  }
}

.dropDown {
  height: 40px;
  background-color: $input-background;
  box-sizing: border-box;
  border-radius: $border-radius;
  position: relative;

  &.variant-brown {
    background-color: initial;
  }

  &.variant-purple {
    background-color: $popup-bg;
    border-radius: 20px;

    .dropDown-selectedName {
      color: $white-color;
    }
  }

  &-spacer {
    margin-right: 16px;
  }

  &-open {
    border-color: $grey-color;
  }

  &-size {
    &-xs {
      width: 80px;
    }

    &-sm {
      width: 192px;
    }

    &-md {
      width: 220px;
    }

    &-lg {
      width: 256px;
    }

    &-full {
      width: 100%;
    }
  }

  &-selected {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px 0 16px;

    .helper-text {
      color: $error;
      position: absolute;
      top: 32px;
      right: 0;
      font-size: 10.5px;
    }
  }

  &-selectedName {
    font-family: $font-heebo;
    font-size: 16px;
    line-height: 150%;
    color: $grey-1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    outline: none;

    @include variant("brown") {
      color: $white-color;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.04em;
    }
  }

  &-optionsList {
    background-color: white;
    z-index: 3;
    position: relative;
    margin-top: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.09);
    max-height: 170px;
    overflow: auto;

    @include variant("brown") {
      border-radius: 12px;
      background-color: $popup-bg;
      color: $purple-3;
      font-size: 13px;
      position: absolute;
      width: max-content;
      right: 0;
      padding: 12px 0;
    }
  }

  &-option {
    padding: 16px;
    font-family: $font-heebo;
    font-size: 14px;
    line-height: 22px;
    word-break: break-all;

    &:hover {
      background-color: $blue-4;
      color: lighten($black-color, 4);
    }

    @include variant("brown") {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.04em;
      padding: 12px 24px;
    }
  }

  &-selectedOpt {
    color: lighten($black-color, 4);
  }

  &-search {
    background-color: $input-background;
    font-family: $font-heebo;
    font-size: 14px;
    padding: 8px 0;
    outline: none;
    border: none;
    width: 100%;
    color: $black-color;
  }

  .arrow-container {
    position: absolute;
    right: 10px;
  }

  .pointer {
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed;
  }

  &.variant {
    &-brown {
    }
  }
}
