@import "../../../../styles/mixins";
.seats {
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  padding: 42px 0 0;
  margin: 0 auto;
  background: #282025;
  @include flex(center, center, column);

  .benchAttendee {
    &:hover {
      z-index: 1;
    }
  }
}
