@import "../../../styles/index.scss";
.social-button {
  width: 100%;
  button {
    font-family: $font-heebo;
    width: 100%;
    outline: none;
    border-radius: 9px;
    letter-spacing: 0.04em;
    color: $grey-1;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    height: 40px;
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    border: 1px solid #444444;
    img {
      margin-right: 24px;
    }
  }
  &.event {
    button {
      color: $white-color;
    }
  }
}
