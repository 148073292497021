@import "../../../../../styles/index.scss";
.eventMembersDialog {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $common-panel-bg;
  font-family: $font-heebo;
  overflow: auto;
  padding-right: 6px;
  box-sizing: border-box;
  &-search {
    margin-bottom: 32px;
    .text-field {
      border-radius: 20px;
      color: $blue-4;
      background-color: rgba($color: $grey-color, $alpha: 0.15);
    }
    .clear {
      position: relative;
      display: inline;
      background: transparent;
      margin: 0 0 0 -20px;
      line-height: 40px;
    }
  }
  &-list {
    max-height: 100%;
    display: flex;

    button {
      width: 14px;
      height: 14px;
      margin: 13px 0 0 13px;
    }

    &-title {
      font-weight: 500;
      font-size: 13px;
      line-height: 25px;
      color: $blue-4;
      margin-bottom: 16px;
    }
  }
}
