@import "../../../../../styles/index.scss";
.labelButton {
  @include display-flex-center;
  background: $purple-1;
  border-radius: 20px 20px 0px 0px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: $blue-4;
}