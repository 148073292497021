@import "../../../../styles/index.scss";
.user-profile-dialog {
  &-changeMainContact {
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      mask: url("/assets/icons/link2.svg") no-repeat;
      mask-size: contain;
      background-color: $blue-1;
      left: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      transform: rotate(77deg) skew(-14deg);
    }
  }
  .sideDialog {
    &-body {
      width: 344px;
      background-color: $white-color;
    }
    &-container {
      padding: 0;
      margin: 0;
    }
    &-header {
      padding: 24px 24px 0;
      border-bottom: none;
    }
    &-title {
      font-family: $font-heebo;
      font-size: 16px;
    }
    &-footer-actions {
      background-color: $white-color;
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
      padding: 12px 24px;
      button {
        font-family: $font-heebo;
        text-transform: capitalize;
        letter-spacing: 0.04em;
      }
      &-cancel {
        color: $blue-1;
      }
      &-confirm {
        color: $blue-1;
        border: 1.5px solid $blue-1;
        border-radius: $border-radius;
        padding: 0 16px;
      }
    }
  }
  .divider {
    width: 100%;
    margin: 24px 0 0;
    background-color: $soft-peach-color;
  }
  .typography {
    color: $purple-color;
    font-size: 18px;
    line-height: 160%;
  }
  .email-help-text {
    font-size: 16px;
    line-height: 28px;
    color: $orange-color;
    padding: 0 12px;
    margin: 12px 0 -20px;
  }
  &-content {
    padding: 16px 24px;
    .styledTextArea {
      .text-label {
        font-size: 13px;
        color: $grey-4 !important;
        transform: translate(0, 3px) !important;
      }
    }
    .uploader {
      height: 104px;
      width: 104px;
      padding-top: 0;
    }
    input::-ms-reveal {
      display: none;
    }
  }
}

.delete-user-button {
  background-color: #ef7171 !important;
}
