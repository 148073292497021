@import "../../../../../styles";

.planInfo {
  @include flex(flex-start);
  width: 472px;
  height: 40px;
  border-radius: 10px;
  padding: 4px;
  margin: 20px 0 0 56px;
  &-timeLeft {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-left: 34px;
    span {
      font-weight: bold;
    }
  }
}
