@import "../../../../../../styles/variables.module";
@import "../../../../../../styles/mixins";

.pollCreation {
  &-content {
    &-textField {
      margin-top: 24px;
      .text-field {
        min-height: 88px;
        @include flex(flex-start, flex-start);
      }
    }
    .optionsList-item {
      display: flex;
      flex-direction: row;
      margin-top: 28px;
      position: relative;
      .label-error {
        position: absolute;
        color: $error;
        font-size: 13px;
        align-self: flex-end;
        bottom: -20px;
        right: 35px;
      }
      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 20px 0 0 5px;
      }
    }
  }
}
