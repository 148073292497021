@import "../../../../../styles/index";

.memberCard {
  width: 100%;
  margin: 0;
  border-radius: $border-radius !important;
  box-shadow: $card-shadow !important;
  background: white !important;
  min-height: 120px;
  cursor: pointer;
  &.memberCard-currentUser {
    box-shadow: $card-shadow-orange !important;
  }
  &-content {
    box-sizing: border-box;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-mainInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-userName {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: $black-color;
    letter-spacing: 0.02em;
    margin: 0 0 16px;
  }

  &-userEmail {
    font-family: $font-heebo;
    font-size: 18px;
    line-height: 160%;
    color: $grey-color;
    letter-spacing: 0.02em;
    @include truncate-line;
  }

  &-actions {
    text-align: right;
  }
}
