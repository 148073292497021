@import "../../../styles/variables.module";

.list-item {
  height: 72px;
  border-radius: $border-radius;
  background-color: $background-color;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 8px;

  &-img {
    margin-right: 16px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &-name {
    flex: 1;
    font-size: 14px;
    line-height: 22px;
    color: $black-color;
  }
  &-controls {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      margin: 0 8px;
    }
  }
}
