@import "../../../styles/mixins";
.square-radio {
  position: relative;
  label {
    height: 80px;
    background: $background-color;
    border-radius: $border-radius;
    margin: 0 0 8px;
    padding: 0 16px 0 0;
    @include flex(flex-start, flex-start);
    span {
      font-size: 14px;
      line-height: 22px;
    }
    .styledRadio {
      padding: 16px;
    }
  }
  &-error {
    color: $red-color;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    position: absolute;
    bottom: -20px;
    right: 0;
    margin: 0;
  }
}