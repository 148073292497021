@import "../../../../styles/index.scss";

.community-creation-blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  box-sizing: border-box;
  @include display-flex-center;
  flex-direction: column;
  background-color: #e5e5e5;

  .logo {
    width: 53px;
    height: 48px;
    mask: url("/assets/icons/logo.svg") no-repeat;
    mask-size: contain;
    background-color: $blue-1;
  }

  h2,
  h3 {
    width: 100%;
    text-align: center;
  }

  h2 {
    font-family: $font-lora;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.01em;
    color: $dark-grey;
    margin: 44px 0 16px;
  }

  h3 {
    font-family: $font-heebo;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $grey-3;
    margin: 0;

    &:last-of-type {
      margin: 30px 0 50px;
    }
  }

  img {
    opacity: 0.7;
  }

  .android-app-link {
    text-decoration: none;
    width: 100%;
    margin-top: 21px;
  }
}
