@import "../../../../styles/index.scss";
@import "../../../../styles/mixins.scss";
.customMessageWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 12px 0 0;
  align-items: flex-end;
  flex-direction: row;
  &-owner {
    justify-content: flex-end;
    .custom-avatar {
      margin-right: 0;
      margin-left: 8px;
    }
    .customMessage-header-messageInfo {
      justify-content: flex-end;
    }
    .customMessage-actions-wrapper {
      right: 0;
      left: auto;
      .customMessage-actions-delete {
        margin-left: 5px;
      }
    }
  }
  &-grouped {
    padding: 0;
  }
  &:hover {
    .customMessage-actions-wrapper {
      display: block;
    }
    .messageMenu {
      display: flex;
    }
  }
}

.customMessage {
  display: flex;
  flex-direction: column;
  background: $tabs-blue-color;
  padding: 8px 16px;
  position: relative;
  border-radius: 4px 20px 20px 20px;
  align-self: flex-start;
  max-width: 100%;
  margin-top: 8px;
  cursor: pointer;
  .error-wrapper {
    .error-header {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4);
      font-style: italic;
    }
    .error-content {
      margin-top: 8px;
      color: #ffbaba;
      img {
        position: relative;
        top: 2px;
        margin-right: 4px;
      }
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
  }
  &.system {
    background: none;
    box-shadow: none;
    padding: 6px 17px 6px;
    &:after {
      display: none;
    }
  }
  &-reactionsList {
    display: flex;
    @include flex(flex-end);
    &.owner {
      @include flex(flex-start);
      margin-left: 0;
    }
    &.disabled {
      .emoji-reaction-item-wrapper {
        cursor: default;
      }
    }
  }
  &-reactions {
    position: relative;
    display: flex;
    padding: 17px 17px;
    box-shadow: $card-shadow;
    border-radius: 8px 8px 0 8px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 0;
      border-color: transparent $white-color transparent transparent;
      position: absolute;
      right: 0;
      bottom: -5px;
    }
    &-edit,
    &-delete {
      cursor: pointer;
    }
    &-edit {
      margin: 0 10px;
    }
    &-owner {
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
    &-reaction {
      padding-right: 5px;
    }
  }
  &-actions {
    height: 15px;
    @include flex();
  }
  &-actions-wrapper {
    &.event {
      background: #382e35;
    }
    background: white;
    border-radius: 20px;
    position: absolute;
    color: white;
    z-index: 100;
    display: none;
    left: 0;
    padding: 6px 9px;
    top: -20px;
    .customMessage-actions-divider {
      display: inline-block;
      &.event {
        border: 1px solid #e8e8e8;
      }
      border: 1px solid #c9c0c6;
      opacity: 0.2;
      min-height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      &.left {
        left: 32px;
      }
      &.right {
        right: 32px;
      }
    }
    .customMessage-actions-reactions,
    .customMessage-actions-edit,
    .customMessage-actions-delete {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    .customMessage-actions-edit {
      margin-left: 16px;
    }
    .customMessage-actions-delete {
      margin-left: 16px !important;
    }
  }
  &-attachments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0;
    margin: 0;
    .str-chat__message-attachment {
      margin: 0;
      border-radius: 20px !important;
      max-width: 100%;
      width: 282px;
      &-card,
      &--media,
      &--image {
        border: unset !important;
        overflow: hidden;
        margin-top: 4px;
      }
      &--card--no-image {
        height: 100%;
      }
    }
    &-owner {
      align-items: flex-end;
      .str-chat__message-attachment {
        &-file--item {
          justify-content: flex-end;
          border-left: none;
          padding-right: 4px;
        }
        margin: 0;
        img {
          margin-left: auto;
        }
      }
    }
    .str-chat__message-attachment--video {
      border-radius: 20px !important;
      border: unset !important;
      overflow: hidden;
    }
  }
  &-owner {
    background: $white-color;
    border-radius: 20px 4px 20px 20px;
    align-self: flex-end;
  }
  &-mediaOnly {
    background: none;
    padding: 0;
    border-radius: 0;
    .customMessage-attachments {
      margin: 0;
    }
  }
  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    * {
      font-family: $font-heebo !important;
    }
    &-ellipsis {
      display: flex;
      font-size: 16px;
      line-height: 26px;
      color: #7e6677;
      align-items: center;

      > p {
        font-style: normal;
      }
      &-cont {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        &-greeter {
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: normal;
          font-size: 16px;
          margin-right: 8px;
          padding: 0 12px;
          background-color: $blue-1;
          color: $blue-4;
          border-radius: 20px;
        }
      }
    }
    &-messageInfo {
      display: flex;
      align-items: center;
      margin-right: 11px;
      width: 100%;

      &-avatar {
        position: relative;
        .restriction {
          position: absolute;
          top: 17px;
          left: 17px;
        }
      }
    }
    &-date {
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 13px;
      margin: 0;
      white-space: nowrap;
    }
    &-userName {
      font-family: $font-heebo;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: $blue-1;
      margin: 0 6px 0 0;
      max-width: 125px;
      @include truncate-line;
    }
    &-date {
      color: $blue-1;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.04em;
    }
    &-edited {
      font-size: 12px;
      color: $grey-color;
    }
    &-actionsBtn {
      &.event {
        background: #382e35;
      }
      background: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      opacity: 0.3;
      position: relative;
      &.opened {
        opacity: 1;
      }
      hr {
        border: none;
        border-radius: 50%;
        width: 3px;
        height: 3px;
        background: $black-color;
        margin-right: 3px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .custom-avatar {
      margin-right: 8px;
    }
  }
  &-text {
    p {
      font-family: $font-heebo;
      font-size: 13px;
      line-height: 20px;
      margin: 0;
      white-space: pre-line;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
    }
    font-family: $font-heebo;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: $black-color;
    display: flex;
    flex-wrap: wrap;
    &.system {
      font-size: 13px;
      color: $grey-color;
    }
    &.deleted {
      color: $grey-color;
    }
    a {
      font-family: $font-heebo;
      font-size: 13px;
      margin: 0 5px;
      cursor: pointer;
      color: $white-color;
      text-decoration: underline;
      outline: none;
      max-width: 100%;
      padding-right: 20px;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      box-sizing: border-box;
    }
  }
  .emoji {
    font-size: 28px;
    line-height: 30px;
  }
  &-tooltip.eventTooltip {
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
      padding: 8px 12px;
      .MuiTooltip-arrow {
        left: 20px !important;
      }
    }
    &.own {
      .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
        .MuiTooltip-arrow {
          left: unset !important;
          right: 12px;
        }
      }
    }
  }
}

.str-chat {
  &__reaction-selector {
    position: unset !important;
    background: none !important;
    border: none !important;
    border-radius: unset !important;
    height: 100% !important;
    box-shadow: none !important;
    .str-chat__message-reactions-list {
      margin: 0;
    }
  }
  &__message-attachment--image {
    margin: 0 !important;
  }
  &__message-attachment--img {
    border-radius: 20px !important;
  }
}

.emodji-reactions-popover {
  .MuiPaper-root {
    overflow: hidden;
    border: 1px solid #c9c0c6;
    border-radius: 16px;
  }
}

.customMessage {
  &-text {
    p {
      display: inline;
    }
  }
}

@media screen and (max-width: 1160px) {
  .customMessageWrapper {
    .customMessage-actions-wrapper {
      &.short-message {
        left: auto;
        right: -14px;
      }
    }
    &-owner {
      .customMessage-actions-wrapper {
        &.short-message {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .customMessage {
    &-text {
      p {
        display: inline;
        a {
          max-width: calc(100vw - 415px);
        }
      }
      .customMessage-header-edited {
        color: $grey-color;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &-header {
      &-userName {
        max-width: 50px;
      }
    }
  }
}
