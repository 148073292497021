@import "../../../../styles/variables.module";
.load-container {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(3px) brightness(120%) contrast(80%) grayscale(100%);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  @keyframes loader {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }
  .loader div {
    left: 78.8px;
    top: 7.88px;
    position: absolute;
    animation: loader linear 1s infinite;
    background: #ffffff;
    width: 39.4px;
    height: 39.4px;
    border-radius: 19.7px / 19.7px;
    transform-origin: 19.7px 90.62px;
  }
  .loader div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.875s;
    background: #ffffff;
  }
  .loader div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.75s;
    background: #ffffff;
  }
  .loader div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.625s;
    background: #ffffff;
  }
  .loader div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.5s;
    background: #ffffff;
  }
  .loader div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.375s;
    background: #ffffff;
  }
  .loader div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.25s;
    background: #ffffff;
  }
  .loader div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.125s;
    background: #ffffff;
  }
  .loader div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: 0s;
    background: #ffffff;
  }
  .loading-spinner-spinner {
    width: 197px;
    height: 197px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .loader {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .loader div {
    box-sizing: content-box;
  }
}
