@import "../../../../../../styles";
.announcements-list-wrapper {
  height: 100%;
  .announcements-empty-screen {
    @include flex(center, center, column);
    width: 100%;
    height: 100%;
    .announcements-empty-icon {
      @include flex();
      width: 136px;
      height: 136px;
      background-color: #382e35;
      border-radius: 50%;
      &::before {
        content: "";
        background: url("/assets/icons/announcements_empty_icon.svg") no-repeat center;
        background-size: contain;
        width: 79px;
        height: 59px;
      }
    }
    .announcements-empty-title {
      color: #7e6677;
      font-size: $font-size-normal;
      font-weight: bold;
      margin-top: 24px;
    }
  }
  .announcements-list {
    height: calc(100% - 106px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 6px 0 0;
    &.full-height {
      height: calc(100% - 20px);
    }
    .announcements-list-item {
      margin-bottom: 16px;
    }
  }
  .announcements-add-button {
    padding: 10px 0 0;
    position: relative;
    @include flex(space-between, center, column);
    height: 84px;
    button {
      max-width: 328px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .announcements-list-wrapper {
    .announcements-add-button {
      button {
        max-width: 230px;
      }
    }
  }
}
