@import "../../../styles/variables.module";
@mixin variant($variant) {
  @at-root .variant-#{$variant} #{&} {
    @content;
  }
}

.imageUpload {
  overflow: hidden;
  position: relative;
  background: $light-grey-color;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &-uploaded {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    height: 100%;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include variant("circle") {
        border-radius: 50%;
      }
    }
    &-options {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &-select {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    background-repeat: no-repeat;
    background-position: center;
    &-icon {
      position: absolute !important;
      bottom: 0;
      right: 0;
    }
    @include variant("circle") {
      border-radius: 50%;
      background-color: $wisp-pink-color;
    }
  }
  &.variant-circle {
    background: none;
    .editIcons {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $blue-1;
      &-edit {
        background: url("/assets/icons/edit_icon_white.svg") no-repeat center $blue-1;
        border: 1px solid $white-color;
        &:before,
        &:after {
          content: none;
        }
      }
      &-delete {
        display: none;
      }
    }
  }
  &.dark {
    .editIcons {
      background-color: $purple-1;
    }
    .imageUpload-select {
      background-color: $popup-bg;
    }
  }
}

.replace-image-button {
  background-color: #ef7171 !important;
}
