@import "../../../../../styles/variables.module";
.mediaPermissionsDialog {
  .defaultButtons {
    .defaultButtons-confirm {
      &.active-btn {
        background-color: $orange-1;
      }
      &.disable-btn {
        background-color: $purple-2;
      }
    }
    .defaultButtons-cancel {
      margin: 0 0 0 10px;
    }
  }
  &.second-popup {
    .defaultButtons {
      justify-content: space-between;
    }
  }
  .styledDialog-text-title {
    margin-bottom: 16px;
    line-height: 30.8px;
    text-transform: none;
  }
  .styledDialog-text-content {
    .secondText {
      margin: 8px 0;
      line-height: 24px;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          display: flex;
          margin: 12px 0;
          &:last-of-type {
            margin-bottom: 0;
          }
          .digit {
            flex-shrink: 0;
            margin: 0 14px 0 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            font-size: 13px;
            color: $purple-3;
            border-radius: 50%;
            background: #4d3f49;
          }
        }
      }
      .text-content-title {
        padding: 8px 0 14px;
      }
    }
    .camera-icon {
      vertical-align: middle;
      &.safari {
        margin-bottom: 4px;
      }
    }
  }
  .media-permissions-img {
    border-radius: 10px;
    margin: 0px 24px 24px;
  }
}

@media screen and (max-height: 520px) {
  .mediaPermissionsDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0.9) translate(-55%, -56%);
  }
}

@media screen and (max-height: 480px) {
  .mediaPermissionsDialog {
    transform: scale(0.8) translate(-60%, -62%);
  }
}

@media screen and (max-height: 420px) {
  .mediaPermissionsDialog {
    transform: scale(0.7) translate(-70%, -72%);
  }
}
