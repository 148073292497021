.social {
  flex: 50;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .social-title {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: var(--text-color);
    margin-right: 32px;
  }
  .social-buttons {
    display: flex;
    cursor: initial;
    button {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    .btn {
      width: 40px;
      height: 40px;
      background: var(--element-background-color);
      border-radius: 50%;
      outline: none;
      border: none;
      margin-right: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
      &.stroke-color {
        path {
          stroke: var(--element-color);
        }
      }
      &.fill-color {
        path {
          fill: var(--element-color);
        }
      }
    }
  }
}