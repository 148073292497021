@import "../../../styles/index";

.searchInput {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 256px;
  max-height: 40px;

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 13px;
    top: 13px;
    background: url("/assets/icons/magnifying.svg") center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-input {
    padding: 8px 40px !important;
    outline: none;
    box-sizing: border-box;
    border-radius: $border-radius;
    overflow: hidden;
  }

  &-clear {
    position: absolute !important;
    background: transparent !important;
    right: 5px;
  }

  &.collapsable {
    position: absolute;
    right: 60px;
  }
}
