@import "../../../../styles/variables.module";
.selectedMembers {
  display: flex;
  align-items: center;
  &-multiply {
    position: relative;
    left: -12px;
    justify-content: flex-end;
    @for $i from 1 through 3 {
      &.selectedMembers-#{$i} {
        width: unquote("calc(#{$i} * 13px + 43px + 100%)");
      }
    }
  }
  &-imgContainer {
    display: flex;
    align-items: center;
    max-width: 250px;
    &-multiply {
      position: absolute;
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          position: absolute;
          left: ($i * 12px);
          z-index: $i;
        }
      }
    }
    .custom-avatar {
      margin-right: 8px;
      .custom-avatar-fallback,
      .custom-avatar-image {
        border-radius: 50%;
        transition: border-color 0.2s linear;
        border: 3px solid $blue-color;
        box-sizing: border-box;
      }
    }
  }
  &-data {
    display: flex;
    flex-direction: column;
    &-name {
      margin: 0;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: $white;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 210px;
    }
  }
  &-imgCount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $blue-color;
    margin-right: 8px;
    &-count {
      font-size: 14px;
    }
  }
  &-info {
    &-container {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }
    &-title {
      margin: 0;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: $purple-color;
    }
    &-count {
      margin: 0;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
    }
  }
}
