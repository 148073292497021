@import "../../../../../styles/mixins";
.event-schedule-component-wrapper {
  margin: 80px 0;
  .event-schedule-title-wrapper {
    margin-bottom: 50px;
    .tabs-list {
      display: flex;
      .tab-item {
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 130%;
        color: var(--text-color);
        opacity: 0.3;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
        &:nth-child(1) {
          padding-right: 24px;
        }
        &:nth-child(2) {
          border-left: 2px solid #71706c;
          padding-left: 24px;
        }
      }
    }
  }
  .event-schedule-list {
    .event-group {
      .date-title {
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--text-color);
        margin-bottom: 40px;
      }
      .event-item {
        display: flex;
        height: 264px;
        margin-bottom: 26px;
        box-sizing: border-box;
        cursor: pointer;
        .image-wrapper {
          flex: 450px;
          height: 100%;
          background-color: black;
          position: relative;
          margin: 0 auto;
          .badge {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
            &.moved {
              left: 130px;
            }
          }
          img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            &.default-image {
              object-fit: cover;
            }
          }
        }
        .event-information-wrapper {
          padding: 40px 30px;
          background: var(--content-backgroud-color);
          width: 100%;
          max-width: 100%;
          box-sizing: border-box;
          position: relative;
          .event-date-wrapper {
            .date-start {
              font-size: 14px;
              line-height: 130%;
              display: flex;
              align-items: center;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: var(--text-color);
              margin-bottom: 16px;
              .private-badge {
                margin-left: 24px;
              }
            }
          }
          .event-title-wrapper {
            font-family: Heebo;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 130%;
            color: var(--text-color);
            margin-bottom: 24px;
          }
          .event-footer {
            left: 30px;
            position: absolute;
            bottom: 24px;
          }
          .event-description-wrapper {
            @include truncate-lines(3);
            .event-description-text {
              font-family: Heebo;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 130%;
              color: var(--text-color);
            }
          }
        }
      }
    }
    .empty-schedule-events-content {
      background: var(--content-backgroud-color);
      padding: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      min-height: 430px;
      flex-direction: column;
      border: 3px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 8px;
      .empty-title {
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: var(--text-color);
        max-width: 370px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .event-schedule-component-wrapper {
    margin: 32px 0;
    .event-schedule-title-wrapper {
      margin-bottom: 16px;
      margin-left: 16px;
      .tabs-list {
        .tab-item {
          font-size: 20px;
          &:nth-child(1) {
            padding-right: 8px;
          }
          &:nth-child(2) {
            padding-left: 8px;
          }
        }
      }
    }
    .event-schedule-list {
      .event-group {
        .date-title {
          font-size: 14px;
          margin-bottom: 16px;
          margin-left: 16px;
        }
        .event-item {
          display: flex;
          flex-direction: column;
          height: auto;
          width: 100%;
          margin-bottom: 32px;
          .image-wrapper {
            flex: auto;
            height: 168px;
            width: 100%;
            .badge {
              top: 4px;
              left: 4px;
            }
            img {
              height: 100%;
              width: 100%;
              position: static;
            }
          }
          .event-information-wrapper {
            padding: 16px;
            .event-date-wrapper {
              .date-start {
                font-size: 11px;
                margin-bottom: 12px;
                .private-badge {
                  margin-left: 8px;
                }
              }
            }
            .event-title-wrapper {
              font-size: 16px;
              margin-bottom: 12px;
            }
            .event-description-wrapper {
              .event-description-text {
                font-size: 11px;
              }
            }
            .event-footer {
              display: none;
            }
          }
        }
      }
      .empty-schedule-events-content {
        border-radius: 8px;
        margin: 0 16px;
        .empty-title {
          font-weight: normal;
          font-size: 11px;
          max-width: 280px;
        }
      }
    }
  }
}
