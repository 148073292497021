@import "../../../../../../styles";

$padding-content: 24px;
$view-created-color: #93808D;

.announcements-view-wrapper {
  height: 100%;
  color: $white-color;
  font-size: $font-size-normal;
  word-wrap: break-word;
  .announcement-info {
    padding: $padding-content;
    height: calc(100% - 160px);
    overflow-y: auto;
    .announcement-created {
      font-size: $font-size-small;
      font-weight: bold;
      color: $view-created-color;
      margin-bottom: 16px;
    }
  }

  .announcement-button {
    padding: $padding-content;
  }
}