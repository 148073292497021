@import "../../../../../../styles/index.scss";

.eventPollTemplates {
  height: 100%;
  overflow-y: auto;
  padding: 20px 8px 0;
  &-card {
    background-color: #382e35;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 16px;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $white-color;
      margin-bottom: 18px;
    }
    &-question {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $white-color;
      margin-bottom: 24px;
    }
    &-options {
      @include flex(center, flex-start, column);
      &-item {
        @include flex();
        margin-bottom: 16px;
        &-action {
          margin-right: 8px;
          &-radio,
          &-box {
            height: 20px;
            width: 20px;
            border: solid 2px $beige-3;
            background-color: #746d72;
            border-radius: 50%;
          }
          &-box {
            border-radius: 5px;
          }
        }
        &-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
        }
      }
    }
    &-divider {
      width: 100%;
      height: 2px;
      background-color: #54444f;
      margin: 24px 0;
    }
    &-footer {
      @include flex(space-between, center);
      &-results {
        width: fit-content;
        padding: 2px 8px;
        background-color: #607184;
        border-radius: 9px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $white-color;
      }
      &-controls {
        @include flex();
        &-publish {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
          cursor: pointer;
        }
        .three-dots-popup {
          min-width: 24px;
          .three-dots-popup-wrapper {
            background-color: $popup-bg;
            box-shadow: 0px 4px 8px rgb(0, 0, 0 / 5%);
            .eventPollTemplates-card-footer-controls-edit {
              border-radius: 20px;
              box-sizing: border-box;
              color: $white-color;
              padding: 18px;
              width: 100%;
              &:hover {
                background: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
