@import "../../../../../styles/index.scss";
.video-list-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  &.center {
    justify-content: center;
  }
  .video-wrapper {
    display: flex;
    width: 50%;
    position: relative;
    &:nth-child(odd) {
      justify-content: flex-end;
    }
    &:nth-child(even) {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    .video-container {
      display: flex;
      margin-left: 25px;
      margin-bottom: 25px;
      position: relative;
      .attendeePic {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%; //position the top  edge of the element at the middle of the parent */
        left: 50%;
      }
      .connection-issue {
        border-radius: 10px;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: black;
        opacity: 0.7;
        .information-hint {
          border-radius: 10px;
          font-size: 16px;
          line-height: 24px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }
    .videobox {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 450px;
      overflow: hidden;
      background-color: #000;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      max-width: 472px;
      max-height: 320px;
      video {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
      &.mirrored {
        video {
          transform: scaleX(-1);
        }
      }
    }
    .direct-message-menu {
      position: absolute;
      right: 8px;
      top: 8px;
      &.opened,
      &:hover {
        .eventMemberListItem-btn {
          background: rgba($color: $popup-bg, $alpha: 1);
        }
      }
      .eventMemberListItem-btn {
        background: rgba($color: $popup-bg, $alpha: 0.6);
        hr {
          background: #e7e1e5;
          width: 3px;
          height: 3px;
          overflow: hidden;
        }
        .eventMemberListItem-options {
          right: 0;
          background: #352a32;
          justify-content: flex-start;
          .eventMemberListItem-options-item {
            padding: 12px 24px 12px;
            text-align: left;
            max-width: 160px;
            &:first-child {
              padding-top: 24px;
            }
            &:last-child {
              padding-bottom: 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 2560px) {
  .video-list-wrapper {
    .video-wrapper {
      .videobox {
        height: 575px;
        width: 780px;
      }
    }
  }
}

@media screen and (max-width: 1980px) {
  .video-list-wrapper {
    .video-wrapper {
      .videobox {
        height: 405px;
        width: 560px;
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .video-list-wrapper {
    .video-wrapper {
      .videobox {
        height: 325px;
        width: 440px;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .video-list-wrapper {
    .video-wrapper {
      .videobox {
        height: 245px;
        width: 330px;
      }
    }
  }
}

@media screen and (max-width: 1268px) {
  .video-list-wrapper {
    .video-wrapper {
      .videobox {
        height: 225px;
        width: 300px;
      }
    }
  }
}
