@import "../../../../../styles";
.hostNotes-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-block {
    display: flex;
    flex-direction: column;
    padding: 15px 24px;
    background: $popup-bg;
    border-radius: $border-radius;
    box-sizing: border-box;
    margin-bottom: 16px;

    &-text {
      max-height: calc(100vh - 350px);
      line-height: 20px;
      overflow-y: scroll;
      overflow-wrap: break-word;
      color: $white;
      padding-right: 5px;

      p {
        margin: 0;
      }
    }

    &-divider {
      width: 100%;
      height: 2px;
      margin-top: 10px;
      background: #7e6677;
      opacity: 0.4;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;

      &-delete {
        cursor: pointer;
        width: 24px;
        height: 24px;
        background: url("/assets/icons/ic_remove.svg") no-repeat center;
      }
    }
  }

  &-lastUpdates {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    justify-content: right;
    align-items: center;
    letter-spacing: 0.04em;
    color: #93808d;
  }
}
