@import "../../../../styles/index.scss";
.messageMenu {
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $event-input-background;
  border-radius: 5px;
  width: 28px;
  height: 16px;
  transition: 0.2s;
  margin-left: 16px;
  &.opened {
    background-color: $purple-color;
    hr {
      background: #e7e1e5;
    }
  }

  hr {
    border: none;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: $purple-1;
    margin: 2.5px;
    transition: 0.2s;
  }

  &-options {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 24px;
    right: 0;
    display: flex;
    background: $popup-bg;
    border-radius: $border-radius;
    z-index: 1000;

    &-item {
      font-weight: bold;
      font-size: 13px;
      width: 167px;
      text-align: start;
      color: $purple-3;
      padding: 16px 24px;
      transition: 0.2s;
      border-radius: $border-radius;
      &:hover {
        color: $white-color;
        background: #3f373c;
      }
    }
  }
}
