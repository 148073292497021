.early-adopters {
  &_title {
    font-family: Lora;
    font-size: 28px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
  }
  &_description {
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 32px;
  }
  &_img {
    display: flex;
    margin: 32px 0;
    width: 100%;
    justify-content: center;
  }
}
