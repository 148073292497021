@import "../../../../../styles/variables.module";

.appearance-favicon {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &-placeholder {
    position: absolute;
    top: 11px;
    left: 70px;
    width: 16px;
    height: 16px;
  }
  &-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 64px;
    &-replace {
      color: $error;
      margin-left: 24px;
      &::before {
        content: "";
        background: url(/assets/icons/ic_remove.svg) no-repeat 100%;
        position: absolute;
        width: 24px;
        height: 24px;
        left: -7px;
        top: -2px;
      }
    }
    .imageUpload {
      width: auto;
      height: auto;
      background: none;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $blue-1;
    }
    &-description {
      font-family: "Heebo";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $grey-3;
    }
  }
}
