@import "../../../../../styles/variables.module.scss";
.stream-event {
  display: flex;
  min-height: 100vh;
  &:focus-visible {
    outline: none;
  }
  .stream-event-right-panel {
    width: 256px;
    box-sizing: border-box;
    padding: 24px;
    background: $light-grayish-blue-color;
    .logo {
      width: 116px;
      height: 32px;
      background: url("/assets/icons/logo_full.svg") no-repeat;
      margin: 4px 0 44px;
    }
  }
  .stream-event-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $white-color;
    .stream-event-main-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 88px;
      padding: 24px;
      box-sizing: border-box;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      .close,
      .back {
        margin-right: 24px;
      }
      .save {
        margin-right: 16px;
      }
      .remove {
        position: relative;
        margin-right: 48px;
        &:after {
          content: " ";
          position: absolute;
          border-left: 1px solid $wisp-pink-color;
          right: -24px;
          height: 24px;
        }
      }
    }
    .stream-event-main-content {
      width: 100%;
      padding: 80px 0 24px;
      margin: 0 auto;
      font-size: 14px;
      color: $grey-1;
      overflow-y: scroll;
      height: 100%;
      max-height: calc(100vh - 88px);
      box-sizing: border-box;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .stream-event-main-content-wrapper {
        margin-bottom: 100px;
        &>div {
          max-width: 624px;
          margin: 0 auto;
        }
        .relayBlocker {
          .image {
            transform: scale(1.2);
          }
        }
      }
      .learn-more {
        text-transform: none;
        font-size: 13px;
        margin-top: 12px;
        position: relative;
        padding-left: 25px;
        &:before {
          content: "";
          mask: url("/assets/icons/link2.svg") no-repeat;
          mask-size: contain;
          background-color: $blue-1;
          left: 0;
          top: 2px;
          width: 16px;
          height: 16px;
          position: absolute;
          transform: rotate(77deg) skew(-14deg);
        }
      }
    }
  }
}
