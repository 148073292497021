@import "../../../styles/variables.module";

.emptyText {
  display: flex;
  width: 100%;
  height: calc(100vh - 300px);
  min-height: 125px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  &-message {
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: $font-heebo;
    font-weight: bold;
    font-size: 28px;
    color: #9F9F9F;
  }
}
