@import "../../../../../styles/variables.module";
@import "../../../../../styles/mixins";

.announcementCreation-template {
  .announcement-form {
    @include flex(flex-start, flex-start, column);
    width: 432px;
    margin-top: 26px;
    &-row {
      width: 100%;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
        margin: -16px 0 6px 16px;
      }
      .custom-text-field-counter {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $grey-4;
      }
      .dropDown {
        &-selectedName,
        &-optionsList {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: $grey-1;
        }
      }
    }
  }
}
